:root {

    /* Buttons and components */
    --primary-color: hsl(150, 81%, 44%);
    --secondary-color: hsl(201, 100%, 50%);
    --tertiary-color: hsl(0, 0%, 100%);


    /* Piano Roll */
    --melody-matte-track-color: #e33100;
    --chords-matte-track-color: #ecec67;
    --bass-matte-track-color: #00a1fe;
    --percussion-matte-track-color: #65e565;
    --extra-matte-track-color: #833add;
    --ornaments-matte-track-color: #4debe3;
    --harmony-matte-track-color: #c7c7c7;

    --melody-track-color: #ff3a05;
    --melody-track-opposite-color: rgb(255, 0, 230);

    --melody-harmonization-track-color: rgb(250, 96, 136);
    --melody-harmonization-track-opposite-color: rgb(255, 0, 230);

    --chords-track-color: #ebeb00;
    --chords-track-opposite-color: #ff8205;
    
    --bass-track-color: #00a3ff;
    --bass-track-opposite-color: #00ffdd;

    --percussion-track-color: #00d300;
    --percussion-track-opposite-color: #0512ff;

    --extra-track-color: #7605ff;
    --extra-track-opposite-color: #f305ff;

    --ornaments-track-color: #b172ff;
    --ornaments-track-opposite-color: #f758ff;

    --custom-pitched-track-color: #8b2efb;
    --custom-pitched-track-opposite-color: #8b2efb;

    --custom-percussion-track-color: #00d300;
    --custom-percussion-track-opposite-color: #00d300;

    /* Automation */
    --dynamic-primary-color: rgb(255, 136, 0);
    --dynamic-secondary-color: rgb(255, 217, 0);

    --low_frequency_cut-primary-color: rgba(255, 70, 70, 1);
    --low_frequency_cut-secondary-color: rgba(255, 150, 150, 1);

    --low_frequency_cut_transparent-primary-color: rgba(255, 70, 70, 0.6);
    --low_frequency_cut_transparent-secondary-color: rgba(255, 150, 150, 0.6);

    --high_frequency_cut-primary-color: rgba(70, 70, 255, 1);
    --high_frequency_cut-secondary-color: rgba(150, 150, 255, 1);

    --high_frequency_cut_transparent-primary-color: rgba(70, 70, 255, 0.6);
    --high_frequency_cut_transparent-secondary-color: rgba(150, 150, 255, 0.6);

    --reverb-primary-color: rgb(0, 234, 255);
    --reverb-secondary-color: rgba(0, 255, 100, 1);

    --delay-primary-color: rgba(255, 0, 190, 1);
    --delay-secondary-color: rgba(255, 146, 236, 1);

    --tension-primary-color: rgb(0, 229, 255);
    --tension-secondary-color: rgb(0, 255, 170);

    --layering-primary-color: rgb(123, 70, 255);

    /* Dropdown */
    --dropdown-primary-color: linear-gradient(rgb(96, 96, 96), rgb(74, 74, 74));
    --dropdown-secondary-color: linear-gradient(gray, #6e6e6e);

    /* Drum Sequencer */
    --drum-sequencer-top-bar-background-color: linear-gradient(rgb(96, 96, 96), rgb(74, 74, 74));
}