@font-face {
    font-family: 'Play';
    src: url('../fonts/Play/Play-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Thin';
    src: url('../fonts/Montserrat/Montserrat-Thin.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Medium.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: 600;
    font-style: normal;
}