@import 'assets/css/vendor-prefixes.scss';

@mixin square-button {
    font-size: 90%;
    font-weight: 700 !important;
    color: white;

    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 45px;

    @include transition(all ease 0.3s);
    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;

    &:hover {
        cursor: pointer;
    }
}

.light-input {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid hsla(0, 0%, 100%, 0.064);
    color: white;
    line-height: 20px;
    font-size: 80%;

    // border-radius and other styles that are set in the a-button class must be forced to be overwritten
    border-radius: 8px;  

    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: rgba(255, 255, 255, 0.613);
    }
    
}

.light-dropdown {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid hsla(0, 0%, 100%, 0.064);
    color: white;
    line-height: 20px;
    font-size: 80%;

    // border-radius and other styles that are set in the a-button class must be forced to be overwritten
    border-radius: 8px;  
    font-weight: 600;
    text-align: center;

    img {
        display: inline-block;
        width: 6px;
    }

    .light-dropdown-title {
        display: inline-block;
        width: calc(100% - 10px);
        text-align: left;
        vertical-align: middle;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    padding-left: 7px;
    padding-right: 7px;
    width: 205px;
    margin-top: 0px!important;

    &:hover {
        cursor: pointer;
    }
}

@mixin error-box {
    padding: 5px;
    font-size: 90%;
    font-weight: 500;
    border: 1px solid transparent;
    width: calc(100% - 10px); // make up for padding
    border-radius: 3px;
    color: white;
    background: #ff2929;
    margin: 10px auto;
    text-align: center;
}

.button-like-dropdown {
    display: inline-block;
    width: 220px;
    line-height: 34px;
    transition: background 0.07s;
    background-color: rgba(202, 202, 202, 0.09);
    border: 1px solid rgba(210, 210, 210, 0.1);
    vertical-align: middle;
    text-align: center;
    border-radius: 11px;
    font-size: 80%;
    color: white !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    outline: none;
    font-weight: 400;

    optgroup {
        background-color: #666666;
        color: white;
        -webkit-appearance: none;
    }

    option {
        background-color: #272727;
        color: white;
        -webkit-appearance: none;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.15);
    }
}