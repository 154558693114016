$template-menu-width: 350px;
$player-height: 50px;
$pianoroll-top-section: 62px;

:root {
    --scrollbar-space: 15px;
    --gp-padding-left-right: 20px;
    --layer-button-height: 60px;
    --layer-button-border-width: 1px;
    --layer-button-additonal-bottom-padding: 10px;

    /* Navbar / Sidebar */
    --sidebar-width: 205px;

    --gp-hub-card-width: 385px;
    --gp-hub-card-height: 175px;

    --empty-development-preview-border: 5px;

    /* Piano Roll */
    --layerlist-width: 325px;
    --layer-instrument-list-item-height: 48px;
    --vertical-pianoroll-scrollbar-width: 15px;
    --spectator-padding: 40px;
    --editor-padding: 20px;
    --spectator-note-container-top-padding: 25px;
    --layer-list-item-line-height: 35px;
    --layer-list-item-height: 36px; // border inclusive

    --template-modal-container-offset: 123px; // $player-height + $pianoroll-top-section + 10px + 1px

    /* Upload Influence Modal */
    --drag-handle-width: 6px;
    --seek-bar-width: 2px;

    /* Drum Sequencer */
    --drum-sequencer-top-bar-height: 40px;
    --drum-sequencer-timeline-height: 30px;
    --drum-sequencer-channel-height: 34px;
    --drum-sequencer-instrument-header-height: 34px;
    --drum-sequencer-controls-width: var(--layerlist-width);
    --drum-sequencer-step-min-width: 20px;
    --drum-sequencer-beat-margin: 20px;
    --drum-sequencer-step-margin: 2px;
    --drum-sequencer-step-container-padding: 15px;
    --drum-sequencer-header-height: 35px;
    --drum-sequencer-steps-width: calc(
        100vw - var(--sidebar-width) - var(--layerlist-width)
    );
    --drum-sequencer-total-width: calc(
        var(--drum-sequencer-controls-width) + var(--drum-sequencer-steps-width)
    );
    /* Player */
    --player-bar-height: 50px;

    /* Accompaniment Designer */
    --note-editor-pitch-size: 40px;
    --note-editor-grid-margin-left: 9px;

    /* Composition Workflow */
    --piano-width-variable: 25px;
    --side-width: 300px;
}
