@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~assets/css/unsemantic-grid-desktop-no-ie7.css";
@import "assets/css/fonts.css";
@import "assets/css/colors.css";
@import "assets/css/vendor-prefixes.scss";
@import "assets/css/layout.scss";
@import "assets/css/components.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "reinvented-color-wheel/css/reinvented-color-wheel.min.css";
@import "assets/css/components.scss";

/*

Some cool gradients:

background: linear-gradient(to top left, rgba(0, 155, 151, 1) 0%, rgba(0, 62, 105, 1) 50%, rgba(64, 0, 105, 1) 100%);
background: linear-gradient(to top left, #2d6ca5 0%, #2a5291 27%, #283d81 35%, #222654 50%, #0c0010 100%);

*/

html {
    overscroll-behavior-x: contain;
    overscroll-behavior-y: contain;
}

body {
    touch-action: none;
    overscroll-behavior-x: contain;
    overscroll-behavior-y: contain;
}

.simple-modal .modal-container {
    width: 400px;
}

.simple-modal-buttons {
    margin-top: 15px;
}

.publish-gp-input {
    width: 205px;
    padding-left: 7px;
    padding-right: 7px;
    font-weight: 500;
}

.material-variations-modal .modal-container {
    width: 600px;
}

.navbar-buttons-container {
    position: relative;
    height: calc(100% - 100px - 122px);
    margin-left: -30px;
}

.create-template-accompaniment-pack-modal .modal-container {
    width: 525px !important;
}

.reward-claimed-modal .modal-container {
    width: 550px;
    text-align: center;

    .checkmark-green {
        width: 36px;
        height: 46px;
        margin: 2% auto;
    }

    .reward-claimed-description {
        font-size: 90%;
    }
}

.modals-select-layer .modal-container {
    width: 400px;
}

.automixing-modal .modal-container {
    width: 375px;

    .modal-subtitle {
        margin-bottom: 10px;
        font-size: 90%;
        width: 215px;
    }
}

.enter-username-modal .modal-container {
    width: 450px;
}

.move-menu-right {
    margin-left: -473px;
}

.gp-library-filters-subtitle {
    font-weight: 600;
    font-size: 100%;
    margin-top: 10px;
    text-align: left;
}

.track-view-list-gp .track-title {
    width: calc(100% - 533px) !important;
}

.select-gp-influence-modal .modal-container {
    width: 700px;
}

.automixing-settings-row-enable {
    line-height: 55px !important;
}

.automixing-settings-row {
    line-height: 30px;
    font-size: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .settings-row-title {
        margin-top: -2px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
    }
}

.publish-gp-modal .modal-container {
    width: 550px;
}

.set-key-signature-modal .modal-container {
    width: 500px;
}

.create-with-gp {
    .settings-row {
        line-height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .settings-row-title {
            margin-top: -2px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.publish-gp {
    .step-3 {
        text-align: center;
        margin-bottom: 10px;
    }

    .loader-container {
        text-align: center;
    }

    .loader {
        font-size: 3px;
    }

    .loader-title {
        font-size: 90%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .modal-title {
        margin-bottom: 5px;
    }

    .step-container {
        margin-top: 25px;
    }

    .publish-gp-buttons-container {
        margin-top: 15px;
    }

    .settings-row {
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .settings-row-title {
            margin-top: -2px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.outdated-modal-buttons {
    margin-top: 15px;
}

.score-corrupted-modal .modal-container {
    width: 500px;
    text-align: left;
}

.pitch-range-control-row {
    margin-bottom: 20px;
}

.cw-create-composition-modal .modal-container {
    width: 500px;
}

.trackslist-create-track-container {
    margin-top: 10px;
}

.pack-synch-settings-row {
    line-height: 52px;
    font-size: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .settings-row-title {
        margin-top: -2px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);

        img {
            height: 15px;
            margin-left: 5px;
            margin-top: -3px;
            vertical-align: middle;

            &:hover {
                opacity: 0.9;
                cursor: pointer;
            }
        }
    }

    .ngx-slider-bubble {
        line-height: 20px;
    }

    .ngx-slider-floor {
        visibility: visible !important;
        display: inline-block !important;
        opacity: 1 !important;
    }

    .ngx-slider-ceil {
        visibility: visible !important;
        display: inline-block !important;
        opacity: 1 !important;
    }

    .ngx-slider-combined {
        visibility: hidden !important;
        display: none !important;
        opacity: 0 !important;
    }
}

.download-app-button-wrapper {
    margin-top: 15px;
}

.pack-synch-settings-row-disabled {
    cursor: not-allowed !important;
    opacity: 0.6;
}

.tooltip-img {
    height: 15px;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: -3px;
}

.gp-delay-dial {
    --dial-size: 26px !important;
    --not-filled: rgba(92, 92, 92, 0.55) !important;
    margin-left: 18px;
    margin-right: 0px;

    .title {
        font-size: 75% !important;
        margin-bottom: 5px !important;
    }
}

.rename-profile-category-modal .modal-container {
    width: 500px;
}

.select-gp-modal .modal-container {
    width: 800px;
    text-align: center;
}

.edit-double-modal .modal-container {
    width: 500px;
}

.download-app-icon {
    width: 50px;
    margin-bottom: 10px;
    margin-top: 5px;
}

.gp-layer-mixing-modal .modal-container {
    width: 720px;
}

.pack-synchronisation-modal .modal-container {
    width: 600px;

    .synchronisation-container {
        margin-bottom: 15px;
        margin-top: 15px;

        .loader {
            width: 18px !important;
            height: 18px !important;
            font-size: 4px;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
    }
}

.no-packs-to-synch-to-modal .modal-container {
    width: 500px;

    .modal-subtitle {
        font-size: 90%;
        opacity: 0.8;
        margin-bottom: 15px;
    }
}

.layer-priorities-modal .modal-container {
    width: 725px;
}

.form-tags-selection-modal .modal-container {
    width: 500px;

    .form-tags-list-container {
        position: relative;
        overflow: hidden;
        max-height: 600px;
    }
}

.pack-dataset-modal .modal-container {
    width: 1020px;
    height: 600px;
}

.instrument-palette-scroll {
    height: 439px;
}

.no-pack-container {
    text-align: center;
}

.no-pack-title {
    font-size: 95%;
    margin-top: 60px;
    margin-bottom: 5px;
}

.add-layer-to-gp-button {
    margin-top: 10px;
}

.mini-accompaniment-pack-card {
    .accompaniment-pack-title-container {
        width: 200px;
        text-align: left;
    }

    border: 2px solid transparent;
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.mini-accompaniment-pack-card:hover {
    cursor: pointer;
    border: 2px solid var(--secondary-color);
}

.mini-accompaniment-pack-card:active {
    background-color: var(--secondary-color) !important;
}

.chord-dataset-filter {
    display: flex;
    font-size: 90%;
    justify-content: space-between;
    align-items: flex-start;
}

.instruments-downloading-modal-progress-bar {
    margin-top: 12px;
    margin-bottom: 15px;
    display: block;
}

.gp-instrument-edition {
    .modal-container {
        width: 750px !important;
    }

    .delete-pack-instrument {
        display: inline-block;
        height: 17px;
        vertical-align: middle;
        margin-top: -2px;

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    .modal-container {
        width: 650px;
    }

    .gp-instrument-edition-button {
        display: inline-block;
        width: calc(100% - 300px);

        .icon {
            margin-top: 2px !important;
        }
    }

    .gp-instrument-edition-button-percussion {
        width: calc(100% - 60px);
    }

    .gp-instrument-edition-container {
        text-align: left;
        margin-bottom: 20px;
        max-height: 500px;
        position: relative;

        .no-instrument-assigned-to-pack {
            font-size: 80%;
            opacity: 0.8;
        }
    }
}

.chord-dataset-modal .modal-container {
    width: 525px;
}

.harmony-datasets-list-container {
    position: relative;
    max-height: 600px;
}

.test-report-data {
    color: white;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.test-report-data-block {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 250px;
}

.insert-section-length {
    .ngx-slider-span {
        color: white !important;
        font-size: 85% !important;
    }

    .ngx-slider-pointer {
        width: 12px !important;
        height: 12px !important;
        top: -4px !important;
        background-color: white !important;
    }

    .ngx-slider-pointer:after {
        width: 0px !important;
        height: 0px !important;
        background-color: transparent !important;
    }

    .ngx-slider-bar {
        background-color: #d8e0f387 !important;
    }

    .ngx-slider-pointer.ngx-slider-active:after {
        background-color: white !important;
    }
}

.insert-section-length {
    margin-bottom: 5px !important;
}

.edit-section-modal-section-length {
    text-align: left;
    font-size: 80%;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 4px;
}

.support-chat img {
    width: 18px;
    margin-top: -3px;
    vertical-align: middle;
}

.support-chat-title {
    display: inline-block;
    margin-left: 10px;
    color: white;
}

.support-chat {
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: var(--secondary-color);
    position: absolute;
    padding: 10px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 95%;
    bottom: 60px;
    right: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.support-chat:hover {
    cursor: pointer;
}

.automation-submenu-setting .dropdown-menu {
    width: 153px !important;
}

.edit-section-modal .modal-blue-button {
    margin-bottom: 0px !important;
    margin-top: 12px !important;
    width: calc(100% - 20px);
    margin-left: 0px !important;
}

.hide-crisp-client {
    opacity: 0 !important;

    .crisp-client .cc-kv6t .cc-1xry .cc-unoo .cc-7doi.cc-1uwh {
        width: 40px !important;
        height: 40px !important;
        display: none !important;
        opacity: 0 !important;
    }
}

.bg-wrapper {
    opacity: 0.15;
}

.gp-hub-content-wrapper {
    display: grid;
    grid-template-columns: var(--gp-hub-card-width) var(--gp-hub-card-width) var(
            --gp-hub-card-width
        );
    grid-gap: 15px;
    justify-items: center;
    justify-content: center;
    height: calc(100% - 300px);
    position: relative;
    padding-top: 25px;
    padding-bottom: 25px;
}

.note-tied-instrument {
    text-align: right !important;
    margin-right: 15px !important;
    float: right;
}

.note-tie-instrument {
    img {
        height: 15px;
        vertical-align: middle;
    }

    max-width: 200px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.05);
    padding: 2px;
    border-radius: 8px;
    display: inline-block;
    font-size: 80%;
    padding-left: 7px;
    padding-right: 7px;
    margin-right: 7px;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.similar-instruments {
    img {
        height: 11px;
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 7px;
    }
}

.note-tie-instrument-empty {
    opacity: 0;

    &:hover {
        cursor: auto !important;
    }
}

.gp-hub-content-wrapper-2-columns {
    grid-template-columns: var(--gp-hub-card-width) var(--gp-hub-card-width);
}

.gp-library-no-search-results {
    color: rgba(255, 255, 255, 1) !important;
    font-size: 120%;
}

.gp-card-tag {
    display: inline-block;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 80%;
    font-weight: 600;
    color: white;
    margin-right: 5px;
    width: 136px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.15);
    margin-bottom: 10px;
}

.gp-instrument-sections {
    margin-left: 5%;
    margin-top: 15px;
    margin-bottom: 15px;

    img {
        height: 20px;
        margin-right: 15px;
    }
}

.gp-container-card-top {
    line-height: 30px;
}

.gp-hub-more-options {
    position: fixed !important;
    text-align: left;
    min-width: 180px !important;
}

.gp-container-card {
    width: 380px;
    display: inline-block;
    color: white;
    text-align: left;
    font-size: 95%;
    border: 1px solid rgba(255, 255, 255, 0.03);
    /* background-color: #6060604d; */
    background-color: #ffffff14;
    /* box-shadow: 0px 0px 10px rgba(255,255,255,0.05); */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 2px;
    padding-right: 2px;

    .gp-card-title {
        font-family: "Montserrat", apple-system, roboto, lato;
        font-size: 115%;
        font-weight: 600;
        margin-bottom: 0px;
        margin-left: 5%;
        display: inline-block;
    }

    .gp-card-description {
        font-size: 85%;
        opacity: 0.9;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .gp-card-tags {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}

.show-crisp-client {
}

.light-button-gp-hub {
    margin-top: -1px;
    margin-right: 5px;
    color: white !important;

    .span.icon {
        padding: 4px !important;
    }

    img {
        width: 20px !important;
        height: 20px !important;
    }
}

.emotion-confirmation-modal .modal-container {
    width: 500px;
}

.compare-two-gps-modal .modal-container {
    width: 500px;
}

.create-gp-modal .modal-container {
    width: 530px;
}

.create-gp-modal .create-gp-content {
    text-align: left;
}

.create-gp-modal .modal-subtitle {
    margin-bottom: 15px;
    font-size: 90%;
}

.light-dropdown-menu {
    border: 1px solid rgba(255, 255, 255, 0.064) !important;
    background-color: rgba(255, 255, 255, 0.08) !important;

    optgroup {
        background-color: #666666;
        color: white;
        -webkit-appearance: none;
    }
}

.gp-development-modal .modal-container {
    width: 500px !important;
}

.edit-section-modal .modal-close img {
    right: 19px;
    top: 23px;
}

.edit-section-modal .dropdown-menu-container {
    min-width: calc(100% - 40px) !important;
    margin-top: -1px !important;
    left: 20px !important;
    height: 76px;
    z-index: 999999999 !important;
}

.edit-section-modal .dropdown-menu {
    margin-left: 0px !important;
    display: block;
    width: 100%;
    margin-top: 10px;
}

.edit-section-modal .dropdown-title {
    width: calc(100% - 27px) !important;
}

.layer-menu {
    margin-left: 250px;
    margin-top: -10px;
    // position: fixed!important;	// without position fixed, the menu got cut off by overflow hidden

    &.bottom {
        bottom: 0px;
    }
}

.test-report input {
    width: 220px;
}

.test-report {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
}

.test-report .button {
    display: inline-block;
}

.large-scale-tests-button {
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    background-color: blue;
    width: 200px !important;
}

.large-scale-tests-button:hover {
    background-color: #00add8;
}

#spectator-container {
    overflow: scroll;
}

.cdk-overlay-container {
    z-index: 999999999;
}

.layer-settings-button {
    margin-right: 2px;
    vertical-align: top;
    display: inline-block;
    margin-left: -33px;

    img {
        width: 15px !important;
        margin-top: -1px !important;
    }
}

.layer-settings-button:hover {
    cursor: pointer;
    opacity: 0.85;
}

.color-picker-container {
    width: calc(100% - 4px) !important;
}

.mat-select-panel {
}

.listening-test-textarea {
    color: black;
}

.mat-form-field {
    display: block !important;
}

.json-uploader-container {
    overflow-y: scroll;
    height: 94%;
    padding-bottom: 50px;
}

.mat-form-field-flex {
    background-color: white !important;
    width: 100% !important;
}

.midi-note-container {
    position: absolute;
}

.changelog-date {
    float: right;
    font-size: 80%;
    font-style: italic;
    margin-top: 4px;
}

.gp-library-filters-modal .modal-container {
    width: 500px;
}

.style-filter-input {
    display: inline-block;
    width: 110px;
}

.filter-columns {
    text-align: left;

    .settings-row {
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .settings-row-title {
            margin-top: -2px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.7);
        }
    }
}

.filter-column {
    display: inline-block;
    width: 250px;
    vertical-align: top;
}

.changelog-version-container a {
    color: white !important;
}

.changelog-version-container ul {
    padding-left: 20px !important;
}

.changelog-version-container li {
    margin-top: 20px;
}

.changelog-version-container li::marker {
    color: rgba(255, 255, 255, 0.5); /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.changelog-version {
    font-size: 150%;
    margin-top: 10px;
}

.changelog-version-container {
    margin-top: 20px;
    margin-bottom: 30px;
}

.text-effect-container {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.changelog-title {
    font-size: 230%;
    font-weight: 600;
}

.changelog-subtitle {
    font-size: 100%;
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: 4px;
}

.mobile-menu-container .create-button {
    width: 200px !important;
    margin-bottom: 10px !important;
}

.mac-electron-dragger-short {
    height: 15px !important;
}

.mac-electron-dragger {
    height: 20px;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 99999999;

    -webkit-app-region: drag;
    -webkit-user-select: none;
}

.auto-mix-options {
    text-align: left;
}

.auto-mix-radio-button {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
}

.pattern-region-block-container {
    display: inline-block;
    position: absolute;
    margin-left: var(--editor-padding);
}

.flexible-pianoroll-wrapper {
    height: calc(100% - 113px) !important;
    width: 100%;
    top: 62px;
    position: absolute;
}

// .drum-sequencer {
// 	height: 30%;
//     background-color: red;
//     position: relative;
// }

#layer-effects-rack {
    white-space: pre-wrap;
    // margin-top: 12px;
    position: relative;
}

#canvas-graph {
    z-index: 1;
}

#secondary-canvas-graph {
    opacity: 0.5;
}

#automation-chart-container canvas {
    position: absolute;
    margin-left: var(--editor-padding);
}

.sidebar-mac-electron {
    padding-top: 52px !important;
}

.radio-service .radio-image .loader {
    font-size: 3px !important;
}

.radio-service-play-volume {
    width: 25% !important;
    top: 38% !important;
}

.radio-player-right-control img:hover {
    opacity: 0.8;
    cursor: pointer;
}

.issue-placeholder-delete:hover {
    cursor: pointer;
    opacity: 0.8;
}

.issue-placeholder {
    margin-top: 4px;
    margin-bottom: 4px;
}

.issue-placeholder-delete {
    margin-top: -4px;
    vertical-align: middle;
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-left: 20px;
}

.listening-test-modal input {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 110%;
}

.listening-test-modal label {
    font-size: 110%;
    margin-right: 10px;
}

.listening-test-modal select {
    font-size: 110%;
    width: 70%;
}

.radio-player-right-control {
    margin-left: 17px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -6px;
}

.construction-mode-title {
    margin-top: 35vh;
    font-size: 155%;
    font-weight: 600;
    margin-bottom: 20px;
}

.construction-mode-community {
    margin-top: 15px;
}

.construction-mode-community a {
    color: white;
}

.construction-mode-subtitle {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.construction-mode {
    text-align: center;
    color: white;
    background-image: radial-gradient(
            circle at 40% 73%,
            rgba(18, 18, 18, 0.03) 0%,
            rgba(18, 18, 18, 0.03) 8%,
            transparent 8%,
            transparent 100%
        ),
        radial-gradient(
            circle at 14% 60%,
            rgba(191, 191, 191, 0.04) 0%,
            rgba(191, 191, 191, 0.04) 8%,
            transparent 8%,
            transparent 100%
        ),
        radial-gradient(
            circle at 79% 78%,
            rgba(240, 240, 240, 0.01) 0%,
            rgba(240, 240, 240, 0.01) 8%,
            transparent 8%,
            transparent 100%
        ),
        radial-gradient(
            circle at 54% 67%,
            rgba(11, 11, 11, 0.06) 0%,
            rgba(11, 11, 11, 0.06) 8%,
            transparent 8%,
            transparent 100%
        ),
        radial-gradient(
            circle at 0% 81%,
            rgba(64, 64, 64, 0.09) 0%,
            rgba(64, 64, 64, 0.09) 8%,
            transparent 8%,
            transparent 100%
        ),
        radial-gradient(
            circle at 49% 70%,
            rgba(135, 135, 135, 0.04) 0%,
            rgba(135, 135, 135, 0.04) 5%,
            transparent 5%,
            transparent 100%
        ),
        radial-gradient(
            circle at 33% 94%,
            rgba(246, 246, 246, 0.04) 0%,
            rgba(246, 246, 246, 0.04) 5%,
            transparent 5%,
            transparent 100%
        ),
        linear-gradient(45deg, rgb(222, 89, 164), rgb(28, 57, 223));
    width: 100%;
    height: 100%;
    position: fixed;
}

.public-player-button {
    display: inline-block;
}

#public-composition-container .loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.public-player-like:hover {
    cursor: pointer;
    opacity: 0.9;
}

.public-player-like {
    margin-left: 30px;
    vertical-align: middle;
    display: inline-block;
}

.public-player-like img {
    width: 36px;
}

#option-features li b {
    font-weight: 500;
    text-decoration: underline;
}

.pattern-region-block-title-container {
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    height: 15px;
}

.pattern-region-block-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 3px;
    font-size: 65%;
    text-transform: uppercase;
    font-weight: 600;
}

.pattern-region-block-canvas {
    position: absolute;
    height: calc(100% - 20px);
    margin-top: 16px;
    width: 100%;
    margin-left: 0px;
}

#option-features {
    list-style: none !important;
}

#option-features li {
    font-size: 105%;
    list-style: none !important;
}

.option-title-free {
    color: rgba(255, 0, 71, 0.97) !important;
}

.option-title-standard {
    color: rgb(11, 92, 255) !important;
}

.option-title-pro {
    color: rgb(255, 127, 0) !important;
}

.pro-button {
    background-color: rgb(255, 127, 0) !important;
}

.standard-button {
    background-color: rgb(11, 92, 255) !important;
}

.option-features-standard li:before {
    color: rgb(11, 92, 255) !important;
}

.option-features-pro li:before {
    color: rgb(255, 127, 0) !important;
}

.option-features-free li:before {
    color: rgba(255, 0, 71, 0.97) !important;
}

#option-title img {
    height: 17px;
    margin-right: 5px;
}

.option-discount-header-pro {
    background-color: #ff7f00 !important;
}

.option-discount-header-standard {
    background-color: #0b5cff !important;
}

.option-discount-header-invisible {
    background-color: transparent !important;
}

.copyright-not-owned:before {
    margin-left: -2px;
    content: "⚠" !important;
}

#option-features li:before {
    content: "✓";
    display: inline-block;
    margin-right: 10px;
}

#option-price-container {
    vertical-align: middle;
}

#option-price-or {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
}

#option-price-vat {
    font-size: 90%;
    font-style: italic;
    display: inline-block;
}

#option-price-billing-cycle {
    margin-top: 4px;
    font-size: 90%;
}

#or-container {
    display: inline-block;
    font-size: 80%;
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 7px;
}

#or-left {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    width: 12px;
}

#or-right {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    width: 12px;
}

.billing-cycle-switch-container:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer !important;
}

.option-prices-free {
    margin-top: 17px !important;
    margin-bottom: 46px;
}

.eula-mention-billing {
    font-size: 90%;
    width: 95%;
    margin-top: 25px !important;
}

.eula-mention-billing a {
    color: white !important;
}

#option-features .parameter-info {
    line-height: 17px !important;
}

#option-features .parameter-info img {
    -webkit-filter: invert(100%);
    margin-left: -4px !important;
    filter: invert(100%);
    opacity: 0.7;
    margin-top: 6px;
}

#option-features {
    font-size: 85%;
    color: #282828;
    margin-top: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 232px;
    line-height: 29px;
    margin-bottom: 27px;
}

#option-prices {
    text-align: left;
    margin-top: 5px;
}

#option-price {
    display: inline-block;
    color: #181818;
    font-weight: 600;
    margin-left: 12px;
    font-size: 150%;
}

#option-price-subtitle {
    display: inline-block;
    font-size: 75%;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    color: #818181;
}

#option-title {
    color: #383838;
    font-weight: 600;
    font-size: 110%;
    margin-bottom: 5px;
    margin-left: 13px;
    text-align: left;
}

.billing-plan-license {
    margin-top: 15px;
}

.billing-plan-title {
    font-size: 130%;
}

.billing-plan ul li {
    line-height: 25px;
    font-size: 90%;
}

#option-discount-header {
    transform: rotate(45deg);
    line-height: 20px;
    height: 20px;
    margin-right: -57px;
    margin-top: -100px;
    float: right;
    padding: 40px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: white;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 75%;
    text-align: center;
}

#pricing-option-container {
    overflow: hidden;
    width: 285px;
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#pricing-option {
    width: 315px;
    color: #383838;
    margin-top: 25px;
    vertical-align: top;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
    border-radius: 8px;
    display: inline-block;
    background-color: white;
    margin-left: 15px;
    margin-right: 15px;
}

.billing-plan {
    vertical-align: top;
    text-align: left;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 15px;
    width: 28%;
    color: black;
    background-color: white;
    max-width: 350px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
}

.aiva-loading-screen-title {
    color: white;
    font-weight: 500;
    font-size: 110%;
    margin-top: 35vh;
}

.aiva-loading-screen .loader {
    border-color: rgba(255, 255, 255, 0.28) !important;
    border-left-color: white !important;
    font-size: 4px;

    margin-top: 20px;

    width: 25px !important;
    height: 25px !important;
}

.aiva-loading-screen {
    text-align: center;
    background-image: linear-gradient(to top left, #1b59ff, #56009d);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.normal-cursor {
    cursor: normal !important;
}

.random-number {
    position: absolute;
    z-index: 99999999;
}

.note-descriptor {
    padding: 5px;
    font-size: 80%;
    font-weight: 400;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    position: fixed;
    margin-top: 8px;
    margin-left: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    backdrop-filter: blur(15px) !important;
    -webkit-backdrop-filter: blur(15px) !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    z-index: 999999;
    color: white;
    display: none;

    -webkit-user-select: none !important; /* webkit (safari, chrome) browsers */
    -moz-user-select: none !important; /* mozilla browsers */
    -khtml-user-select: none !important; /* webkit (konqueror) browsers */
    -ms-user-select: none !important; /* IE10+ */
    -webkit-touch-callout: none !important;
    user-select: none !important;
    text-decoration: none !important;
}

.cyberpunk-preset:hover {
    background-color: rgba(240, 255, 0, 1) !important;
    color: rgb(0, 187, 173) !important;
    border-color: rgb(0, 187, 173) !important;
}

.shortcuts-container-title {
    border-bottom: 1px solid #d2d2d230;
    padding-bottom: 5px;
    font-size: 95%;
    font-weight: 500;
    text-align: left;
}

.shortcut-container {
    text-align: left;
    font-size: 95%;
    line-height: 30px;
}

.shortcut-container-headline {
    text-align: left;
    font-size: 110%;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 20px;
}

.shortcut-container-separator {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.05);
    margin: 20px auto;
    width: 100%;
}

.shortcut-container-title {
    display: inline-block;
}

.shortcut-keys {
    position: absolute;
    right: 18px;
    margin-top: -32px;
}

.shortcut-top-icon {
    padding-top: 10px !important;
    padding-bottom: 11px !important;
    vertical-align: top;
}

.shortcut-icon {
    width: 14px;
    margin-right: 7px;
    vertical-align: middle;
    margin-top: -2px;
}

.shortcut {
    display: inline-block;
    margin-left: 9px;
    margin-right: 9px;
    vertical-align: middle;
}

.shortcut-subcontainer {
    display: inline;
    /* display: inline-block; */
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.9);
    border-radius: 6px;
    padding: 3px;
    font-size: 70%;
    font-weight: 600;
    padding-left: 5px;
    /* margin-top: -2px; */
    padding-right: 5px;
    margin-left: 3px;
    margin-right: 3px;
}

img {
    @include user-drag(none);
}

.selection-block {
    background-color: #73d1ff75;
    border: 1px solid #dbf2ff57;
    position: absolute;
    border-radius: 3px;
    z-index: 9999999999999;

    // margin-top: -200px;		// position correction after changing layout
    // margin-left: var(--editor-width);
}

// .spectator .selection-block, .influence .selection-block {
// 	margin-top: -26px!important;		// position correction after changing layout
// 	margin-left: 20px!important;
// }

.modified-pianoroll-marker-overlay-insert {
    position: absolute;
    background-image: repeating-linear-gradient(
            135deg,
            rgba(0, 255, 34, 0.35) 0px,
            rgba(0, 255, 34, 0.35) 2px,
            transparent 2px,
            transparent 4px
        ),
        linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(4, 2, 73, 0));
    height: calc(100vh - 45px);
    width: 100%;
    border-right: 1px solid rgba(0, 255, 34, 0.35);
    display: inline-block !important;
    margin-top: 45px;
}

.modified-pianoroll-marker-overlay-delete {
    position: absolute;
    background-image: repeating-linear-gradient(
            135deg,
            rgba(255, 0, 0, 0.35) 0px,
            rgba(255, 0, 0, 0.35) 2px,
            transparent 2px,
            transparent 4px
        ),
        linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(4, 2, 73, 0));
    height: calc(100vh - 45px);
    width: 100%;
    border-right: 1px solid red;
    display: inline-block !important;
    margin-top: 45px;
}

.modified-pianoroll-marker-overlay-regenerate {
    position: absolute;
    background-image: repeating-linear-gradient(
            135deg,
            rgba(255, 0, 221, 0.35) 0px,
            rgba(255, 0, 212, 0.35) 2px,
            transparent 2px,
            transparent 4px
        ),
        linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(4, 2, 73, 0));
    height: calc(100vh - 45px);
    width: 100%;
    border-right: 1px solid rgb(255, 0, 151);
    display: inline-block !important;
    margin-top: 45px;
}

.modified-pianoroll-marker-overlay-replace {
    position: absolute;
    background-image: repeating-linear-gradient(
            135deg,
            rgba(255, 166, 0, 0.35) 0px,
            rgba(255, 166, 0, 0.35) 2px,
            transparent 2px,
            transparent 4px
        ),
        linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(4, 2, 73, 0));
    height: calc(100vh - 45px);
    width: 100%;
    border-right: 1px solid rgb(255, 143, 0);
    display: inline-block !important;
    margin-top: 45px;
}

.modified-pianoroll-marker-insert {
    color: white !important;
    background-color: rgb(0 255 34 / 72%) !important;
    background-image: none !important;
}

.pianoroll-marker.modified-pianoroll-marker-delete {
    color: white !important;
    background-color: red !important;
    background-image: none !important;
}

.pianoroll-marker.modified-pianoroll-marker-delete:hover {
    background-color: rgb(255, 73, 73) !important;
    background-image: none !important;
    color: white !important;
}

.pianoroll-marker.modified-pianoroll-marker-regenerate:hover {
    background-color: rgb(228, 0, 137) !important;
    background-image: none !important;
    color: white !important;
}

.pianoroll-marker.modified-pianoroll-marker-regenerate {
    color: white !important;
    background-color: rgb(255, 0, 151) !important;
    background-image: none !important;
}

.pianoroll-marker.modified-pianoroll-marker-replace:hover {
    background-color: rgb(255, 175, 25) !important;
    background-image: none !important;
    color: white !important;
}

.pianoroll-marker.modified-pianoroll-marker-replace {
    color: white !important;
    background-color: rgb(255, 143, 0) !important;
    background-image: none !important;
}

@-webkit-keyframes blinker {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes blinker {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}

@keyframes blinker {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}

.blink-element-animation {
    -webkit-animation: blinker 0.4s linear;
    -moz-animation: blinker 0.4s linear;
    animation: blinker 0.4s linear;
}

.blink-element {
    background-color: white;
    position: fixed;
    z-index: 999999999;
    width: 100%;
    height: 100%;
}

.preset-usecases {
}

.preset-usecase-category {
    width: 220px;
    display: inline-block;
    vertical-align: top;
}

.preset-usecase-category-title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 600;
}

.preset-usecase img {
    height: 20px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}

.preset-usecase {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    width: 200px;

    line-height: 34px;
    -webkit-transition: background 0.07s; /* For Safari 3.0 to 6.0 */
    transition: background 0.07s; /* For modern browsers */
    background-color: #1a1848;
    border: 1px solid rgba(210, 210, 210, 0.1);
    vertical-align: middle;
    text-align: center;
    border-radius: 11px;
    font-size: 80%;
    font-weight: 500;
    margin-bottom: 6px;
}

.preset-usecase:hover {
    cursor: pointer;
    background-color: rgba(30, 27, 75, 1);
}

.preset-usecase:active {
    cursor: pointer;
    background-color: rgba(33, 30, 78, 1);
}

.refresh-gp-source-packs-modal .modal-container {
    width: 450px;
}

.admin-buttons-container {
    margin-top: 10px;
    margin-bottom: 10px;
}

.admin-button {
    display: inline-block;
}

.json-uploader-read-me {
    color: white;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: 10px;
}

.json-uploader-loader {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    left: 100px;
    font-size: 3px !important;
}

.json-uploader img:hover {
    cursor: pointer;
    opacity: 0.8;
}

.json-uploader img {
    width: 50px;
    margin-bottom: 10px;
}

.json-uploader {
    border: 2px solid transparent;
    border-radius: 11px;
    width: calc(100% - 220px);
    line-height: 30px;
    margin-left: 210px;
    font-weight: 600;
    margin-top: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: white;
    font-family: "Inconsolata";
    text-align: center;
}

.trackslist-loader-container {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    text-align: center;
    margin-top: 15px;
}

.scrollbar-vertical-offset-templates {
    margin-right: $template-menu-width + 10px;
}

.pianoroll-loader-subtitle {
    font-size: 80%;
    margin-top: 5px;
    font-weight: 400;
}

.pattern-region-block-container-selected {
    z-index: 999999;
}

.pattern-region-block-container-selected .pattern-region-block {
    box-shadow: 0px 0px 2px black;
}

.playback-button-add-channel img {
    vertical-align: middle;
    margin-right: 2px;
    margin-left: 4px;
    height: 7px;
}

.playback-button-add-channel:hover {
    background-color: #777777;
}

.playback-button-add-channel {
    width: 66px !important;
}

.add-channel-message {
    font-size: 90%;
}

.mute-selected-instrument-label {
    opacity: 0.8;
}

.solo-selected {
    background-color: rgb(255, 169, 0) !important;
}

.mute-selected {
    background-color: red !important;
}

.playback-button {
    text-align: center;
    width: 19px;
    display: inline-block;
}

.playback-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.mobile-only {
    display: none !important;
}

.trackslist-loader {
    left: -14px;
    font-size: 4px !important;
}

.effect-setting-ir {
    width: 360px;
    margin-right: 20px;
    vertical-align: top;
    margin-left: 4px;
}

.playback-modes {
    margin-right: 15px !important;
    margin-top: 6px;
}

.playback-mode:hover {
    cursor: pointer;
}

.playback-mode {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 11px;
    padding-right: 11px;
    display: inline-block;

    border: 1px solid rgba(0, 0, 0, 0.42);
    background-color: rgba(162, 165, 168, 0.31);

    font-size: 90%;
    font-weight: 500;
}

.color-indication-upload-influence {
    vertical-align: middle;
    margin-top: -2px;
}

.playback-mode-selected {
    background-color: rgba(240, 192, 0, 1) !important;
}

.effect-settings {
    text-align: left;
}

.effect-setting-subcontainer
    input[type="range"].slider-effect::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background-color: white;
    cursor: row-resize;
    height: 28px;
    width: 7px;
    z-index: 10;
}

.effect-setting-subcontainer input[type="range"].volume {
    border-radius: 0;
    background-color: #14cc00;
    // @include filter-gradient(#14cc00, #f70000, horizontal);
    // @include background-image(linear-gradient(left,  #14cc00 83%,#ff6a00 85%,#ff6a00 92%,#f70000 93%));
    overflow: hidden;
}

.effect-setting-subcontainer input[type="range"] {
    appearance: none !important;
    background: rgba(0, 0, 0, 0.28);
    border-radius: 1rem;
    box-shadow: inset 0.1rem -0.1rem 0.1rem rgba(black, 0.5),
        inset -0.1rem 0.1rem 0.1rem rgba(white, 0.5);
    height: 6px;
    width: 65px;
}

.effect-setting-subcontainer {
    display: inline-block;
}

.effect-setting-slider {
    text-align: center;
    width: 40px;
    background-color: #d2d2d211;
    padding: 4px;
    border-radius: 5px;
}

.slider-dry {
    margin-top: 88px;
    font-size: 90%;
    font-weight: 600;
}

.slider-wet {
    font-size: 90%;
    font-weight: 600;
}

.slider-wetness {
    margin-top: 9px;
    font-size: 90%;
}

.slider-effect {
    margin-left: -12px;
    display: block;
    margin-top: -5px;
}

.slider-effect,
.volume {
    transform: rotate(270deg) translate(-3rem);
}

.effects-container {
    margin-top: 30px;
    text-align: center;
}

.bass-boost-effect img {
    width: 95px;
    margin-top: 5px;
}

.bass-boost-effect img:hover {
    cursor: pointer;
}

.bassboost-selected {
    border-color: #a50000 !important;
    background-color: #e80000 !important;
}

.vinyl-selected {
    border-color: rgb(70, 70, 70) !important;
    background-color: rgb(0, 0, 0) !important;
}

.effect-list-item {
    display: inline-block;
    margin-left: 12px;
    margin-right: 12px;
    width: 105px;
    height: 105px;
    background-color: #8e8e8e;
    border: 4px solid #b7b7b7;
    border-radius: 50%;
}

.edit-effects {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: rgb(150, 150, 150);
    text-align: center;
    border-radius: 3px;
    margin-top: 6px;
}

.effect-settings-buttons {
    text-align: center;
}

.impulse-response {
    width: 110px;
    padding-top: 28px;
    padding-bottom: 28px;
    display: inline-block;
    vertical-align: middle;
    background-color: #d2d2d211;
    margin-right: 10px;
    margin-bottom: 9px;
    font-weight: 500;
    text-align: center;
    font-size: 90%;
    border-radius: 5px;
}

.impulse-response-selected {
    background-color: white !important;
    color: rgb(95, 95, 95) !important;
}

.impulse-response:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
    color: rgb(95, 95, 95);
}

.tracks-body.trackslist-single-playlist {
    height: calc(100% - 390px) !important;
    width: initial !important;
}

.track-list-comments {
    height: 35px;
    margin-left: 21px;
}

.track-list-comments:hover {
    cursor: pointer;
}

.composition-comment {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    font-size: 90%;
    // margin-left: 236px;
    position: absolute;
}

.edit-mode-circle {
    width: 9px;
    height: 9px;
    border-radius: 20px;
    background-color: rgba(50, 50, 50, 0.7);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}

.layer-list-item-comment {
    padding-left: 20px;
    height: 30px !important;
    line-height: 30px !important;
    // border-top: 1px solid rgba(50, 50, 50, 0.31);
}

.number-of-comments {
    background-color: red;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    margin-top: -1px;
    margin-right: 2px;
    margin-left: 3px;
    font-size: 95%;
    line-height: 17px !important;
}

.plans-container {
    width: 942px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    color: rgb(25, 25, 25);

    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    border: 1px solid rgba(210, 210, 210, 0.1);
    border-radius: 12px;
}

.plans-header {
    background-color: rgb(240, 240, 241);
    border-radius: 11px;
    color: rgb(93, 93, 93) !important;
    display: inline-block;
}

.last-row {
    border-bottom: none !important;
}

.eula-mention {
    font-size: 85%;
    margin-top: 15px;
    display: block;
}

.show-more-button {
    width: 100%;
    text-decoration: underline;
    padding-top: 22px;
    padding-bottom: 22px;
    border-radius: 25px;
    font-size: 90%;
    color: var(--secondary-color);
}

.right-click-menu-item-loading {
    text-align: center;
}

.show-more-button:hover {
    cursor: pointer;
    color: var(--secondary-color);
}

.plan-header {
    display: inline-block;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 180px;
    font-size: 90%;
}

#billing-container .plan-header .loader {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 2px;
    width: 10px !important;
    height: 10px !important;
}

.plan-header:hover {
    cursor: pointer;
    background-color: rgba(185, 185, 185, 0.178);

    border-radius: 11px;
}

.plan-row-container {
    vertical-align: top;
    display: inline-block;
    width: 180px;
    font-size: 95%;
    opacity: 0.9;
}

.plans-row-header {
    font-size: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.plans-row {
    padding-bottom: 15px;
    border-bottom: 1px solid #d2d2d257;
}

.plan-selected {
    font-weight: 600 !important;
    background-color: var(--primary-color) !important;
    color: white !important;
    border-radius: inherit;
}

.modal-text-input {
    height: 120px !important;
    resize: none;
}

.composition-comment-container {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.61);
    border-radius: 5px;
    line-height: 7px;
    color: rgb(71, 71, 71);
    font-weight: 700;
    width: 22px;
    height: 17px;
    margin-top: 10px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
}

.feature-notes {
    color: #f2f2f2 !important;
}

.changelog-modal .modal-background {
    background-color: rgb(0 0 0 / 55%) !important;
}

.changelog-modal {
    -webkit-touch-callout: text !important; /* iOS Safari */
    -webkit-user-select: text !important; /* Safari */
    -khtml-user-select: text !important; /* Konqueror HTML */
    -moz-user-select: text !important; /* Firefox */
    -ms-user-select: text !important; /* Internet Explorer/Edge */
    user-select: text !important; /* Non-prefixed version, currently */
}

.changelog-modal .modal-container {
    -webkit-touch-callout: text !important; /* iOS Safari */
    -webkit-user-select: text !important; /* Safari */
    -khtml-user-select: text !important; /* Konqueror HTML */
    -moz-user-select: text !important; /* Firefox */
    -ms-user-select: text !important; /* Internet Explorer/Edge */
    user-select: text !important; /* Non-prefixed version, currently */

    text-align: left;
    width: 600px;
    max-height: 750px;
    background-color: rgb(84 84 84) !important;
}

.drum-sequencer-value-warning-modal {
    width: 400px;
}

#preset-container {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
}

.shortcut-modal-scroll-container {
    height: 600px;
    padding-left: 20px;
    padding-right: 20px;
}

.shortcut-modal .modal-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 500px !important;
}

.customize-effects-modal .modal-container {
    width: 456px;
    padding-bottom: 25px !important;
}

.customize-effects-modal .modal-title {
    margin-bottom: 15px;
    margin-left: 4px;
}

.edit-effects:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

.edit-effects img {
    vertical-align: middle;
    width: 16px;
    margin-top: -4px;
    margin-right: 6px;
}

.influence-tips img {
    width: 15px;
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -2px;
}

.upload-influence-dropzone-subtitle {
    font-size: 70%;
    margin-top: 15px;
    display: block;
}

.no-audio-files-to-midi {
    margin-top: 10px;
    font-size: 90%;
}

#pianoroll-loader .track-icon-loading {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.ambient-preset img {
    width: 80px !important;
    height: 80px !important;
}

.pianoroll-loader-tip img {
    width: 20px;
    vertical-align: middle;
    margin-top: -3px;
}

.pianoroll-loader-tip-text {
    margin-top: 4px;
}

.pianoroll-loader-tip {
    line-height: 20px;
    font-style: italic;
    font-size: 90%;
    margin-top: 10px;
    font-weight: 400;
    padding-left: 15px;
    padding-right: 15px;
}

.pianoroll-loader-rendering {
    width: 600px !important;
    margin-left: -200px !important;
    margin-top: -150px !important;
}

.tips {
    font-weight: 600;
    margin-bottom: 7px;
}

.influence-tips {
    margin-bottom: 20px;
    margin-top: 10px;
    width: 500px;
    font-size: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    .link {
        color: var(--primary-color);
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }
}

.loader-container {
    text-align: center;
}

.instrumentation-button img {
    width: 18px !important;
    margin-left: -2px;
}

.select-influence {
    border: 2px solid var(--secondary-color) !important;
    border-radius: 4px;
}

.pianoroll-loader-subtitle {
    margin-top: 15px;
}

.billing-plans {
    letter-spacing: 0.2px;
}

.billing-faq-title {
    font-size: 150%;
    margin-bottom: 30px;
    font-weight: 500;
}

.copy {
    text-align: center;
    font-size: 75%;
}

.copy:active {
    background-color: var(--secondary-color) !important;
}

.dropdown-menu {
    width: 171px;
    height: 23px;
    margin-top: 0px;

    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: white;
    font-weight: 400;
    font-family: "Lato", Arial, Helvetica Neue, monospace;
}

select.dropdown-menu {
    padding-left: 7px;
}

.dropdown-menu:hover {
    cursor: pointer;
}

.automation-effect-settings-line-item {
    margin-top: 5px;
}

.automation-list-item {
    padding-left: 8px;
    padding-right: 8px;
}

.automation-list-item-hover {
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
}

.automation-list-item:hover {
    cursor: pointer;
}

.dropdown-menu img {
    width: 8px;
    margin-left: 5px;
    margin-top: 1px;
}

.dropdown-title {
    vertical-align: top;
    width: 144px;
}

.quote {
    display: inline-block;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 95%;
}

.preview-play {
    margin-left: 0px !important;
    width: 19px !important;
}

.billing-plans-mobile {
    display: none;
}

#regeneration-more {
    height: 13px;
    margin-top: -2px;
    margin-left: 5px;
    vertical-align: middle;
}

.loader-container .loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

#student-discounts img {
    width: 15px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 13px;
}

.tooltip-left .tooltiptext {
    margin-left: -170px !important;
    text-align: left;
}

.tracks-body {
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100% - 155px);
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.tracks-body #track-view {
    height: 100%;
}

.tracks-body #track-view #track-view-titles {
    background-color: rgba(0, 0, 0, 0.75);
    height: 32px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: rgba(255, 255, 255, 0.9);
    width: 100%;
}

.track-view-list-container {
    min-height: 100px;
}

@media (max-device-width: 475px) {
    #login-window-wrapper {
        left: 0px !important;
        margin-left: 0 !important;
        width: 100% !important;
        top: 0px !important;
        margin-top: 0px !important;
        height: 100% !important;
    }

    .aiva-logo {
        display: none;
    }

    #login-window .message-box,
    #createaccount-window .message-box,
    #reset-password-window .message-box {
        max-width: 95% !important;
        width: 324px !important;
    }

    #login-window-wrapper .window {
        border-radius: 0px !important;
        height: 100%;
    }

    #login-window .additional-options {
        max-width: 95% !important;
        width: 324px !important;
        font-size: 90%;
    }

    #terms-and-conditions {
        font-size: 80% !important;
    }

    #login-component .re-captcha.mobile-only,
    #createaccount-component .re-captcha.mobile-only {
        display: block !important;
        color: #858585;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        text-align: center !important;
        position: relative;
        max-width: 95% !important;
        width: 324px !important;
        font-size: 50% !important;
        /* needed to make the div stay at the bottom of the page and not overlap elements */
    }

    .re-captcha.mobile-only a {
        color: #858585 !important;
    }
}

@media (max-device-width: 375px) {
    #login-component .aiva-logo,
    #createaccount-component .aiva-logo {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    #login-component .re-captcha.mobile-only a,
    #createaccount-component .re-captcha.mobile-only a {
        color: #858585 !important;
    }
    .re-captcha.desktop-only {
        display: none;
    }
    #terms-and-conditions {
        font-size: 80% !important;
    }
    #reset-password-window #terms-and-conditions {
        /* display: flex;
		align-items: center; */
        margin: 10px auto;
    }
}

@media (max-width: 650px) {
    #public-playlist-container
        .composition-container
        .composition-item
        .composition-meta
        .composition-title {
        font-weight: 400 !important;
        font-size: 110%;
    }

    #public-player-background .composition-subtitle {
        display: none !important;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-meta
        .composition-subtitle {
        display: none !important;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-playback {
        width: 45px !important;
        height: 30px !important;
    }

    #public-playlist-container {
        width: 95% !important;
        max-width: 100%;
    }

    #public-playlist-container .composition-playback .playback-button {
        width: 13px !important;
        height: 13px !important;
        padding: 7px !important;
    }
}

@media (max-width: 680px) {
    .mobile-banner {
        padding: 4px !important;
        margin-left: 2px !important;
        font-size: 50% !important;
    }

    #logo-title {
        display: none;
    }

    .mobile-hamburger-menu {
        width: 22px !important;
        margin-top: 9px !important;
    }

    #track-view-list .ps__rail-y {
        display: none !important;
    }

    .subscription-modal .modal-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #download-bottom-container {
        width: 95% !important;
        max-width: 500px !important;
    }

    .modal-menus {
        display: none !important;
    }

    #preset-container {
        padding-bottom: 60px !important;
    }

    #billing-container {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    #billing-component .modal-menus {
        font-size: 90% !important;
    }

    #billing-component .modal-preset-title {
        margin-top: 15px !important;
    }

    #period-billing-switch {
        font-size: 80% !important;
        margin-top: 15px !important;
        margin-bottom: 10px !important;
        width: auto !important;
    }

    #period-billing-switch div {
        margin-left: 15px !important;
        margin-right: 15px !important;
    }

    #billing-container .licensing-button {
        margin-top: 15px !important;
        margin-bottom: 0px !important;
        font-size: 80% !important;
        display: none !important;
    }

    .trackslist-button-container {
        margin-top: 5px;
    }

    .track-parameters {
        display: none !important;
    }

    .path {
        display: none !important;
    }

    .trackslist-toolbar .toggle-track-view {
        display: none !important;
    }

    #component-header #subtitles {
        display: none;
    }

    .move-menu {
        display: none;
    }

    .playlists-content,
    #playlists-component {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .single-playlist .playlist-item-songs {
        display: none;
    }

    .playlists-title-container {
        margin-top: 15px;
    }

    .playlist-view:not(.single-playlist-view) .tracks-header {
        height: 0px !important;
    }

    .playlists-title-container {
        width: 95% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .create-with-influence-container .parameter-wrapper.key-signature {
        margin-left: 0px !important;
    }
}

/*-device*/

@media (max-width: 500px) {
    .mobile-grid-50 {
        width: 50% !important;
    }

    .currently-playing {
        font-size: 90% !important;
    }

    .radio-image-text {
        font-size: 160% !important;
    }

    .tracks-body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .playlist-item-name {
        bottom: 10px !important;
        left: 10px !important;
        font-size: 140% !important;
    }

    .playlist-item-songs {
        bottom: 10px !important;
        right: 10px !important;
        font-size: 110% !important;
    }
}

@media (max-width: 350px) {
    .radio-image-text {
        font-size: 140% !important;
    }
}

@supports not (-moz-appearance: none) {
    /* Add non-firefox CSS code here */
    @media only screen and (min-device-width: 1000px) {
        .hide-on-desktop {
            display: none;
        }
    }
    @media only screen and (max-device-width: 1000px) {
        .hide-on-desktop {
            display: block !important;
        }

        .radio-service .radio-title {
            max-width: 75% !important;
        }

        .radio-title-container {
            height: auto !important;
        }

        .currently-playing {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 87% !important;
        }

        .radio-services {
            height: 100% !important;
            overflow-y: scroll;
        }

        .radio-service {
            width: 94% !important;
            margin-left: auto;
        }

        .radio-services-container {
            width: 97% !important;
        }

        .billing-preview-modal.modal .modal-container {
            margin-left: 0px !important;
            margin-top: 0px !important;
        }

        #radio-component {
            padding-top: 59px !important;
            height: calc(100% - 109px) !important;
            width: 100% !important;
            padding-left: 0px !important;
        }

        .radio-component-title {
            margin-top: 10px !important;
            width: 200px !important;
            margin-left: 15px !important;
            font-size: 320% !important;
        }

        .radio-component-subtitle {
            margin-left: 15px !important;
        }

        #public-player-background #logo img {
            margin-top: -4px;
        }

        #public-player-background #logo {
            font-size: 150% !important;
        }

        .billing-preview-modal .preview-container {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }

        .billing-preview-modal.modal .modal-title {
            font-size: 90% !important;
            margin-left: 10px;
        }

        .subscription-status-info-modal .modal-title {
            font-size: 90% !important;
            margin-left: 10px;
        }

        .billing-preview-modal.modal .modal-background {
            z-index: 9999 !important;
            background-color: rgba(0, 0, 0, 0.85) !important;
        }

        .billing-faq-title {
            margin-bottom: 15px !important;
            font-size: 120%;
        }

        .faq-question {
            font-size: 90% !important;
        }

        .billing-plan-container {
            min-width: 130px;
            text-align: center;
        }

        #pianoroll-bottom-section {
            display: none;
        }

        #pianoroll-component .ps__rail-x {
            display: none !important;
            opacity: 0 !important;
        }

        #pianoroll-component .ps__rail-y {
            display: none !important;
            opacity: 0 !important;
        }

        .download-modal .modal-title.modal-preset-title {
            font-size: 90% !important;
            text-align: left !important;
        }

        #preset-list {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        .right-click-menu {
            margin-top: -60px;
        }

        .rename-modal .modal-title {
            width: 92% !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .modal-input {
            width: 90% !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        #public-composition-container #time-elapsed {
            width: 55px !important;
        }

        #public-composition-container #total-time {
            width: 55px !important;
        }

        #public-composition-container #progress-bar-wrapper {
            width: 65% !important;
        }

        #public-player-background .composition-title {
            font-size: 130% !important;
        }

        #public-player-background .composition-subtitle {
            font-size: 100% !important;
        }

        #public-composition-container #time-elapsed {
            font-size: 90%;
        }

        #public-player-background .public-player-button {
            width: 200px !important;
            font-size: 90%;
        }

        #public-player-background .play-button {
            width: 23px !important;
            height: 23px !important;
            margin-bottom: 17px !important;
        }

        .mobile-menu-container {
            margin-top: 50px;
        }

        .mobile-menu-items {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.85);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
            top: 0px;
            left: 0px;

            z-index: 999;
        }

        #sidebar-title-icon {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
        }

        #sidebar-title-icon.sidebar-title-community,
        #sidebar-title-icon.sidebar-title-logout {
            margin-left: -2px;
            margin-right: 14px;
            width: 24px !important;
            height: 24px !important;
        }

        .download-type.download-all {
            width: 90% !important;
            font-size: 80% !important;
            padding: 5px !important;
            height: auto !important;
            margin-top: 2px !important;
            margin-bottom: 5px !important;
        }

        .download-all .download-type-title {
            width: auto !important;
        }

        .download-type.download-all img {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            vertical-align: middle !important;
            margin-right: 8px;
            width: 20px !important;
            display: inline-block;
        }

        .download-type {
            width: 90% !important;
            font-size: 80% !important;
            padding: 5px !important;
            height: auto !important;
            text-align: left !important;
            margin-top: 3px !important;
            margin-bottom: 3px !important;
        }

        .download-type img {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            vertical-align: middle !important;
            margin-right: 8px;
            width: 20px !important;
            display: inline-block;
            margin-left: 8px;
        }

        .download-type-title {
            display: inline-block;
            vertical-align: middle;
        }

        .mobile-menu-item {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            max-width: 200px;
            color: white;
            font-size: 140% !important;
            width: 80%;
        }

        .mobile-menu-item .navbar-create-button {
            margin-left: 0px !important;
        }

        #logo img {
            margin-top: 0px !important;
            width: 35px !important;
        }

        #logo {
            margin-left: 15px !important;
        }

        .hide-on-mobile {
            display: none;
        }

        .mobile-hamburger-menu-close {
            margin-top: 15px !important;
            margin-right: 25px !important;
        }

        .mobile-hamburger-menu {
            z-index: 99999;
            width: 30px;
            float: right;
            margin-right: 35px;
            margin-top: 5px;
        }

        .mobile-hamburger-menu:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .mobile-banner {
            color: white;
            padding: 8px;
            font-weight: 500;
            background-color: orange;
            text-align: center;
            top: 0;
            font-size: 80%;
            display: inline-block !important;
            border-radius: 5px;
            margin-top: -4px;
            vertical-align: middle;
            margin-left: 10px;
        }

        .download-all {
            display: block !important;
            margin: auto !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .track-timesignature.track-title-mobile {
            display: none !important;
        }

        .right-click-menu.right-click-menu-mobile {
            border-radius: 0px !important;
            left: 0 !important;
            top: 0 !important;
            width: 100% !important;
            height: 100% !important;
            padding-top: 20%;
            padding-bottom: 0%;
            text-align: center;
        }

        .track-more-delete.track-title-mobile {
            display: none !important;
        }

        .track-more-download.track-title-mobile {
            display: none !important;
        }

        .track-love.track-title-mobile {
            display: none !important;
        }

        #subtitles {
            margin-top: 30px !important;
            width: 100%;
        }

        .subtitle {
            width: 50% !important;
        }

        .subtitle {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .modal-close {
            position: absolute;
            top: 0px;
            right: 5px;
        }

        .preset-parameter-list {
            z-index: 9999 !important;
        }

        .modal-container .modal-menus {
            margin-top: 30px;
            font-size: 90%;
        }

        .modal-container .modal-preset-title {
            margin-top: 0px !important;
        }

        #preset-description {
            display: none;
        }

        .preset {
            width: 80px !important;
            height: 66px !important;
            padding-top: 10px !important;
            font-size: 70% !important;
            margin-top: 10px !important;
        }

        .preset img {
            width: 30px !important;
        }

        .modal-preset-title {
            margin-top: 50px !important;
        }

        #billing-component {
            padding-left: 0px !important;
            width: 100% !important;
            padding-top: 60px !important;
        }

        #pianoroll-component {
            padding-left: 0px !important;
        }

        #pianoroll-component #pianoroll-grid {
            width: 100% !important;
        }

        .pianoroll-parameters-section {
            display: none !important;
        }

        .pianoroll-bottom-section-container {
            margin-right: 30px !important;
        }

        .pianoroll-edit-section {
            margin-right: 0px !important;
        }

        #app-body {
            min-width: 100% !important;
        }
        .track-parameters {
            width: 30% !important;
        }

        .tracks-body {
            max-width: 95% !important;
            height: calc(100% - 230px) !important;
        }

        .tracks-body.trackslist-single-playlist {
            height: calc(100% - 380px) !important;
        }

        .track-creationdate {
            display: none !important;
        }

        .track-duration {
            display: none !important;
        }

        .track-rank {
            display: none !important;
        }

        .track-more {
            float: none;
        }

        .mobile-menu-item:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .new-folder {
            margin-right: 5% !important;
        }

        .trackslist-toolbar {
            padding-right: 15px;
            padding-left: 15px;
        }

        .trackslist-button-container .create-button {
            margin-right: 5px !important;
            width: calc(50% - 20px) !important;
            margin-left: 5px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .trackslist-button-container {
            position: relative !important;
            width: 100% !important;
            max-width: auto !important;
        }

        .trackslist-loader {
            left: 0px !important;
        }

        .component-container {
            padding-left: 0px !important;
            min-width: 100% !important;
        }

        #sidebar {
            display: none !important;
        }

        #mytracks-component {
            padding-top: 30px;
        }

        #sidebar-mobile {
            padding: 10px;
            z-index: 999;
            width: 100%;
            display: block !important;
            position: fixed;
        }

        .platform-component-container {
            position: relative !important;
            width: 100% !important;
        }

        .single-playlist {
            max-width: 95% !important;
        }

        .single-playlist .playlist-discography {
            width: 160px !important;
            height: 160px !important;
        }

        .single-playlist-container .playlist-discography .playlist-item-name {
            font-size: 145% !important;
            bottom: 5px !important;
            left: 5px !important;
        }

        .single-playlist-container .playlist-discography .playlist-item-songs {
            font-size: 120% !important;
            bottom: 5px !important;
            right: 5px !important;
        }

        .single-playlist-container .playlist-info .description {
            margin-top: 15px !important;
            font-size: 140% !important;
        }

        .playlist-item-name {
            bottom: 10px !important;
            left: 10px !important;
            font-size: 140% !important;
        }

        .playlist-item-songs {
            bottom: 10px !important;
            right: 10px !important;
            font-size: 95% !important;
        }

        .track-parameters-tooltip-wrapper {
            display: none !important;
        }
    }
}

@supports (-moz-appearance: none) {
    /* Add firefox CSS code here */
    @media only screen and (min-width: 1000px) {
        .hide-on-desktop {
            display: none;
        }
    }

    @media only screen and (max-width: 1000px) {
        .hide-on-desktop {
            display: block !important;
        }

        .radio-service .radio-title {
            max-width: 75% !important;
        }

        .radio-title-container {
            height: auto !important;
        }

        .currently-playing {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 87% !important;
        }

        .radio-services {
            height: 100% !important;
            overflow-y: scroll;
        }

        .radio-service {
            width: 94% !important;
            margin-left: auto;
        }

        .radio-services-container {
            width: 97% !important;
        }

        .billing-preview-modal.modal .modal-container {
            margin-left: 0px !important;
            margin-top: 0px !important;
        }

        #radio-component {
            padding-top: 59px !important;
            height: calc(100% - 109px) !important;
            width: 100% !important;
            padding-left: 0px !important;
        }

        .radio-component-title {
            margin-top: 10px !important;
            width: 200px !important;
            margin-left: 15px !important;
            font-size: 320% !important;
        }

        .radio-component-subtitle {
            margin-left: 15px !important;
        }

        #public-player-background #logo img {
            margin-top: -4px;
        }

        #public-player-background #logo {
            font-size: 150% !important;
        }

        .billing-preview-modal .preview-container {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }

        .billing-preview-modal.modal .modal-title {
            font-size: 90% !important;
            margin-left: 10px;
        }

        .subscription-status-info-modal .modal-title {
            font-size: 90% !important;
            margin-left: 10px;
        }

        .billing-preview-modal.modal .modal-background {
            z-index: 9999 !important;
            background-color: rgba(0, 0, 0, 0.85) !important;
        }

        .billing-faq-title {
            margin-bottom: 15px !important;
            font-size: 120%;
        }

        .faq-question {
            font-size: 90% !important;
        }

        .billing-plan-container {
            min-width: 130px;
            text-align: center;
        }

        #pianoroll-bottom-section {
            display: none;
        }

        #pianoroll-component .ps__rail-x {
            display: none !important;
            opacity: 0 !important;
        }

        #pianoroll-component .ps__rail-y {
            display: none !important;
            opacity: 0 !important;
        }

        .download-modal .modal-title.modal-preset-title {
            font-size: 90% !important;
            text-align: left !important;
        }

        #preset-list {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        .right-click-menu {
            margin-top: -60px;
        }

        .rename-modal .modal-title {
            width: 92% !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .modal-input {
            width: 90% !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        #public-composition-container #time-elapsed {
            width: 55px !important;
        }

        #public-composition-container #total-time {
            width: 55px !important;
        }

        #public-composition-container #progress-bar-wrapper {
            width: 65% !important;
        }

        #public-player-background .composition-title {
            font-size: 130% !important;
        }

        #public-player-background .composition-subtitle {
            font-size: 100% !important;
        }

        #public-composition-container #time-elapsed {
            font-size: 90%;
        }

        #public-player-background .public-player-button {
            width: 200px !important;
            font-size: 90%;
        }

        #public-player-background .play-button {
            width: 23px !important;
            height: 23px !important;
            margin-bottom: 17px !important;
        }

        .mobile-menu-container {
            margin-top: 50px;
        }

        .mobile-menu-items {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: rgba(0, 0, 0, 0.85);
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
            top: 0px;
            left: 0px;

            z-index: 999;
        }

        #sidebar-title-icon {
            width: 20px !important;
            height: 20px !important;
            margin-right: 10px;
        }

        #sidebar-title-icon.sidebar-title-community,
        #sidebar-title-icon.sidebar-title-logout {
            margin-left: -2px;
            margin-right: 14px;
            width: 24px !important;
            height: 24px !important;
        }

        .download-type.download-all {
            width: 90% !important;
            font-size: 80% !important;
            padding: 5px !important;
            height: auto !important;
            margin-top: 2px !important;
            margin-bottom: 5px !important;
        }

        .download-all .download-type-title {
            width: auto !important;
        }

        .download-type.download-all img {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            vertical-align: middle !important;
            margin-right: 8px;
            width: 20px !important;
            display: inline-block;
        }

        .download-type {
            width: 90% !important;
            font-size: 80% !important;
            padding: 5px !important;
            height: auto !important;
            text-align: left !important;
            margin-top: 3px !important;
            margin-bottom: 3px !important;
        }

        .download-type img {
            margin-bottom: 0px !important;
            margin-top: 0px !important;
            vertical-align: middle !important;
            margin-right: 8px;
            width: 20px !important;
            display: inline-block;
            margin-left: 8px;
        }

        .download-type-title {
            display: inline-block;
            vertical-align: middle;
        }

        .mobile-menu-item {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            max-width: 200px;
            color: white;
            font-size: 140% !important;
            width: 80%;
        }

        #logo img {
            margin-top: 0px !important;
            width: 35px !important;
        }

        #logo {
            margin-left: 15px !important;
        }

        .hide-on-mobile {
            display: none;
        }

        .mobile-hamburger-menu-close {
            margin-top: 15px !important;
            margin-right: 25px !important;
        }

        .mobile-hamburger-menu {
            z-index: 99999;
            width: 30px;
            float: right;
            margin-right: 35px;
            margin-top: 5px;
        }

        .mobile-hamburger-menu:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .mobile-banner {
            color: white;
            padding: 8px;
            font-weight: 500;
            background-color: orange;
            text-align: center;
            top: 0;
            font-size: 80%;
            display: inline-block !important;
            border-radius: 5px;
            margin-top: -4px;
            vertical-align: middle;
            margin-left: 10px;
        }

        .download-all {
            display: block !important;
            margin: auto !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .track-timesignature.track-title-mobile {
            display: none !important;
        }

        .right-click-menu.right-click-menu-mobile {
            border-radius: 0px !important;
            left: 0 !important;
            top: 0 !important;
            width: 100% !important;
            height: 100% !important;
            padding-top: 20%;
            padding-bottom: 0%;
            text-align: center;
        }

        .track-more-delete.track-title-mobile {
            display: none !important;
        }

        .track-more-download.track-title-mobile {
            display: none !important;
        }

        .track-love.track-title-mobile {
            display: none !important;
        }

        #subtitles {
            margin-top: 30px !important;
            width: 100%;
        }

        .subtitle {
            width: 50% !important;
        }

        .track-title.track-title-mobile {
            width: calc(100% - 495px) !important;
        }

        .subtitle {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .modal-close {
            position: absolute;
            top: 0px;
            right: 5px;
        }

        .preset-parameter-list {
            z-index: 9999 !important;
        }

        .modal-container .modal-menus {
            margin-top: 30px;
            font-size: 90%;
        }

        .modal-container .modal-preset-title {
            margin-top: 0px !important;
        }

        #preset-description {
            display: none;
        }

        .preset {
            width: 80px !important;
            height: 66px !important;
            padding-top: 10px !important;
            font-size: 70% !important;
            margin-top: 10px !important;
        }

        .preset img {
            width: 30px !important;
        }

        .modal-preset-title {
            margin-top: 50px !important;
        }

        #billing-component {
            padding-left: 0px !important;
            width: 100% !important;
            padding-top: 60px !important;
        }

        #pianoroll-component {
            padding-left: 0px !important;
        }

        #pianoroll-component #pianoroll-grid {
            width: 100% !important;
        }

        .pianoroll-parameters-section {
            display: none !important;
        }

        .pianoroll-bottom-section-container {
            margin-right: 30px !important;
        }

        .pianoroll-edit-section {
            margin-right: 0px !important;
        }

        #app-body {
            min-width: 100% !important;
        }

        .track-parameters {
            width: 30% !important;
        }

        .tracks-body {
            max-width: 95% !important;
            height: calc(100% - 230px) !important;
        }

        .tracks-body.trackslist-single-playlist {
            height: calc(100% - 380px) !important;
        }

        .track-creationdate {
            display: none !important;
        }

        .track-duration {
            display: none !important;
        }

        .track-rank {
            display: none !important;
        }

        .track-more {
            float: none;
        }

        .mobile-menu-item:hover {
            cursor: pointer;
            opacity: 0.8;
        }

        .new-folder {
            margin-right: 5% !important;
        }

        .trackslist-toolbar {
            padding-right: 15px;
            padding-left: 15px;
        }

        .trackslist-button-container .create-button {
            margin-right: 5px !important;
            width: calc(50% - 20px) !important;
            margin-left: 5px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .trackslist-button-container {
            position: relative !important;
            width: 100% !important;
            max-width: auto !important;
        }

        .trackslist-loader {
            left: 0px !important;
        }

        .component-container {
            padding-left: 0px !important;
            min-width: 100% !important;
        }

        #sidebar {
            display: none !important;
        }

        #mytracks-component {
            padding-top: 30px;
        }

        #sidebar-mobile {
            padding: 10px;
            z-index: 999;
            width: 100%;
            display: block !important;
            position: fixed;
        }

        .platform-component-container {
            position: relative !important;
            width: 100% !important;
        }

        .single-playlist {
            max-width: 95% !important;
        }

        .single-playlist .playlist-discography {
            width: 160px !important;
            height: 160px !important;
        }

        .single-playlist-container .playlist-discography .playlist-item-name {
            font-size: 145% !important;
            bottom: 5px !important;
            left: 5px !important;
        }

        .single-playlist-container .playlist-discography .playlist-item-songs {
            font-size: 120% !important;
            bottom: 5px !important;
            right: 5px !important;
        }

        .single-playlist-container .playlist-info .description {
            margin-top: 15px !important;
            font-size: 140% !important;
        }

        .playlist-item-name {
            bottom: 10px !important;
            left: 10px !important;
            font-size: 140% !important;
        }

        .playlist-item-songs {
            bottom: 10px !important;
            right: 10px !important;
            font-size: 95% !important;
        }

        .track-parameters-tooltip-wrapper {
            display: none !important;
        }
    }
}

@media (max-device-width: 680px) {
    .track-bpm.track-title-mobile {
        display: none !important;
    }

    .track-title.track-title-mobile {
        width: calc(100% - 430px) !important;
    }

    #track-view-list {
        max-height: 100% !important;
    }

    .tracks-body {
        height: calc(100% - 160px) !important;
    }

    .tracks-body.trackslist-single-playlist {
        height: calc(100% - 300px) !important;
    }

    .trackslist-toolbar {
        padding-left: 5px;
    }

    #mytracks-component {
        padding-top: 40px !important;
    }

    .single-playlist {
        margin-bottom: 10px !important;
    }

    .single-playlist .playlist-discography {
        width: 120px !important;
        height: 120px !important;
    }

    .single-playlist-container .playlist-discography .playlist-item-name {
        bottom: 5px !important;
        left: 5px !important;
        font-size: 110% !important;
    }

    .single-playlist-container .playlist-info .description {
        margin-top: 5px !important;
        font-size: 100% !important;
    }

    .single-playlist-container .playlist-info .compositions-info {
        margin-top: 5px !important;
        font-size: 95% !important;
    }

    .playlist-item-name {
        bottom: 10px !important;
        left: 5px !important;
        font-size: 130% !important;
    }

    .playlist-item-songs {
        font-size: 80% !important;
    }
}

@media (max-device-width: 550px) {
    .track-key.track-title-mobile {
        display: none !important;
    }

    .track-instrumentation.track-title-mobile {
        display: none !important;
    }

    .track-title.track-title-mobile {
        width: calc(100% - 230px) !important;
    }

    .playlist-item-name {
        bottom: 10px !important;
        left: 7px !important;
    }

    .playlist-item-songs {
        bottom: 10px !important;
        right: 10px !important;
    }

    /* .tracks-body {
		height: calc(100% - 195px) !important;
	} */
}

#sidebar-mobile {
    display: none;
}

@media (max-device-width: 872px) {
    .billing-plans {
        text-align: left;
    }
}

@media (max-width: 1220px) {
    .faq-section {
        width: 96% !important;
    }

    .billing-plans {
        margin-right: auto;
        margin-left: auto;
        min-width: 800px !important;
        text-align: left;
        width: 90%;
    }

    .billing-plans-mobile {
        display: block;
    }

    .billing-plans-desktop {
        display: none;
    }

    #student-discounts {
        font-size: 90% !important;
    }

    .billing-plans {
        font-size: 100%;
    }

    .pricing-note {
        margin-top: 10px !important;
    }

    .billing-plan-header {
        margin-bottom: 10px !important;
    }

    #billing-container .billing-plan-header {
        width: 95% !important;
        font-size: 85% !important;
    }

    .side-panel .side-panel-element {
        padding-left: 13px !important;
        padding-right: 13px !important;
    }

    .billing-plan-header {
        width: 800px !important;
    }
}

.mobile-shown-billing-switch {
    display: none;
}

@media (max-device-width: 600px) {
    .mobile-hidden-billing-switch {
        display: none;
    }

    .mobile-shown-billing-switch {
        display: block !important;
    }

    .modal-button.plan-button {
        font-size: 60% !important;
    }
}

@media (max-height: 850px) {
    .changelog-modal .modal-container {
        max-height: 625px;
    }
}

@media (max-width: 1200px) {
    .changelog-modal .modal-close img {
        top: 30px;
    }

    .plans-container {
        width: calc(100% - 16px) !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        margin-left: 5px !important;
        border-radius: 0px;
    }

    .plans-row-header {
        font-size: 80%;
    }

    .pianoroll-parameters-section {
        display: none !important;
    }

    .plan-header {
        width: 130px;
        font-size: 80%;
    }

    .plan-row-container {
        width: 130px;
        font-size: 90%;
    }

    .eula-mention {
        font-size: 75%;
    }
}

@media (max-width: 900px) {
    .create-modal .modal-container {
        padding-bottom: var(--player-bar-height) !important;
    }

    .create-with-influence-modal .modal-container {
        .modal-button-wrapper {
            margin-bottom: 50px;
        }
    }

    .create-modal.emotion-view .modal-container {
        min-height: 100% !important;
        min-width: 100% !important;
        margin-top: 0px !important;
        margin-bottom: 50px !important;
        top: 0px !important;

        .modal-button-wrapper {
            margin-bottom: 50px;
        }
    }

    .preset {
        margin-top: 10px !important;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }

    .plans-container {
        margin-left: 0px !important;
    }
}

@media (max-device-width: 700px) {
    .plan-header {
        width: 100px !important;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 75% !important;
        font-weight: 600 !important;
        vertical-align: middle;
        padding-top: 10px;
        padding-bottom: 10px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .plans-row-header {
        font-size: 75%;
    }

    .plan-row-container {
        width: 104px !important;
        font-size: 85%;
    }

    .plans-container {
        overflow-x: scroll;
    }

    .eula-mention {
        font-size: 50%;
    }

    .create-with-influence-container .mode-change-view .warning-text {
        max-width: 450px !important;
        margin: auto !important;
    }

    .create-with-influence-modal .modal-container {
        width: 450px !important;
    }

    .create-modal.emotion-view .modal-container {
        width: 450px !important;
    }
}

@media (max-device-width: 350px) {
    .billing-cycle-switch {
        font-size: 90%;
        width: 289px !important;
        min-width: 0px !important;
    }

    #pricing-option {
        margin-left: 0px !important;
        margin-right: 0px !important;
        width: 300px !important;
        margin-top: 10px !important;
    }

    #pricing-option-container {
        padding: 8px !important;
    }

    .plan-header {
        font-size: 60% !important;
        width: 82px !important;
    }

    .plan-row-container {
        width: 84px !important;
        font-size: 75%;
    }

    #period-billing-switch label {
        width: 30px !important;
    }

    .checkbox-label {
        height: 20px !important;
    }

    .show-more-button {
        font-size: 80%;
    }

    #period-billing-switch div {
        font-size: 80%;
    }

    .modal-button {
        font-size: 70%;
        line-height: 15px;
    }

    .checkbox-label:before {
        width: 20px !important;
        height: 20px !important;
        top: 0px !important;
    }

    .ios-toggle:checked + .checkbox-label:before {
        left: calc(100% - 18px) !important;
    }
}

@media (max-width: 1250px) {
    .create-with-influence-modal .modal-container.ensemble-selection {
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 100% !important;
        margin-left: 0px !important;
        left: 0px !important;
    }

    .create-modal .modal-container #preset-suggestion {
        display: none;
    }

    .preset {
        height: 70px !important;
        margin-top: 10px !important;
        padding-top: 15px !important;
    }

    .preset img {
        width: 30px !important;
    }
}

html {
    font-smooth: 2em !important;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility !important;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
}

body {
    font-family: lato, "Helvetica Neue", Helvetica, "Segoe UI", Arial,
        -apple-system, BlinkMacSystemFont, sans-serif;
    margin: 0px;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 500px;
}

#public-player-background {
    background-color: #252321;
    width: 100%;
    height: 100%;
    padding-top: 35px;

    color: white;
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}

#public-player-background .message-box {
    text-align: center;
}

#public-player-background .public-player-button {
    margin-left: auto;
    margin-right: auto;
    background-color: var(--secondary-color);
    color: white;
    font-weight: 600;
    width: 220px !important;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border: 4px solid transparent;
    transition: all ease 0.3s;
    margin-top: 25px !important;
}
#public-player-background .public-player-button.playlist {
    width: 100% !important;
    border-radius: 5px !important;
}

#public-player-background .public-player-button:hover {
    cursor: pointer;
}

.pianoroll-layer-list #pianoroll-component-container {
    top: auto !important;
    overflow: hidden;
    position: relative;
    margin-left: 2px;
}

.pianoroll-layer-list .mini-pianoroll #pianoroll-grid {
    padding-top: 0px !important;
    overflow-x: visible !important;
    width: 100% !important;
}

#public-player-background .message-box {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
}

#public-player-background .composition-title {
    margin-top: 15px;
    font-size: 160%;
    color: white;
    font-weight: 600;
}

#public-player-background .composition-subtitle {
    margin-top: 5px;
    font-size: 120%;
    color: rgba(255, 255, 255, 0.644);
    font-weight: 400;
}

#public-composition-container,
#public-playlist-container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

#public-playlist-container {
    max-width: 900px;
    width: 80%;
    height: 100%;
}

@media (max-device-width: 475px) {
    #public-playlist-container {
        width: 100%;
        max-width: 100%;
    }

    #public-player-background #logo {
        margin-left: 0px !important;
    }

    #public-player-background .public-player-button.playlist {
        width: 80% !important;
        margin-top: 40px !important;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-playback {
        width: 40px !important;
    }

    /* changes fonts and size for mobile devices */
    #public-composition-container,
    #public-playlist-container {
        margin-top: 20px;
    }
    #public-player-background #logo img {
        width: 30px !important;
    }

    #public-playlist-container .playlist-meta .playlist-headline {
        font-size: 0.8em;
        margin: 0px 0px !important;
    }
    #public-playlist-container .playlist-meta .playlist-name {
        font-size: 0.9em !important;
        margin: 0px 0px !important;
    }
    #public-playlist-container .playlist-meta {
        margin-bottom: 15px !important;
    }
    #public-playlist-container .playlist-meta .playlist-info {
        font-size: 0.8em;
    }
    #public-playlist-container .composition-title {
        font-size: 90% !important;
        margin-top: 5px !important;
    }
    #public-playlist-container .composition-subtitle {
        font-size: 75% !important;
    }
    .player-container .composition-meta .composition-subtitle {
        font-size: 75% !important;
    }
    #public-playlist-container .composition-container {
        height: calc(100% - 430px) !important;
    }
    #public-playlist-container .player-container {
        margin-top: 15px !important;
    }
    #public-composition-container,
    #public-playlist-container {
        margin-top: 25px;
    }
    #public-playlist-container .main-playback .playback-button {
        width: 20px !important;
        height: 20px !important;
        padding: 10px !important;
    }
    #public-playlist-container .composition-playback .playback-button {
        width: 12px !important;
        height: 12px !important;
        padding: 8px !important;
    }
    .crisp-client .cc-kv6t[data-full-view="true"] .cc-1xry .cc-unoo {
        bottom: 0px !important;
        margin-bottom: 5px !important;
    }
}

@media (min-width: 2000px) {
    .playlist-view:not(.single-playlist-view) .tracks-header {
        height: 35px;
    }
}

#public-playlist-container .loader {
    display: inline-block;
    font-size: 3px;
    width: 20px !important;
    height: 20px !important;
    margin-left: 0px;
    margin-right: 0px;
}

#public-playlist-container .track-icon img {
    width: 15px;
    height: 20px;
}

#public-playlist-container .playlist-meta {
    text-align: left;
    margin-bottom: 20px;
    margin-left: 10px;
}

#public-playlist-container .playlist-meta .playlist-headline {
    margin: 5px 0px;
}
#public-playlist-container .playlist-meta .playlist-name {
    margin: 5px 0px;
    font-size: 1.6em;
    font-weight: 600;
}
#public-playlist-container .playlist-meta .playlist-info {
    opacity: 0.6;
}

#public-playlist-container .composition-container {
    height: calc(100% - 520px);
    overflow: auto;
    border-radius: 5px;
    min-height: 300px;
}

#public-playlist-container .composition-container .composition-item {
    border: 2px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    width: calc(100% - 4px);
    margin-bottom: 4px;
    /* height: 41px; */
    font-size: 80%;
}

.sort-icon {
    width: 10px;
    margin-left: 5px;
}

#public-playlist-container .composition-container .composition-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

#public-playlist-container
    .composition-container
    .composition-item
    .composition-item-wrapper {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#public-playlist-container
    .composition-container
    .composition-item
    .composition-playback {
    width: 65px;
    margin-right: 10px;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
}

#public-playlist-container
    .composition-container
    .composition-item
    .composition-meta {
    flex: 1;
    text-align: left;
}

#public-playlist-container
    .composition-container
    .composition-item
    .composition-meta
    .composition-title {
    font-size: 120%;
    color: white;
    font-weight: 600;
    margin-top: 0px;
}
#public-playlist-container
    .composition-container
    .composition-item
    .composition-meta
    .composition-subtitle {
    margin-top: 5px;
    font-size: 1em;
    color: rgba(255, 255, 255, 0.644);
    font-weight: 400;
    margin-top: 0px;
}

#public-playlist-container
    .composition-container
    .composition-item
    .composition-duration {
    width: 60px;
}

/* #public-playlist-container .composition-container .composition-item .composition-meta .composition-title { margin: unset;} */

#public-playlist-container .composition-playback .playback-button {
    border-radius: 50%;
    border: 1px solid white;
    width: 15px;
    height: 15px;
    padding: 10px;
    cursor: pointer;
}
#public-playlist-container .composition-playback .playback-button img {
    width: 100%;
    margin-left: 1px;
}

#public-playlist-container .playback-button-pause img {
    margin-left: 0px !important;
}

/* Main player for playlist */
#public-playlist-container .player-container {
    margin-top: 25px;
}
#public-playlist-container .player-container .composition-meta {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.player-container .composition-meta .composition-title {
    font-size: 110% !important;
}
.player-container .composition-meta .composition-subtitle {
    font-size: 90% !important;
}
#public-playlist-container .main-playback {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 500px;
    height: 52px;
    display: flex;
}
#public-playlist-container .main-playback .controls {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    text-align: center;
}
#public-playlist-container .main-playback .controls .next,
#public-playlist-container .main-playback .controls .previous {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    margin-right: 2px;
    vertical-align: middle;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    opacity: 0.8;
}
#public-playlist-container .main-playback .playback-button {
    border-radius: 50%;
    border: 1px solid white;
    width: 25px;
    height: 25px;
    padding: 12px;
    cursor: pointer;
}
#public-playlist-container .main-playback .playback-button img {
    width: 100%;
    margin-left: 1px;
}
#public-playlist-container .main-playback .previous {
    background-image: url("assets/img/player/previous.svg");
    cursor: pointer;
}
#public-playlist-container .main-playback .next {
    cursor: pointer;
    background-image: url("assets/img/player/next.svg");
}

#public-playlist-container #progress {
    display: flex;
    /* margin-top: 20px; */
}
#public-playlist-container #progress-bar-wrapper {
    max-width: 90%;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 21px;
    display: inline-block;
}

#public-playlist-container #progress-bar-wrapper #progress-bar {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}
#public-playlist-container #progress-bar-wrapper #progress-bar-time {
    background-color: var(--secondary-color);
    height: 5px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
#public-playlist-container #progress #time-elapsed {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin-top: 14px;
    margin-right: 5px;
}
#public-playlist-container #progress #total-time {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin-top: 14px;
    margin-left: 5px;
}

/* #public-playlist-container .composition-playback #pause {} */

#public-composition-container #progress-bar-time {
    background-color: var(--secondary-color);
    height: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

#public-composition-container #progress-bar {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
}

#public-composition-container #time-elapsed {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin-top: 4px;
}

#public-player-background #logo {
    text-align: center;
    margin-top: 0px;
    font-size: 200%;
}

#public-composition-container .loader {
    font-size: 6px;
    width: 35px !important;
    height: 35px !important;
}

#public-composition-container #total-time {
    display: inline-block;
    width: 100px;
    vertical-align: middle;
    margin-top: 4px;
}

.folder-download-warning img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.folder-download-warning {
    margin-bottom: 10px;
}

.modal.share-modal .message-box,
.modal.share-folder-modal .message-box {
    text-align: center;
    margin: 10px 0px;
}

.share-gp-modal .modal-container {
    width: 480px;
}

.modal.share-modal .loader-container,
.modal.share-folder-modal .loader-container {
    margin: 10px auto;
    text-align: center;
}

.modal.share-modal .loader,
.modal.share-folder-modal .loader {
    border-top: 0.5em solid rgba(255, 255, 255, 0.05);
    border-right: 0.5em solid rgba(255, 255, 255, 0.05);
    border-bottom: 0.5em solid rgba(255, 255, 255, 0.05);
    border-left: 0.5em solid #3990f5;
}

.share-header {
    text-align: left;
    font-weight: 600;
    font-size: 90%;
    margin-bottom: 10px;
    margin-top: 5px;
}

.link-sharing-button {
    margin-left: 10px;
    color: rgb(0, 203, 255);
}

.link-sharing-button:hover {
    cursor: pointer;
    opacity: 0.8 !important;
}

.link-sharing-subheader {
    display: inline-block;
}

.link-copied {
    font-size: 80%;
    text-align: left;
}

.link-copied img {
    width: 12px;
    vertical-align: middle;
    margin-top: -1px;
    margin-right: 5px;
}

.link-sharing-input {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    margin-bottom: 20px;
    font-size: 90%;
    padding: 5px;
    padding-left: 7px;
    padding-right: 7px;
    text-align: justify;

    -webkit-touch-callout: auto !important; /* iOS Safari */
    -webkit-user-select: auto !important; /* Safari */
    -khtml-user-select: auto !important; /* Konqueror HTML */
    -moz-user-select: auto !important; /* Firefox */
    -ms-user-select: auto !important; /* Internet Explorer/Edge */
    user-select: auto !important; /* Non-prefixed version, currently */
}

.link-sharing-input:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
}

.link-sharing-input:active {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.link-sharing-input::selection {
    background: var(--secondary-color);
}

.link-sharing-input::moz-selection {
    background: var(--secondary-color);
}

#public-composition-container #progress-bar-wrapper {
    max-width: 90%;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: inline-block;
}

#public-composition-container #progress-bar-wrapper:hover {
    cursor: pointer;
}

#public-composition-container .play-button {
    margin-left: auto;
    margin-right: auto;
}

#public-player-background #progress {
    height: auto;
}

#public-player-background .play-button {
    margin-bottom: 30px;
    border-radius: 50%;
    border: 1px solid white;
    width: 32px;
    height: 32px;
    padding: 12px;
}

#public-player-background .pause-button img {
    width: 90% !important;
    margin-top: 2px;
}

#public-player-background .play-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

#public-player-background .play-button img {
    width: 100%;
    margin-left: 1px;
}

#public-player-background #logo {
    margin-left: -6px;
}

#public-player-background #logo img {
    width: 60px;
    margin-top: 0px !important;
}

.modal-blue-button.disabled-render-button {
    opacity: 0.6;
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.modal-blue-button.disabled-render-button:hover {
    cursor: not-allowed !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.section-list-container {
    margin-bottom: 25px;
}

.change-drums-modal .section-list-container {
    margin-bottom: 0px;
    height: 190px;
}

.selected-instrument-list-item {
    background-color: var(--secondary-color) !important;
}

.section-list-container .instrument-list-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.section-list-container .instrument-list-item:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.09);
}

.section-list-item-title-selected {
    background-color: var(--secondary-color) !important;
}

.section-list-container .instrument-list-item {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: calc(100% - 30px) !important;
    padding-left: 15px;
    padding-right: 15px;
    background-color: transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    background-color: rgba(255, 255, 255, 0.025);
}

.section-list-item {
    text-transform: capitalize;
    width: calc(95% - 20px);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    font-size: 90%;
    margin-top: 3px;
    margin-bottom: 3px;
}

.section-list-item img {
    width: 18px;
    vertical-align: middle;
    margin-right: 10px;
}

.section-list-item-title {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(255, 255, 255, 0.05);
}

.section-list-item-title:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

#chatio-container {
    bottom: 50px !important;
}

#chatio-eye-grabber-container {
    right: 90px !important;
    bottom: 40px !important;
}

.ps__thumb-y {
    background-color: white !important;
    margin-left: 5px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    width: 6px !important;
    cursor: pointer;
}

.tracks-body .ps .ps__rail-x:hover,
.ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    width: 15px !important;
    background-color: rgba(255, 255, 255, 0.1);
}

.ps .ps__rail-x:hover,
.ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    height: 8px !important;
    background-color: transparent !important;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: white !important;
    height: 6px !important;
}

.ps__thumb-x {
    position: absolute;
    bottom: 0px;
    background-color: white;
    margin-left: 5px;
}

#pianoroll-drum-header {
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: #003a69;
    z-index: 9999;
    top: 907px;
    padding-left: 70px;
}

#pianoroll-drum-header .drumkit-title {
    font-size: 85%;
    font-weight: 500;
    color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    display: inline-block;
    margin-left: 10px;
    margin-top: 3px;
    font-style: italic;
    margin-right: 305px;
    float: right;
}

.section-list-container {
    overflow: scroll;
}

.change-drums-modal .modal-preset-title {
    opacity: 1 !important;
}

.change-drums-modal .modal-preset-title:hover {
    cursor: auto;
}

.chord-container {
    position: absolute;
    z-index: 999999;
    top: 72px;
    padding-left: 20px;
    vertical-align: bottom;
    background-color: rgb(132, 132, 132);
    border-bottom: 1px solid rgb(40, 40, 40);
    line-height: 21px;
    height: 28px;
}

.spectator .chord-container {
    padding-left: var(--spectator-padding);
}

.chord-symbols {
    display: inline-block;
    font-size: 70%;
    font-weight: 600;
    overflow: hidden;
}

.change-drums-modal .modal-container {
    height: 300px;
    margin-top: -150px;
    width: 500px;
    margin-left: -250px;
    text-align: center !important;
}

#pianoroll-drum-header .change-drumkit-button:hover {
    cursor: pointer;
    background-color: rgb(0, 132, 255) !important;
    font-size: 85% !important;
}

#pianoroll-drum-header .change-drumkit-button {
    margin-left: 5px;
    margin-top: 3px;
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    display: inline-block;
    background-color: var(--secondary-color) !important;
    border: 1px solid rgba(255, 255, 255, 0.15);
    font-size: 85%;
}

* {
    @include user-select(none !important);
}

#pianoroll-component:active,
#pianoroll-component:hover {
    cursor: default;
}

#pianoroll-component {
    @include user-select(none !important);
    -webkit-touch-callout: none !important;
    text-decoration: none !important;
}

#pianoroll-component .ps__rail-y {
    display: block !important;
    opacity: 0.9 !important;
}

#pianoroll-component .ps__rail-x {
    display: block !important;
    opacity: 0.9 !important;
}

.component-container {
    min-height: 650px;
    min-width: 900px;
}

#app-body {
    width: 100%;
    height: 100%;
    min-width: 1000px;

    position: fixed;

    background-color: rgba(0, 0, 0, 0.55);
}

#component-header #title {
    font-weight: 600;
    font-size: 190%;
}

.subtitle-left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.subtitle-right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.selected-subtitle {
    background-color: var(--secondary-color) !important;
}

.subtitle {
    width: 250px;
    display: inline-block;
    font-size: 80%;
    font-weight: 600;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 0px;
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.5px;
    background-color: rgba(150, 162, 201, 0.19);
    line-height: 27px;
}

.subtitle:hover {
    cursor: pointer;
    color: white;
}

.subtitle-selected {
    color: white;
}

.subtitle-selected::after {
    content: "";
    height: 2px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    width: 80px;
    position: relative;
    top: 5px;
    display: block;
    margin: 0 auto;
}

.parameter-item .tooltiptext {
    top: auto !important;
    left: auto !important;
    margin-top: 32px !important;
    margin-left: -50px !important;
    line-height: 15px !important;
    text-align: left !important;
    font-size: 90% !important;
    text-transform: none !important;
}

.disabled-duration {
    color: rgba(255, 255, 255, 0.6) !important;
    background-color: rgba(255, 255, 255, 0.059) !important;
}

.radio-currently-playing {
    width: 100%;
    padding-bottom: 35px;
    margin-bottom: 30px;
}

.preset-parameter-list-item .disabled-duration:hover {
    background-color: none !important;
}

.animate-transform-0_1s {
    -moz-transition: transform ease 0.1s;
    -o-transition: transform ease 0.1s;
    -webkit-transition: transform ease 0.1s;
    transition: transform ease 0.1s;
}

.animate-0_1s {
    -moz-transition: all ease 0.1s;
    -o-transition: all ease 0.1s;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
}

.animate-0_2s {
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
}

.dropdown-title .tooltiptext {
    font-size: 105% !important;
    width: auto !important;
    left: 192px !important;
    margin-left: 0px !important;
}

.large-size-indicator {
    width: 22px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 7px;
}

.small-size-indicator {
    width: 13px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 7px;
    margin-top: 2px;
}

.slidecontainer {
    margin-left: 0px;
    margin-top: 9px;
}

.slider {
    display: inline-block;
    -webkit-appearance: none;
    width: 70px;
    height: 4px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.308);
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.mobile-banner {
    z-index: 9999;
    display: none;
}

.gp-container-compositions {
    .button-row {
        margin-right: 0px !important;
    }

    .tracks-body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    cursor: pointer;
}

.right-click-menu {
    color: white !important;
}

.note-about-publication {
    font-style: italic;
    font-size: 90%;
    margin-top: 10px;
}

.create-right-click-menu {
    color: white !important;
    width: 130px !important;
    min-width: 130px !important;
}

.trackslist-create {
    margin-top: 0px !important;
    background-color: var(--primary-color) !important;
    border-color: none !important;
    border-radius: 8px !important;

    img {
        width: 11px !important;
        height: 11px !important;
    }

    height: 32px !important;

    vertical-align: middle;
    border-radius: 8px !important;
    margin-top: -3px;

    color: white !important;
    margin-left: 5px !important;

    font-weight: 600;

    span.icon {
        background-color: transparent !important;
    }

    span.label {
        margin-top: -1px;
        margin-right: 5px !important;
        margin-left: 5px !important;
    }

    margin-right: 6px !important;

    padding: 0px 4px !important;
}

.or {
    margin: 20px 0px;
    font-style: italic;
}

#subtitles {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    color: black !important;
    width: 95%;
    max-width: 1400px;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 90%;
}

#mytracks-component .track-parameters {
    width: 28%;
    margin-right: 2%;

    text-transform: capitalize;

    vertical-align: middle;
}

.playlist-view .tracks-body:not(.trackslist-single-playlist) {
    height: calc(100% - 140px) !important;
}

.overflow-ellipsis {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

textarea {
    @include user-select(text !important);
}

.link-sharing-input {
    @include user-select(text !important);
}

textarea#send-your-feedback {
    resize: none;
    background-color: rgba(255, 255, 255, 0.4);
    color: black;
    outline: none;
    border: none;
    font-size: 90%;
    height: 115px;
    width: 100%;
}

#mytracks-component-header #mytracks-title {
    font-weight: 600;
    font-size: 150%;
}

#component-header {
    text-align: center;
    margin-top: 10px;
}

#search-bar-container {
    width: 540px;
    margin-left: auto;
    margin-right: auto;
}

.horizontal-separator {
    background-color: rgba(255, 255, 255, 0.1);
    width: 30%;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    border-radius: 250px;
}

.separator {
    position: absolute;
    margin-top: 20px;
    height: 4195px;
    width: 1px;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.15);
    vertical-align: top;
    pointer-events: none;
}

.separator-index {
    position: absolute;
    margin-top: -16px;
    display: inline-block;
    width: 25px;
    text-align: center;
    margin-left: -12px;
    font-size: 65%;
}

.bar-separator {
    background-color: rgba(255, 255, 255, 0.25);
    margin-top: 15px !important;
    height: 4200px !important;
}

.sixteenth-note-separator {
    background-color: rgba(255, 255, 255, 0.05);
    margin-top: 25px !important;
}

#feedback-container {
    position: absolute;
    padding: 10px;
    margin-top: -34px;
    border-radius: 3px;
    background-color: white;
    width: 280px;
    height: 150px;
    z-index: 9999999;
}

.path {
    padding-left: 20px;
    max-width: 1600px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.path-element {
    display: inline-block;
    margin-bottom: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 90%;
    opacity: 0.85;
}

.list-item-folder:hover {
    cursor: pointer;
}

.path-element:hover {
    text-decoration: underline;
    cursor: pointer;
    opacity: 1;
}

.navigation-chevron {
    width: 12px !important;
    margin-top: -2px !important;
    margin-left: 7px;
    margin-right: 7px !important;
}

.path-element img {
    margin-right: 12px;
    vertical-align: middle;
    margin-top: -3px;
    width: 20px;
}

#feedback-container .button {
    margin-top: 4px;
    margin-bottom: 8px;
    display: inline-block;
}

.open-button {
    margin-left: 1px;
}

#feedback-container .transparent-button {
    float: left;
}

#feedback-container .call-to-action-button {
    float: right;
}

.transparent-button {
    flex: 0 0 auto;
    padding: 3px 9px 3px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    word-break: keep-all;
    transition: box-shadow 0.08s ease-in, color 0.08s ease-in;

    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #4d597c5b;
    text-align: center;
    color: #4d597c;
}

.transparent-button:hover {
    background-color: #4d597c09;
}

.transparent-button:active {
    background-color: #4c5b880e;
}

#button-grey img {
    width: 15px;
    vertical-align: middle;
    margin-right: 10px;
    margin-top: -3px;
}

.download-quota-progress-bar-container {
    background-color: rgba(255, 255, 255, 0.2);
    width: 89%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    height: 2px;
}

.download-quota-subtitle {
    font-size: 80%;
    font-weight: 500;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 7px;
}

.download-quota-title {
    font-size: 70%;
    font-weight: 900;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7px;
    text-transform: uppercase;
}

.download-quota-container {
    opacity: 1 !important;
    margin-bottom: 12px !important;
    margin-left: -20px !important;
    font-size: 100% !important;
}

.download-quota-container:hover {
    cursor: auto !important;
}

.download-quota-progress-bar {
    height: 2px;
    border-radius: 20px;
    background-color: var(--primary-color) !important;
}

.feedback-button {
    width: 121px !important;
    font-size: 80%;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.start-drawing-message {
    opacity: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 70%;
    position: absolute;
    line-height: 25px;
    margin-top: 5px;
    margin-left: 5px;
    backdrop-filter: blur(10px);
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 12px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.29);
    z-index: 9999;
}

#button-grey {
    font-weight: 500;
    line-height: 20px;
    padding: 5px;
    padding-left: 11px;
    padding-right: 11px;
    text-align: center;
    font-size: 80%;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 8px;
}

#automation-chart-container {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}

.effects-rack-container-percussion {
    height: 190px !important;
}

.instrument-list-container {
    border-bottom: 1px solid #ffffff1f;
    padding-top: 190px;
}

.btn-big-add-instrument-title {
    margin-left: 4px;
}

.dial {
    background: rgb(170, 170, 170);
    background: radial-gradient(circle, #bdbdbd 0%, #9a9a9a 100%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
}

.add-instrument-drum {
    width: calc(100% - 13px) !important;
}

.pan {
    margin-top: 7px;
}

// .dial-container {
// 	margin-top: 10px;
// 	vertical-align: middle;
// 	display: inline-block;
// 	width: 65px;
// 	margin-left: 10px;
// }

#button-grey:hover {
    background-color: rgba(216, 216, 216, 0.4);
}

.call-to-action-button {
    flex: 0 0 auto;
    padding: 2px 9px 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    text-align: left;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;
    word-break: keep-all;
    transition: box-shadow 0.08s ease-in, color 0.08s ease-in;

    background-color: var(--secondary-color);
    border-radius: 4px;
    border: 2px solid transparent;
    text-align: center;
    color: white;
}

.call-to-action-button:hover {
    cursor: pointer;
    border-color: #00fcff;
}

.call-to-action-button:active {
    background-color: #00fcff;
}

.call-to-action:hover {
    background-color: #4e1bd8 !important;
}

#nb-of-variations {
    font-size: 200%;
    font-weight: 300;
    margin-bottom: 10px;
}

#variations-subtitle {
    margin-top: 50px;
    font-weight: 500;
    margin-bottom: 25px;
}

#create-component-container .track-title {
    width: 35% !important;
}

.global-layer-block:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.22);
}

.add-custom-layer {
    vertical-align: middle;
    color: white;
    background-color: var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.35);
    width: calc(var(--layerlist-width) - 11px);
    border-radius: 3px;
    font-size: 85%;
    text-align: center;
    -webkit-text-decoration-line: none !important;
    text-decoration-line: none !important;
    margin-left: auto;
    margin-right: auto;
    margin-left: 5px;
    text-align: center;
    line-height: 25px;
    position: relative;
    margin-top: 4px;
}

.add-custom-layer:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

.tracks-body-variations #track-view-list {
    overflow-y: scroll;
    max-height: 300px;
}

.tracks-body-variations .list-item-composition:active {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.tracks-body-variations #track-view {
    border-radius: 5px;
    overflow: hidden;
}

.download-checkmark {
    position: absolute;
    width: 12px !important;
    height: 12px !important;
    margin-top: 23px !important;
    margin-left: -8px !important;
}

#music-title {
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.downgrade-explanation {
    font-size: 90%;
}

#create-component-container #search-bar {
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 80, 0.125);
    height: 50px;
    border-radius: 80px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: left;
    width: auto;
    padding-left: 55px;
    padding-right: 55px;
}

#track-view-list .ps__rail-y {
    height: 100% !important;
    opacity: 1 !important;
    background-color: transparent;
}

#track-view-list .ps__rail-y:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    width: 16px !important;
}

.search-result #search-title {
    font-size: 80%;
    font-weight: 700;
}

.search-result #search-composer {
    font-size: 70%;
    font-style: italic;
}

#result-description {
    display: inline-block;
    width: 400px;
    margin-left: 18px;
    vertical-align: middle;
    margin-top: -23px;
}

.search-result {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.search-result:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
}

#result-thumbnail {
    width: 70px;
    height: 44px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: black;
}

#thumbnail-play {
    border-radius: 50%;
    position: relative;
    width: 32px;
    margin-left: 20px;
    margin-top: 6px;
}

.search-result:active {
    cursor: pointer;
    background-color: var(--secondary-color);
}

#nav-chevron {
    margin-right: -15px;
    float: right;
    margin-top: -22px;
}

#create-component-container .tracks-body {
    text-align: left;
}

.trackslist-button-container {
    width: calc(100% - 215px);
    max-width: 1600px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;

    .button-row {
        width: 320px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }
}

.trackslist-button-container .create-button {
    padding-left: 9px;
    padding-right: 10px;
}

#nav-chevron img {
    width: 5px;
}

#add-project {
    margin-top: 10px;
    font-size: 90%;
}

#add-project .loader {
    width: 17px !important;
    height: 17px !important;
    font-size: 3px !important;
    margin: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    right: 20px;
}

#add-project .text-button {
    width: 140px;
    text-align: center;
    margin-top: 7px;
}

#add-project .text-button:active {
    cursor: pointer;
    color: #00fcff;
}

#add-project input {
    font-size: 90%;
    width: 140px;
    border: none;
    outline: none;
    padding: 5px;
}

#search-results {
    text-align: left;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 540px;
    max-height: 320px;
    background-color: rgba(0, 0, 80, 0.125);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    overflow-y: scroll;
}

#player #button-grey img {
    width: 15px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
}

.support-button {
    line-height: 26px;
    margin-top: 7px;
    float: right;
    margin-right: 12px;
    color: white;
    font-size: 90%;
    background-color: var(--secondary-color);
    font-weight: 500;
    padding: 5px;
    padding-left: 11px;
    padding-right: 11px;
    text-align: center;
    border-radius: 8px;

    img {
        width: 15px;
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 8px;
    }
}

#player .support-button:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

#player #button-grey {
    line-height: 26px;
    margin-top: 7px;
    float: right;
    margin-right: 12px;
    color: white;
    font-size: 90%;
    background-color: rgba(255, 255, 255, 0.47);
}

#player #button-grey:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

#player #button-grey:active {
    background-color: #00c3ff;
}

#create-component-container #or {
    margin-top: 25px;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-size: 100%;
    font-weight: 700;
}

#create-component-container #search-bar input {
    font-size: 100%;
    height: 50px;
    width: 100%;
    border: transparent;
    background-color: transparent;
    outline: none;
    color: white !important;
    text-align: center;
    top: -37px;
    position: relative;
}

#create-component-container #search-bar input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

#create-component-container #search-icon {
    position: relative;
    vertical-align: middle;
    width: 20px;
    margin-top: 15px;
    margin-right: 15px;
    position: relative;
    left: -32px;
}

#search-influence-container {
    margin-top: 40px;
    opacity: 0.8;
}

.upload-secondstep .loader {
    font-size: 4px !important;
    width: 26px !important;
    height: 26px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.file-upload-error {
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 90%;
    font-weight: 500;
    border: 1px solid transparent;
    width: 500px;
    border-radius: 3px;
    color: white;
    background: #ff2929;
    margin: 10px auto;
}

#upload-midi-container {
    margin-top: 30px;
    opacity: 1;
}

#create-component-container #upload-bar img {
    width: 21px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
}

#create-component-container #upload-bar {
    line-height: 22px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 90%;
}

#upload-bar input[type="file"] {
    display: none;
    margin: 10px;
}

#upload-bar input[type="file"] + label {
    display: inline-block;
    margin: 20px;
    padding: 4px 32px;
    background-color: #ffffff;
    border: solid 1px #666f77;
    border-radius: 6px;
    color: #666f77;
}

#upload-bar input[type="file"]:active + label {
    background-image: none;
    background-color: #2d6c7a;
    color: #ffffff;
}

#create-component-container #upload-bar-label {
    width: 100%;
    display: block;
    padding-bottom: 8px;
    padding-top: 8px;
}

#create-component-container #upload-bar-label:hover {
    cursor: pointer;
}

#create-component-container #upload-bar {
    height: auto;
    border-radius: 30px;
    width: 380px;
    color: white !important;
    border-style: none;
    background-color: rgb(0, 217, 255);
    border: 2px solid transparent;
}

.slider-difficulty {
    margin-top: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.mobile {
    .slider-difficulty {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.slider-difficulty .slider-difficulty-title {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 500;
    font-size: 80%;
    opacity: 0.7;
}

.slider-difficulty .slider-difficulty-title {
    opacity: 1 !important;
    vertical-align: middle;
    text-align: left;
}

.slider-container:hover {
    cursor: pointer;
    opacity: 0.9;
}

.selected-slider-container {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color) !important;
    opacity: 1 !important;
}

// .selected-slider-container {
// 	background-color: var(--primary-color) ;
// 	border-color: var(--primary-color) !important;
// 	opacity: 1!important;
// }

// .selected-slider-container .slider-moveable-part {
// 	margin-left: 18px!important;
// }

// .slider-container {
// 	-moz-transition: all ease 0.15s;
// 	-o-transition: all ease 0.15s;
// 	-webkit-transition: all ease 0.15s;
// 	transition: all ease 0.15s;
// 	display: inline-block;
// 	border: 1px solid white;
// 	width: 30px;
// 	border-radius: 20px;
// 	height: 14px;
// 	vertical-align: middle;
// }

// .slider-moveable-part {
// 	-moz-transition: all ease 0.3s;
// 	-o-transition: all ease 0.3s;
// 	-webkit-transition: all ease 0.3s;
// 	transition: all ease 0.3s;

// 	background-color: white;
// 	margin-top: 2px;
// 	height: 10px;
// 	width: 10px;
// 	border-radius: 50%;
// 	position: absolute;
// 	margin-left: 3px;
// }

#create-component-container #upload-bar:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

#create-component {
    text-align: center;
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}

.tutorials-title {
    font-size: 200%;
    font-weight: 300;
    margin-bottom: 35px;
    margin-top: 50px;
}

.thumbnail-container img {
    width: 250px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.808);
}

.thumbnail-container img:hover {
    cursor: pointer;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
}

#instrument-selection-modal-middle-scrollbar {
    overflow-y: hidden;
}

.tutorial-container {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
    vertical-align: top;

    .tutorial-subtitle {
        font-size: 90%;
        width: 250px;
        margin-top: 5px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

// #tutorials-component {
// 	padding-top: 20px;
// 	padding-left: 225px;
// 	text-align: center;
// 	width: calc(100% - 195px);
// 	color: white;
// 	height: 100%;
// 	overflow-y: scroll;
// 	background: -webkit-linear-gradient(to top left, #297cca 0%, #255db8 27%, #2646b1 35%, #2d368f 50%, #22002e 100%);
// 	background: linear-gradient(to top left, #297cca 0%, #255db8 27%, #2646b1 35%, #2d368f 50%, #22002e 100%);
// }

.tutorials-title {
    font-size: 200%;
    font-weight: 300;
    margin-bottom: 35px;
    margin-top: 50px;
}

.thumbnail-container img {
    width: 250px;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.808);
}

.thumbnail-container img:hover {
    cursor: pointer;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 1);
}

.tutorial-container {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 25px;
}

#tutorials-component {
    padding-top: 20px;
    // padding-left: 225px;
    padding-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    text-align: center;
    color: white;
    height: 100%;
    overflow-y: scroll;
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}

.update-paypal-payment-details {
    font-style: italic;
    font-size: 80%;
    margin-bottom: 10px;
    text-align: center;
}

.payment-method-title {
    font-weight: 600;
    font-size: 130%;
    margin-bottom: 20px;
}

.default-payment-method {
    vertical-align: middle;
    margin-top: -38px;
    float: right;
    font-size: 80%;
}
.default-payment-method img {
    margin-left: 5px;
    vertical-align: middle;
    margin-top: -2px;
    height: 15px;
}

.default-payment-method-tooltip {
    cursor: pointer;
    position: relative;
}
/* Text box */
.default-payment-method-tooltip::before {
    position: absolute;
    top: 0px;
    left: 20px;
    background-color: #2b222a;
    border-radius: 4px;
    color: #fff;
    content: attr(aria-label); /* use aria-label as tooltip text */
    padding: 5px;
    text-transform: none;
    transition: all 0.1s ease-in !important;
    width: 160px;
}

/* general setup when not visible */
.default-payment-method-tooltip::before,
.default-payment-method-tooltip::after {
    color: #efefef;
    font-size: 10px;
    opacity: 0;
    pointer-events: none;
    text-align: center;
}

/* triggering the tooltip transition */
.default-payment-method-tooltip:focus::before,
.default-payment-method-tooltip:focus::after,
.default-payment-method-tooltip:hover::before,
.default-payment-method-tooltip:hover::after {
    opacity: 1;
    transition: all 0.75s ease;
}

.payment-method-container .full-input {
    width: 483px !important;
    display: block !important;
}

.payment-method-container .first-name-input {
    margin-right: 3px;
}

.payment-method-container .input-expiration-date {
    margin-right: 3px;
}

.payment-method-container .message-box {
    margin-bottom: 10px;
    text-align: center;
}

.billing-address-city {
    margin-right: 3px;
}

.billing-error-message {
    color: red;
    font-size: 90%;
    margin-top: 10px;
    text-align: center;
}

.billing-address-country {
    width: 100% !important;
    margin-bottom: 15px !important;
}

#billing-address-country {
    width: calc(100% - 1px) !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#billing-address-country.custom-select option {
    background-color: #272727;
    color: white;
    -webkit-appearance: none;
}

.plan-header-subtitle {
    padding-left: 3px;
    padding-right: 3px;
    margin-top: 15px;
    font-size: 80%;
    vertical-align: middle;
}

.plan-row-container .loader {
    vertical-align: middle;
}

select.billing-address-country {
    width: 100% !important;
    display: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #efefef;
    border-radius: 0px !important;
    height: 28px !important;
}

.payment-method-container .loader-container {
    text-align: center;
    margin-top: 20px;
}

.payment-method-container .loader {
    font-size: 5px;
    margin-left: auto;
    margin-right: auto;
    width: 19px !important;
    height: 19px !important;
}

.payment-method-container .input {
    margin-bottom: 3px;
    vertical-align: middle;
    display: inline-block !important;
    width: 232px;
    height: 17px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    outline: none;
    color: white !important;
}

.payment-method-container input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.payment-method-container {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.18);
    border: 1px solid rgba(210, 210, 210, 0.1);
    width: 500px;
    border-radius: 11px;
}

.paypal {
    border-left: 1px solid rgba(210, 210, 210, 0.1);
    border-right: 1px solid rgba(210, 210, 210, 0.1);
}

.paypal-button-row {
    height: 40px !important;
}
.paypal-container .info-text {
    opacity: 0.5;
    text-align: center;
}

.braintree-validation .braintree-validation-message {
    text-align: center;
    opacity: 0.5;
    margin: 5px 0px;
}

img.paypal-logo {
    filter: invert(100%);
}

.pricing-note {
    display: block;
    margin-top: 20px;
    font-size: 95%;
    text-align: center;
}

.add-payment-method {
    background-color: var(--primary-color);
    font-weight: 600 !important;
    width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0px !important;
    display: block !important;
    margin-top: 20px !important;
}

#billing-container {
    padding-left: 5px;
    padding-right: 5px;
}

.payment-method-selector-large {
    width: 274.5px !important;
}

#payment-method-selector {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: 1px solid rgba(210, 210, 210, 0.1);
    background-color: rgba(255, 255, 255, 0.18);
    width: 551px;
    border-radius: 8px;
    line-height: 30px;
    overflow: hidden;
    font-weight: 500;
    font-size: 90%;
}

.payment-method-selector-item.selected {
    background-color: var(--primary-color) !important;
}

.payment-method-selector-item:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.25);
}

.payment-method-selector-item.apple-pay img {
    margin-top: -4px !important;
}

.payment-method-selector-item img {
    height: 15px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}

.payment-method-selector-item {
    font-size: 105%;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    width: 183px;
}

#payment-method-selector .selector-item.apple-pay .logo-and-text span.text {
    font-size: 1.2em;
    margin-top: 2px;
}

#payment-method-selector .current-payment-method {
    position: absolute;
    bottom: -10px;
    right: -10px;
}

#payment-method-selector .current-payment-method::after {
    content: "✓";
    color: white;
    display: inline-block;
    width: 20px;
    height: 20px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: var(--secondary-color);
}

.radio-services-container {
    width: 95%;

    margin-top: 15px;
    max-width: 1550px;

    margin-left: auto;
    margin-right: auto;
}

.radio-services {
    height: calc(100% - 50px);
    width: 100%;
}

.radio-service {
    margin-right: auto;
    width: 90%;
    margin-bottom: 10%;
}

.radio-services-container {
    display: table;
}

.radio-currently-playing-player-love {
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;
}

.radio-currently-playing-player-loop {
    width: 18px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
}

.radio-currently-playing-player #progress {
    margin-top: 15px;
}

.radio-currently-playing-player #progress-bar-wrapper {
    max-width: 650px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.radio-currently-playing-player #time-elapsed {
    display: inline-block;
    margin-right: 15px;
}

.radio-currently-playing-player #total-time {
    display: inline-block;
    margin-left: 15px;
}

.radio-currently-playing-player #progress-bar-wrapper:hover {
    cursor: pointer;
}

.progress-bar-time-hovered {
    background-color: var(--primary-color) !important;
}

.radio-currently-playing-player #progress-bar {
    height: 4px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.35);
    overflow: hidden;
    margin-top: -8px;
}

#progress-bar-circle {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 50%;
    position: relative !important;
    margin-left: calc(25% - 6px);
    margin-top: -8px;
}

.radio-currently-playing-player #progress-bar-time {
    height: 4px;
    background-color: var(--secondary-color);
}

.radio-currently-playing-player-element {
    opacity: 0.85;
}

.radio-currently-playing-player-element:hover {
    opacity: 1;
    cursor: pointer;
}

.radio-component-title-icon {
    height: 30px;
    margin-left: 2px;
    margin-bottom: 5px;
    display: block;
}

.radio-currently-playing-player-skip {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    margin-right: 15px;
}

.radio-currently-playing-player-play img:hover {
    width: 75px;
    margin-top: 0px;
}

.radio-currently-playing-player-play img {
    width: 65px;
    margin-top: 5px;
}

.radio-currently-playing-player-play {
    width: 75px;
    height: 75px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    margin-right: 15px;
}

.radio-service-play {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
}

.radio-currently-playing-subtitle {
}

.radio-currently-playing-title {
    font-size: 185%;
    font-weight: 600;
    margin-top: 7px;
    margin-bottom: 20px;
}

.radio-service-play .loader {
    position: relative;
    top: 42%;
}

.radio-service-play img {
    position: relative;
    width: 35%;
    top: 32.5%;
}

.radio-service-play img:hover {
    width: 39%;
    top: 30.5%;
    cursor: pointer;
}

.radio-image-text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 170%;
    font-weight: 700;
    text-align: left;
    left: 10%;
    bottom: 8%;
    position: absolute;
}

.radio-service-style-image {
    border-radius: 8px;
    position: absolute;
    padding: 50%;
}

.radio-service .radio-image:hover {
    cursor: pointer;
}

.radio-header {
    height: 100%;
    width: calc(100% - 200px);
    position: absolute;
}

.radio-background-header {
    height: 100%;
    position: absolute;
    opacity: 0.1;
    width: 100%;
    /*background-image: url('assets/img/time-capsule-desktop-header2.jpg');*/
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.radio-image-background {
    position: absolute !important;
    background-image: url("assets/img/radio_background.svg");
    background-size: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

.radio-service .radio-image {
    position: relative;
    border-radius: 5px;

    padding: 50%;

    background-repeat: no-repeat;
}

.radio-service .radio-title {
    height: 35px;
    margin-top: 10px;
    font-size: 80%;
    text-align: left;
    font-weight: 600;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
}

.pricing-note img {
    width: 15px;
    margin-right: 8px;
    margin-top: -1px;
    vertical-align: middle;
}

.radio-title-container {
    position: relative;
    position: relative;
    height: 130px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: left;
}

.radio-component-title {
    font-family: apple-system, roboto, lato;
    margin-top: 60px;
    width: 95%;
    max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 380%;
    font-weight: 700;
    margin-bottom: 10px;
}

.radio-component-subtitle {
    font-weight: 500;
    width: 95%;
    max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 90%;
    opacity: 0.9;
    padding-left: 3px;
}

#changelog-component-container {
    overflow-y: scroll;
    height: calc(100% - 110px);
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.34);
    padding: 15px;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;

    max-width: 650px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

#radio-component {
    text-align: center;
    padding-left: var(--sidebar-width, 205px);
    width: calc(100% - var(--sidebar-width, 205px));
    color: white;
    height: 100%;

    background-color: #460092;
    /*background: linear-gradient(rgba(125, 101, 211, 1), rgba(37, 50, 131, 1) 50.42%);
	background: -webkit-linear-gradient(rgba(125, 101, 211, 1), rgba(37, 50, 131, 1) 50.42%);	*/
}

#billing-component {
    padding-top: 20px;
    text-align: center;
    padding-left: var(--sidebar-width);
    width: calc(100% - var(--sidebar-width));
    color: white;
    height: 100%;
    background: -webkit-linear-gradient(
        to top left,
        #62a5e8,
        #2c50c5 50.87%,
        #8161f5
    );
    background: linear-gradient(to top left, #62a5e8, #2c50c5 50.87%, #8161f5);
}

#changelog-component {
    padding-top: 20px;
    padding-left: var(--sidebar-width, 205px);
    width: calc(100% - var(--sidebar-width, 205px));
    color: white;
    height: 100%;
    background: -webkit-linear-gradient(
        to top left,
        #62a5e8,
        #2c50c5 50.87%,
        #8161f5
    );
    background: linear-gradient(to top left, #62a5e8, #2c50c5 50.87%, #8161f5);
}

#billing-component-wrapper {
    height: calc(100% - 70px);
}

.option-price-subtitle-billing-cycle {
    display: block !important;
    margin-left: 14px !important;
    text-align: left !important;
}

.billing-cycle-switch-container-selected {
    background-color: var(--primary-color) !important;
    font-weight: 600;
    color: white !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
    text-decoration: none !important;
}

.billing-cycle-switch {
    max-width: 450px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    display: inline-block;
    margin-top: 15px;
    background-color: rgba(218, 218, 218, 0.31);
    padding: 5px;
    width: 39%;
    min-width: 304px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px;
}

.billing-cycle-switch-container-monthly {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

#option-tldr {
    font-weight: 500;
    line-height: 22px;
    font-size: 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: left;
}

.billing-cycle-switch-container-selected
    .billing-cycle-switch-container-subtitle {
    color: white;
}

.billing-cycle-switch-container-subtitle {
    display: block;
    font-size: 85%;
    margin-top: 4px;
    font-weight: 600;
    color: rgb(0, 251, 255);
    text-decoration: underline;
}

.billing-cycle-switch-container {
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 8px;
    display: inline-block;
    font-weight: 600;
    color: white;
    width: 50%;
    vertical-align: middle;
}

.faq-answer .quote {
    -webkit-user-select: text !important; /* webkit (safari, chrome) browsers */
    -moz-user-select: text !important; /* mozilla browsers */
    -khtml-user-select: text !important; /* webkit (konqueror) browsers */
    -ms-user-select: text !important; /* IE10+ */
    -webkit-touch-callout: text !important;
    user-select: text !important;
    text-decoration: auto !important;
}

#pianoroll-component {
    height: calc(100% - 51px) !important;
    text-align: left;
    color: white;
    width: 100%;
    /*position: absolute;*/
    padding-left: var(--sidebar-width, 205px);
    background: -webkit-linear-gradient(
        bottom right,
        rgba(0, 211, 208, 0.9) 0%,
        rgba(0, 162, 255, 0.9) 50%,
        rgba(43, 0, 173, 0.9) 100%
    );
    background: linear-gradient(
        to top left,
        rgba(0, 255, 251, 0.9) 0%,
        rgba(0, 162, 255, 0.9) 50%,
        rgba(64, 0, 255, 0.9) 100%
    );
}

#pianoroll-component #empty-element {
    width: 100%;
    height: 50px;
}

.upload-influence-customize {
    display: inline-block;
    vertical-align: top;
    width: 370px;
    margin-left: 25px;
}

.upload-influence-customize-title {
    margin-top: 28px;
    margin-bottom: 10px;
}

.upload-influence {
    width: 360px;
    text-align: center;
    border-right: 1px solid rgba(255, 255, 255, 0.493);
    height: 190px;
    vertical-align: middle;
    display: inline-block;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.track-edit img {
    width: 15px !important;
}

.track-create img {
    width: 17px !important;
    margin-right: 0px !important;
}

.upload-influence:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.363);
}

.create-influence-mode-title {
    display: inline-block;
}

.create-influence-mode-title img {
    width: 15px;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 10px;
}

.first-item {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.last-item {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.parameter-wrapper-title {
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 600;
    opacity: 0.9;
    text-align: center !important;
}

.create-modal.emotion-view .parameter-wrapper-title {
    text-align: left !important;
}

#preset-settings-container .modal-title {
    text-align: center;
    margin-top: 25px;
}

#preset-settings-container .modal-subtitle {
    margin-top: 20px;
    margin-bottom: 10px;
}

#preset-settings-container .parameters-view {
    margin-bottom: 10px !important;
    text-align: left !important;
    width: 90%;
    margin: auto;
    max-width: 650px;
}

.disable-key-signature-hover:hover {
    cursor: not-allowed !important;
    background-color: rgba(202, 202, 202, 0.09) !important;
}

.disable-key-signature {
    opacity: 0.5 !important;
}

.emotion-warning-subtitle {
    margin-left: 4px;
    display: inline-block;
}

.emotion-warning-subtitle img {
    width: 15px;
    margin-top: -2px;
    margin-right: 4px;
    vertical-align: middle;
}

.parameter-item {
    border-radius: 8px !important;
    margin: 3px;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.219);
    color: rgb(255, 255, 255) !important;
    display: inline-block;
    border-left: none;
    line-height: 25px;
    font-size: 85%;
    width: 100px;
    text-transform: capitalize;
    text-align: center;
    vertical-align: middle;

    &:hover {
        background-color: rgba(255, 255, 255, 0.319) !important;
    }
}

.parameter-item:hover {
    cursor: pointer;
}

.parameters-emotion-view {
    .parameter-item {
        background-color: #34335b;

        &:hover {
            background-color: #535275;
        }
    }
}

.selected-item {
    background-color: white !important;
    color: rgb(71, 71, 71) !important;

    &:hover {
        background-color: white !important;
        color: rgb(71, 71, 71) !important;
    }
}

.create-modal.emotion-view .parameter-item.emotion {
    font-size: 180%;
    line-height: 45px;

    img {
        height: 24px;
        margin-top: 10px;
    }
}

.create-influence-mode-cancel:hover {
    cursor: pointer;
    opacity: 0.8;
}

.create-influence-mode-cancel {
    display: inline-block;
    float: right;
}

.create-influence-mode {
    background-color: rgb(0, 175, 15);
    border-radius: 5px;
    width: calc(100% - 260px);
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 213px;
    height: 50px;
    font-weight: 500;
    line-height: 50px;
    font-size: 110%;
    position: fixed;
    top: 16px;
    z-index: 999999;
    color: white;
}

.upload-influence-title {
    padding-top: 45px;
}

.upload-influence img {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    display: block;
}

#pianoroll-component-container {
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: nowrap;
    height: 100%;
}

.resizer-edge:hover {
    cursor: col-resize;
}

.pencil-pointer {
    cursor: url("./assets/img/editmodes/1_cursor.png"), auto !important;
}

.eraser-cursor {
    cursor: url("./assets/img/editmodes/2_cursor.svg"), 4 4, crosshair;
}

.resizer-cursor {
    cursor: col-resize !important;
}

.move-cursor {
    cursor: all-scroll !important;
}

.layer-list-item-title .dropdown-item-icon {
    margin-left: 10px;
    margin-top: -1px;
}

.template-settings-toggle {
    width: 8px !important;
    margin-left: 10px;
}

.automation-template-title-settings img {
    vertical-align: middle;
    width: 15px;
    margin-top: -2px;
    margin-right: 10px;
}

.automation-template-title-settings:hover {
    cursor: pointer;
}

.automation-template-title {
    font-weight: 600;
    text-align: left;
    margin-left: 12px;
    margin-bottom: 10px;
    font-size: 85%;
}

.dropdown-item-icon {
    width: 12px;
    vertical-align: middle;
    margin-right: 7px;
    margin-left: 3px;
}

.automation-submenu-setting .dropdown-menu-container {
    margin-top: 30px !important;
    height: 135px !important;
    min-width: 151px !important;
    margin-top: -131px !important;
}

.template-modal {
    .dropdown-menu {
        width: 331px !important;
        margin-bottom: 12px;
    }

    .dropdown-title {
        width: 303px !important;
    }

    .dropdown-menu-container {
        margin-top: 25px !important;
        min-width: 331px !important;
        left: 11px !important;
        height: 120px !important;
        z-index: 9999999 !important;
    }
}

.dropdown-menu-subcontainer:hover {
    cursor: pointer;
}

.dropdown-menu {
    z-index: 999999 !important;
}

#pianoroll-component #pianoroll-grid {
    // padding-top: 34px;
    display: inline-block;
    // background-color: rgba(0,0,0,0.2);
    width: calc(100% - var(--sidebar-width, 205px) - var(--layerlist-width));
    vertical-align: top;
    position: relative;
    overflow-x: scroll;
    left: var(--layerlist-width);
    // height: 100%;
    overflow: visible;
}

#video-controller-view {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.75);
}

#preset-preview {
    font-size: 80%;
    margin-top: 7px;
}

#preset-preview:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

#preset-preview img {
    width: 8px;
    margin-right: 5px;
}

.disabled-preset-container {
    vertical-align: top !important;
}

.preset-container {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
}

#video-controller-view #close:hover {
    opacity: 0.8;
    cursor: pointer;
}

#video-controller-view #close {
    position: fixed;
    width: 30px;
    height: 30px;
    right: 20px;
    top: 20px;
    background-image: url("assets/img/close.svg");
}

#video-controller-view #ytplayer {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 2%;
}

#video-controller-view .modal-green-button {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 87%;
}

.midi-note-selected {
    background-color: rgb(0, 235, 215) !important;
}

.midi-note-resize-left:hover {
    cursor: col-resize;
}

.midi-note-resize-right:hover {
    cursor: col-resize;
}

.custom-scrollbar-container {
    width: calc(100% - 5px);
    height: auto;
    position: relative;
    margin-left: auto;
    display: block;
    margin-right: auto;
}

.spectator #pianoroll-note-container {
    margin-top: var(--spectator-note-container-top-padding) !important;
    padding-bottom: 20px;
}

#pianoroll-note-container {
    display: inline-block;
    margin-top: 45px;
    width: 100%;
}

.black-note-border {
    background-color: #d2d2d2;
    height: 1px;
    position: relative;
    width: 11px;
    left: 10px;
    top: 4px;
}

#pianoroll-component #piano {
    padding-top: 75px;
    display: inline-block;
    z-index: 9999999999;
    width: 21px;
    padding-right: 0px;
    background-color: white;
    position: absolute;
    // margin-top: -12px;	// counter fx-rack margin
}

.dropdown-menu img {
    margin-right: 0px !important;
}

.pianoroll-layer-list {
    // margin-left: calc(var(--layerlist-width) - 20px);
    // margin-left: 20px;
    // margin-top: 62.5px;
    // margin-top: 98px;
}

.layer-list-header .dropdown-title {
    width: 132px;
}

.dropdown-title {
    width: 125px;
    display: inline-block;
    margin-left: 7px;
    text-align: left;
    overflow: hidden;
    vertical-align: middle;
}

.layer-list {
    min-height: 100%;
    position: absolute;
    left: 0px;
    width: var(--layerlist-width);
    background-color: rgb(132, 132, 132);
    border-right: 1px solid rgb(50 50 50 / 68%);
    z-index: 9999999;
}

.note-row {
    padding-left: 40px;
    height: 9px;
    width: 100%;
    display: block;
    border-bottom: 1px solid transparent;
}

.edit-pianoroll .mini-pianoroll .note-row {
    margin-left: -20px;
}

.dropdown-menu {
    display: block;
    width: 181px;
    margin-left: 5px !important;
    margin-top: -5px;
}

#pianoroll-end-gap {
    height: 1px;
}

.edit-pianoroll .note-row {
    padding-left: 0px !important;
    margin-left: 215px;
}

.csharp {
    margin-top: -3px !important;
}

.dsharp {
    margin-top: -6px !important;
}

.fsharp {
    margin-top: -2px !important;
}

.gsharp {
    margin-top: -4px !important;
}

.asharp {
    margin-top: -8px !important;
}

.black-note-row {
    background-color: rgba(50, 50, 50, 0.25);
}

.note-row-with-border {
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
}

#timeline-selection {
    z-index: 1111;
    background-color: rgba(200, 200, 200, 0.5);
    border: 1px solid white;
    position: absolute;
    left: 200px;
}

.right-edge-note {
    float: right;
}

.edge-note {
    height: 7.4px;
    display: inline-block;
}

input {
    -webkit-user-select: text !important; /* webkit (safari, chrome) browsers */
    -moz-user-select: text !important; /* mozilla browsers */
    -khtml-user-select: text !important; /* webkit (konqueror) browsers */
    -ms-user-select: text !important; /* IE10+ */
}

input[type="number"] {
    -moz-appearance: textfield;
}

.custom-scrollbar-vertical {
    position: fixed;
    right: 0px;
    width: 12px;
    top: 196px;
    height: calc(100% - 247px);
    z-index: 999999999999;
}

.custom-scrollbar-container-vertical {
    height: 100%;
    right: 2px;
    width: 8px;
    border-radius: 65px;
}

.custom-thumb-scrollbar-vertical {
    border-radius: 65px;
    height: 5px;
    width: 8px;
    position: absolute;
    backdrop-filter: blur(5px);
    background-color: #ffffffba;
}

.custom-thumb-scrollbar-vertical:hover {
    background-color: rgba(255, 255, 255, 0.904);
}

.custom-thumb-scrollbar-vertical:active {
    background-color: white;
}

.pianoroll-piano-background-color {
    background-color: rgb(140, 140, 140);
    position: fixed;
    height: 100%;
    width: 40px;
    z-index: 999;
    top: 65px;
}

.custom-scrollbar {
    position: fixed;
    background-color: #232323;
    width: calc(100% - var(--layerlist-width) - var(--sidebar-width));
    height: 16px;
    z-index: 999999;
}

.custom-thumb-scrollbar {
    width: 50px;
    height: 8px;
    position: absolute;
    margin-top: 3px;
    border-radius: 15px;
    vertical-align: middle;
    background-color: #ffffffba;
}

.custom-thumb-scrollbar:hover {
    background-color: rgba(255, 255, 255, 0.904);
}

.custom-thumb-scrollbar:active {
    background-color: white;
}

#timeline-cursor-selection-wrapper {
    position: absolute;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: calc(var(--editor-padding) - 4px);
    z-index: 9999998;
    display: block;
    height: 100%;
}

#timeline-cursor-selection-wrapper:hover {
    cursor: ew-resize !important;
}

#timeline-cursor {
    position: absolute;
    will-change: all;
    width: 1px;
    box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.37);
    background-color: white;
    height: 100%;
    top: 15px;
}

#timeline-cursor #pianoroll-grid {
    top: 0px !important;
}

.pianoroll-component-container-background {
    height: 100%;
    width: 100%;
    position: absolute;
}

.spectator #timeline-cursor-selection-wrapper {
    margin-left: calc(var(--spectator-padding) - 4px) !important;
    height: 400px;
}

.spectator #pianoroll-grid #timeline-cursor-selection-wrapper {
    margin-left: calc(var(--spectator-padding) - 25px) !important;
    height: 100%;
}

#pianoroll-grid-container {
    overflow-x: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}

#pianoroll-grid-container::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
    display: none;
}

.instument-list-item-delete {
    width: 14px;
    float: right;
    margin-right: 0px !important;
}

.instument-list-item-delete:hover {
    cursor: pointer;
    opacity: 0.8;
}

.instruments-list {
    overflow: scroll;
}

.instrument-list-item {
    text-transform: capitalize;
    width: calc(95% - 20px);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    background-color: rgba(255, 255, 255, 0.05);
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 90%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.instrument-list-item img {
    width: 18px;
    vertical-align: middle;
    margin-right: 10px;
}

.section-list-container .ps__rail-y {
    top: 81px !important;
    height: 200px !important;
    right: 15px;

    opacity: 1 !important;
}

.change-instrumentation-scroll-container .ps__rail-y {
    top: 80px !important;
    height: 270px !important;
    margin-right: 17px !important;
}

#timeline-temp-cursor {
    position: fixed;
    width: 1px;
    background-color: rgba(0, 153, 255, 0.9);
    height: 863px;
    top: 120px;
    z-index: 1000;
}

.create-modal-button.render-button {
    margin-top: 25px !important;
    margin-left: 6px !important;
    font-size: 90% !important;
    margin-bottom: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 181px;
}

.browser-item img {
    margin-bottom: 10px;
    width: 50px;
}

.browser-item-version {
    font-size: 90%;
}

.browser-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
    margin-bottom: 10px;
    text-align: center;
    display: inline-block;
}

.floating-menu .close img {
    width: 11px;
    vertical-align: middle;
    position: absolute;
    z-index: 99999;
    margin-left: 232px;
    top: 12px;
}

.compatibility-modal .modal-container {
    text-align: center;
    width: 600px;
}

.floating-menu .close img:hover {
    cursor: pointer;
    opacity: 0.7;
}

.floating-menu {
    position: absolute;
    padding: 9px;
    font-size: 80%;
    background-color: rgba(22, 22, 22, 0.85);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 245px;
    border-radius: 11px;
    white-space: initial !important;
    z-index: 9999999999;
    margin-left: 5px;
    margin-top: 57px;
}

.floating-menu .tips img {
    width: 15px;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 3px;
}

.create-modal-button.render-button img {
    width: 15px;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 7px;
}

.modal-button.create-modal-button.render-button {
    background-color: rgb(0, 207, 104) !important;
}

#pianoroll-bottom-section {
    height: 35px;
    background-color: rgba(63, 38, 191, 0.44);
    padding: 15px;
    padding-left: 20px;
    padding-top: 12px;
    font-size: 80%;
    font-weight: 500;
    z-index: 9999999;
    position: fixed;
    width: 100%;
    min-width: 1200px;
}

.color-indicator-wrapper {
    margin-right: 15px;
}

.color-map-indicator {
    margin-right: 4px;
    margin-top: -2px;
    display: inline-block;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    text-transform: uppercase;
}

.pianoroll-edit-info img {
    width: 14px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
}

.pianoroll-edit-info {
    margin-bottom: 5px;
}

.cursor-mode-title img {
    width: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 10px;
}

.cursor-mode-title .tooltip {
    display: inline-block;
}

.cursor-mode-title {
    vertical-align: top;
    font-size: 90%;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-left: 25px;
    width: 120px;
    font-weight: 500;
}

.cursor-mode-title .tooltip .tooltiptext {
    left: auto !important;
    white-space: initial !important;
    top: 4px !important;
    text-align: left;
    font-size: 100%;
    line-height: 15px;
}

.green-edit-mode {
    background-color: rgb(0, 255, 0);
}

.selected-white {
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
}

.cursor-container {
    width: 150px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 80%;
    font-weight: 500;
    display: inline-block;
}

.cursor-container:hover {
    cursor: pointer;
    text-decoration: underline;
}

.cursor-container img {
    width: 10px !important;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 4px;
}

.cursor-container .playback-button {
    padding-right: 10px;
    width: 74px !important;
    padding-left: 10px;
}

.cursor-mode-title {
    display: inline-block;
    font-size: 90%;
    font-weight: 500;
    margin-left: 20px;
}

.cursor-mode img {
    width: 12px;
    margin-right: 10px;
    vertical-align: middle;
}

.pianoroll-edit-info:hover {
    cursor: pointer;
    opacity: 0.9;
}

.pianoroll-top-icon-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    line-height: 20px;
}

.shortcut-modal .modal-close img {
    top: 7px !important;
}

.discard-changes-button {
    background-color: red !important;
}

.selected-spectator-mode {
    background-color: rgb(12, 168, 127) !important;
}

.pianoroll-top-icon {
    -webkit-transition: background 0.13s; /* For Safari 3.0 to 6.0 */
    transition: background 0.13s; /* For modern browsers */

    margin-top: 1px;
    background-color: rgb(156 170 247 / 46%);
    display: inline-block;
    border-radius: 5px;
    padding: 9px;
    padding-left: 13px;
    padding-right: 13px;
    margin-right: 5px;
    font-size: 100%;
    font-weight: 600;
}

.reverb-type-dropdown .dropdown-menu-container {
    margin-left: 74px;
}

.automation-effect-settings-title {
    font-size: 80%;
    font-weight: 500;
    display: inline-block;
    margin-left: 5px;
}

.automation-effect-settings {
    width: 235px;
}

.reverb-selected {
    border-color: #00c3ff !important;
    background-color: #0083e5 !important;
}

.pianoroll-top-icon:hover {
    cursor: pointer;

    background-color: rgba(189, 200, 253, 0.46);
}

.pianoroll-top-icon img {
    vertical-align: middle;
    height: 14px;
    line-height: 20px;
}

.color-indication-title {
    margin-bottom: 4px;
}

.pianoroll-bottom-section-container {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-left: 10px;
}

.composition-parameters {
    font-weight: 400 !important;
    font-size: 90%;
    text-transform: capitalize;
}

.composition-parameters-title {
    display: inline-block;
    font-style: italic;
    width: 150px;
}

.color-map-container {
    font-weight: 400 !important;
    font-size: 90%;
}

.pianoroll-bottom-section-container-title {
    margin-bottom: 3px;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 600;
}

.color-map-indicator-lime {
    background-color: lime;
}

.color-map-indicator-red {
    background-color: rgb(255, 58, 5);
}

.color-map-indicator-white {
    background-color: rgb(255, 255, 255);
}

.color-map-indicator-green {
    background-color: rgb(36, 255, 5);
}

.color-map-indicator-purple {
    background-color: rgb(118, 5, 255);
}

.color-map-indicator-yellow {
    background-color: rgb(254, 255, 5);
}

.color-map-indicator-blue {
    background-color: #00a3ff;
}

@keyframes fadein {
    from {
        transform: scaleX(0);
        opacity: 0.5;
    }
    to {
        transform: scaleX(1);
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        transform: scaleX(0);
        opacity: 0.5;
    }
    to {
        transform: scaleX(1);
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        transform: scaleX(0);
        opacity: 0.5;
    }
    to {
        transform: scaleX(1);
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        transform: scaleX(0);
        opacity: 0.5;
    }
    to {
        transform: scaleX(1);
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        transform: scale(0.5);
        opacity: 0.5;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.midi-note {
    position: absolute;
    background-color: var(--secondary-color);
    display: inline-block;
    vertical-align: top;
    z-index: 999997;

    height: 8px;

    border: 1px solid rgba(0, 0, 0, 0.6);
}

/*.midi-note:hover {
	border: 1px solid rgba(0,0,0,0.8);
	cursor: pointer;
}*/

.edit-pianoroll .midi-note {
    margin-left: 0px;
}

.cursor-container-alt-img {
    margin-right: 0px !important;
}

.percussion-note-container .midi-note {
    height: 20px !important;
    border-radius: 5px !important;
}

.edit-pianoroll #pianoroll-drum-mapping {
    position: relative;
    top: 0px;
    width: 100%;
    padding-top: 0px;
    // border-bottom: 1px solid rgba(50, 50, 50, 0.7);
}

.midi-note-percussion {
    background-color: lime !important;
}

.edit-pianoroll .midi-note-percussion {
    margin-top: 10px !important;
}

.drum-row-button {
    float: right;
    margin-top: 0px;
}

.drum-row-button img {
    height: 25px;
    margin-top: -2px !important;
}

.drum-row-button img:hover {
    cursor: pointer;
    opacity: 0.85;
}

#pianoroll-drum-mapping .dropdown-menu img {
    // margin-left: 0px!important;
    // margin-top: -2px!important;
    // margin-right: 10px!important;
}

#pianoroll-drum-mapping .dropdown-menu {
    // border: 1px solid rgba(0, 0, 0, 0.4)!important;
}

#pianoroll-drum-mapping .dropdown-menu .dropdown-title {
    // margin-top: -3px;
}

.dropdown-menu-drum-container {
    line-height: 32px;
    height: 36px;
    display: flex;
}

.drum-sample-border {
    background-color: rgba(255, 255, 255, 0.18);
    height: 1px;
    position: absolute;
    width: 100%;
    margin-top: 36px;
    padding-right: 20px;
    // margin-top: 11px;
}

.edit-pianoroll .drum-row {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 25px;
    height: 25px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: block !important;
}

.edit-pianoroll .drum-row img {
    margin-right: 10px;
    margin-top: -1px;
    vertical-align: middle;
    width: 16px;
}

.color-map-subcontainer {
    display: inline-block;
}

.midi-note-percussion {
    height: 10px;
    margin-top: 17px;
}

.edit-pianoroll #pianoroll-timeline {
    // padding-left: var(--layerlist-width);
}

/*
.tooltip .tooltiptext {
  visibility: hidden;
  width: 145px;
  background-color: rgba(0,0,0,0.85);
  backdrop-filter: blur(10px);
  color: #fff;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 75%;
  margin-left: 8px;
  
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}
*/

.settings-menu {
    margin-top: -100px;
    width: 200px;
    border-radius: 8px;
    position: absolute;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: rgb(12 12 12 / 62%) !important;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    z-index: 99999999;
    padding-top: 10px;
    padding-bottom: 10px;
}

.settings-element input {
    padding-bottom: 3px;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 120%;
    color: white;
    width: 36px !important;
    display: inline-block;
    text-align: center;
    margin-left: 0px !important;
}

.zindex-999 {
    z-index: 999;
}

.zindex-99 {
    z-index: 99;
}

.zindex-9 {
    z-index: 9;
}

.midi-note-thick-borders {
    border-width: 1.75px;
    border-radius: 2px;
}

.settings-element input:focus::placeholder {
    color: transparent;
}

.increment-button {
    margin-left: 6px;
    margin-right: 6px;
    font-weight: 700;
    font-size: 120%;
    display: inline-block;
}

.increment-button:hover {
    cursor: pointer;
    color: var(--secondary-color);
}

.layer-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 221px;
}

.settings-element input[type="number"]::-webkit-inner-spin-button,
.settings-element input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.settings-element-title {
    display: inline-block;
    font-weight: 600;
    font-size: 90%;
    margin-left: 13px;
    width: 90px;
}

.settings-element input {
    margin-left: 10px;
    width: 175px;
    margin-bottom: 5px;
}

.bpm-input {
    margin-top: 10px;
    padding-bottom: 8px;
    font-size: 100%;
    width: 150px;
    margin-left: 6px;
    display: block;
    border: none;
    background-color: transparent;
    outline: none;

    border-bottom: 1px solid white;
    color: white;
}

.bpm-input::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
}

.delete-instrument {
    background-color: red;
    width: 158px;
    font-weight: 600 !important;
}

.edit-pianoroll #pianoroll-header {
    // padding-left: calc(var(--layerlist-width) - 39px);
    // margin-left: 40px;
}

.edit-pianoroll .chord-container {
    // left: calc(var(--layerlist-width) - 19px);
    // padding-left: 20px;
}

.spectator #piano {
    padding-top: 0px !important;
}

.spectator #pianoroll-timeline {
    padding-left: var(--spectator-padding) !important;
}

.spectator #pianoroll-header {
    padding-left: 41px !important;
}

.spectator .custom-scrollbar {
    // z-index: 1;
    width: calc(100% - var(--sidebar-width));
}

#pianoroll-timeline {
    position: absolute;
    top: 16px;
    padding-left: var(--editor-padding);
    margin-bottom: 4px;
    height: 30px;
    background-color: #232323;
    text-align: left;

    -webkit-user-select: none !important; /* webkit (safari, chrome) browsers */
    -moz-user-select: none !important; /* mozilla browsers */
    -khtml-user-select: none !important; /* webkit (konqueror) browsers */
    -ms-user-select: none !important; /* IE10+ */
    -webkit-touch-callout: none !important;
    user-select: none !important;
    text-decoration: none !important;
}

#pianoroll-header {
    position: absolute;
    top: 45px;
    height: 25px;
    padding-left: var(--editor-padding);
    width: 100%;
    z-index: 999999;
    background-color: #404040;
    border-top: 1px solid rgb(40, 40, 40);
    border-bottom: 1px solid rgb(40, 40, 40);
}

.section-will-be-overridden .right-click-menu-background {
    width: calc(100% - 33px);
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    border-radius: 5px;
    margin-left: 4px;
}

.section-will-be-overridden-container {
    width: calc(100% - 33px);
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 70%;
    line-height: 18px;
    z-index: 9999;
}

.section-will-be-overridden {
    margin-top: 6px;
    position: absolute;
    z-index: 1;
    font-size: 90%;
    font-weight: 600;
    margin-left: calc(var(--editor-padding) + 2px);
    white-space: pre-wrap;
}

.pianoroll-marker:not(.influence):hover {
    cursor: pointer;
    background-color: #ffffff;
    color: #404040;
}

.dropdown-image {
    width: 8px !important;
}

.regenerate-menu {
    width: 350px !important;
    color: white;
}

#pianoroll-drum-mapping .dropdown-menu {
    // width: 287px;
    // margin-bottom: 10px;
    // background-image: linear-gradient(#606060,#4a4a4a);
    // align-self: center;
}

#pianoroll-drum-mapping .dropdown-menu .dropdown-title {
    // width: 264px;
}

#pianoroll-drum-mapping .dropdown-menu img {
    // width: 7px;
    // margin-left: 3px;
    // vertical-align: middle;
}

#pianoroll-drum-mapping .dropdown-menu-container {
    // width: 287px!important;
}

.drum-instrument-list:hover {
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
    cursor: pointer;
}

.pianoroll-marker {
    border-top: 0px;
    border-bottom: 0px;
    margin-top: -2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-right: 1px solid rgb(40, 40, 40);
    border-top: 1px solid rgb(40, 40, 40);
    border-bottom: 1px solid rgb(40, 40, 40);
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    font-size: 85%;
    display: inline-block;
    width: 200px;
    font-weight: 600;
}

.midi-note-start {
    border-left: 1px solid rgb(134, 211, 255);
}

.midi-note-end {
    border-right: 1px solid rgb(134, 211, 255);
}

.midi-note-empty {
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: top;
}

#piano .key {
    height: 10px;
    text-align: right;
}

#piano .key:active {
    background-color: var(--secondary-color);
}

#piano .key .keyname {
    color: black;
    font-size: 9px;
    font-weight: 900;
    margin-right: 5px;
    vertical-align: middle;
    position: relative;
    top: -2px;
    left: 4px;
}

#piano .black-key {
    height: 8.4px !important;

    width: 10px;
    /*margin-top: -5px;
	position: absolute;*/
    background-color: black;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

#piano .white-key {
    height: 10.15px !important;
    border-bottom: 1px solid white;
}

#piano .key-with-border {
    border-bottom: 1px solid #bebebe;
}

.waiting-on-browser-login-link {
    color: blue;
    text-decoration: underline;
}

.waiting-on-browser-login-link:hover {
    cursor: pointer;
}

.waiting-on-browser-login-italic {
    margin-top: 16px;
    margin-bottom: 14px;
    font-style: italic;
    font-size: 90%;
}

.waiting-on-browser-login .loader {
    margin-bottom: 10px;
    margin-top: 10px;
}

.waiting-on-browser-login {
    text-align: center;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
}

.update-modal .modal-container {
    background-image: url("assets/img/navbar/aiva_logo_transparent.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 400px;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.melody-note {
    z-index: 1003 !important;
}

.accompaniment-note {
    z-index: 1001 !important;
}

.bass-note {
    z-index: 1002 !important;
}

.update-modal .modal-title {
    text-align: center;
}

.update-modal .modal-subtitle {
    text-align: center;
}

.projects-body {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(100% - 260px);
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: scroll;
    text-align: center;
}

#mytracks-component {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(to top left, #1b59ff, #56009d);
    background: linear-gradient(to top left, #1b59ff, #56009d);
}

#myinfluences-component .mytracks-body {
    margin-top: 20px;
}

#myinfluences-component {
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}
#regenerate-button-wrapper {
    position: absolute;
    top: -50px;
    padding: 2px;
    background: linear-gradient(257deg, #4a41e3 0%, #27279e 30%, #00d4ff 100%);
    opacity: 0;
    cursor: pointer;
    z-index: 5;
    border-radius: 10px;
}

#regenerate-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 5px 9px;
    background-color: white;

    border: none;
    border-radius: 10px;

    &__icon {
        width: 18px;
        height: 25px;
    }

    &__text {
        color: black;
        font-size: 0.9rem;
    }
}

#regenerate-section-layer {
    position: absolute;
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
    background: linear-gradient(
        257deg,
        rgba(74, 65, 227, 1) 0%,
        rgba(39, 39, 158, 1) 60%,
        rgba(0, 212, 255, 1) 100%
    );
    opacity: 0;
    z-index: 5;

    #regenerate-section-layer__button {
        background-color: white;
        width: 100%;
        height: 100%;
        padding: 5px;
        border-radius: 50%;
        border: none;
        opacity: 0.95;
        cursor: pointer;

        img {
            width: 100%;
            cursor: pointer;
        }
    }
}

#myprojects-component {
    color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}

#project-component-header .create-button {
    width: 180px !important;
    display: inline-block;
    margin: 0px;
    margin-right: 20px;
}

.platform-component-container {
    // padding-left: 205px;
    // width: calc(100% - 205px);
    padding-left: var(--sidebar-width, 205px);
    width: calc(100% - var(--sidebar-width, 205px));
    position: fixed;
    height: 100%;
}

#preset-preview .loader {
    width: 10px !important;
    height: 10px !important;
    font-size: 2px;
    margin-right: 4px;
    margin-left: 0px;
    margin-top: 0px;
}

.loader {
    display: inline-block;
    font-size: 3px;

    width: 23px !important;
    height: 23px !important;

    margin-left: 26px;
    margin-right: 26px;
}

.loader-small {
    width: 15px !important;
    height: 15px !important;
    font-size: 3px !important;
}

.track-from {
    text-transform: capitalize;
    width: calc(47% - 430px);
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block;
}

.track-title-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 7px;
}

.track-instrumentation {
    text-transform: capitalize;
    width: 160px;
}

.track-bpm {
    text-transform: capitalize;
    width: 60px;
}

.track-timesignature {
    width: 60px;
}

.track-key {
    text-transform: capitalize;
    width: 90px;
}

.track-creationdate {
    width: 105px;
}

.track-modificationdate {
    width: 105px;
}

.track-timesignature {
    text-transform: capitalize;
}

.track-rank {
    /* position: relative; */
    width: 50px;
    padding-left: 10px;
}

#track-view-list .project-icon img {
    width: 16px;
    margin-left: 22px;
    vertical-align: middle;
    margin-top: -2px;
}

#track-view-list .project-name {
    width: 27% !important;
    white-space: nowrap;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

#track-view-list .project-client {
    width: 15%;
}

.track-title {
    padding-left: 8px;
    padding-right: 8px;
    width: calc(53% - 493px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top !important;
}

#track-view-titles div:hover {
    cursor: pointer;
    opacity: 0.85;
    text-decoration: underline;
}

.track-title-influence {
    width: 500px;
}

.track-instrumentation-influence {
    width: 200px;
}

#project-component {
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: linear-gradient(rgba(20,20,20,0), rgba(20,20,20,0.7));*/
    background: -webkit-linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
    background: linear-gradient(
        to top left,
        #297cca 0%,
        #255db8 27%,
        #2646b1 35%,
        #2d368f 50%,
        #22002e 100%
    );
}

.drum-row {
    display: block;
    height: 20px;
    margin-left: 9px;
    margin-top: 12px;
    margin-bottom: 11px;
    border-right-color: white !important;
    color: white !important;
}

.hihat {
    margin-top: 28px;
}

.cymbals {
    margin-top: 24px;
}

.snare {
    margin-top: 25px;
}

.claps {
    margin-top: 24px;
}

.cowbell {
    margin-top: 27px;
    margin-bottom: 11px;
}

.tom {
    margin-top: 27px;
}

.layer-list-header {
    height: 100px;
    border-bottom: 1px solid rgba(50, 50, 50, 0.31);
}

.drum-note-row {
    background-color: rgba(10, 10, 10, 0.25);
    height: 284px;
}

div.list-item {
    width: 100%;
    position: absolute;
}

.track-list .layer-list-item-title:hover {
    cursor: auto;
    background-color: transparent !important;
}

.articulation-block {
    margin-top: -10px;
}

.articulation-subblock {
    display: inline-block;
    width: 100px;
    font-size: 80%;
    font-weight: 600;
    padding-left: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    vertical-align: top;
    margin-top: -1px;
}

.layer-block {
    z-index: 1;
    background-color: rgb(118, 5, 255);
    border: 1px solid rgba(255, 255, 255, 0.5);
    height: 33px;
    display: inline-block;
    border-radius: 4px;
    position: absolute;
    // margin-left: calc(var(--layerlist-width) + 2px);
    // margin-left: 2px;
    margin-left: var(--editor-padding);
}

.mini-pianoroll #pianoroll-note-container {
    margin-top: 0px !important;
}

.mini-pianoroll #pianoroll-grid {
    padding-top: 32px !important;
}

.track-list #piano {
    margin-left: 1px !important;
    z-index: 1 !important;
    padding-top: 0px !important;
}

.mini-pianoroll {
    height: auto !important;
    overflow-y: hidden;
}

.toggled-block {
    opacity: 0.3 !important;
}

.melody-block {
    background-color: var(--melody-track-color);
}

.melody-harmonization-block {
    background-color: var(--melody-harmonization-track-color);
}

.breath-gain-settings .volume-dial {
    width: 80px;
    height: 30px;

    .bi-directional-dial {
        margin-left: 3px !important;
    }

    .bi-directional-dial .title {
        display: none;
    }
}

.accompaniment-block {
    background-color: var(--chords-track-color);
}

.bass-block {
    background-color: var(--bass-track-color);
}

.percussion-block {
    background-color: var(--percussion-track-color);
}

.selected-block {
    border-color: rgba(255, 255, 255, 0.85) !important;
    border-width: 2px;
}

.selected-block.resizer {
    border-radius: 1px !important;
}

.pianoroll-piano-background #piano {
    display: block !important;
    position: absolute !important;
    padding-top: 0px !important;
    background-color: transparent !important;
}

.auto-stac-switch #checkbox3 {
    margin-right: 9px;
    margin-top: -1px;
    vertical-align: middle;
}

.auto-stac-switch img {
    width: 15px;
    margin-left: 0px !important;
    margin-top: 2px !important;
}

.auto-stac-switch img {
    width: 15px;
    margin-left: 3px !important;
}

.hidden-item {
    display: none;
    opacity: 0.3;

    &.spectator {
        margin-left: 20px;
    }
}

#tempo-scale {
    margin-top: -8px;
    padding-left: 0px;
    font-size: 70%;
    font-weight: 600;
    text-align: center;
    margin-left: calc(var(--layerlist-width) - 35px);
    position: absolute;
    line-height: 15px;
}

.upper-tempo {
    margin-top: 0px;
}

.lower-tempo {
    margin-top: 73px;
}

.composition-tempo {
    margin-top: 0px;
}

#tempo-scale div {
    margin-left: 8px;
    width: 25px !important;
    position: absolute;
    text-align: center;
}

.tooltip-title {
    font-size: 85%;
}

.cursor-container-wrapper-subtitle {
    font-style: italic;
    margin-top: 4px;
    font-size: 95%;
}

.auto-stac-switch .tooltip {
    margin-left: 10px !important;
    margin-right: 5px !important;
    font-size: 85%;
    margin-top: -1px !important;
}

.auto-stac-switch .tooltiptext {
    z-index: 9999999999999 !important;
    top: auto !important;
    margin-top: 30px !important;
    margin-left: 150px !important;
    left: 0px !important;
    font-size: 100% !important;
    white-space: normal !important;
    padding: 8px !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    width: 175px !important;
    font-weight: 400 !important;
}

#pianoroll-component-container .dropdown-menu-container .ps__rail-y {
    height: 100%;
    position: absolute !important;
    margin-top: 0px !important;
    width: 3px !important;
    background-color: transparent !important;
}

#pianoroll-component-container .dropdown-menu-container .ps__rail-y:hover {
    background-color: transparent !important;
}

#pianoroll-component-container .dropdown-menu-container .ps__rail-y:active {
    background-color: transparent !important;
}

#pianoroll-component-container
    .dropdown-menu-container
    .ps__rail-y
    .ps__thumb-y {
    width: 4px;
    padding-left: 2px;
    padding-right: 2px;
    background-color: white;
}

.auto-stac-switch {
    margin-top: 20px !important;
    margin-left: 28px !important;
}

.auto-stac-switch label {
    width: 25px !important;
    height: 15px;
}

.auto-stac-switch .checkbox-label:before {
    width: 18px;
    height: 18px;
}

.effects-rack-container {
    padding-bottom: 1px;
    position: relative;
}

.pianoroll-piano-background {
    background-color: white;
    width: 21px;
    margin-left: 39px;
    left: calc(var(--layerlist-width) - 39px);
    position: absolute;
    padding-bottom: 1px;
    z-index: 999;
}

.layer-list-item.mini-pianoroll {
    line-height: 0px !important;
}

.layer-list-settings {
    float: right;
    vertical-align: middle;
    width: 15px !important;
    margin-top: 3px !important;
}

.layer-list-settings:hover {
    cursor: pointer;
    opacity: 0.9;
}

.layer-list-item-title:hover {
    cursor: pointer;
    background-color: rgb(140, 140, 140);
}

.layer-list-item-title {
    font-size: 80%;
    font-weight: 500;
    position: relative;
}

.layer-effect-toggle-button {
    width: 16px !important;
}

#pianoroll-drum-container {
    margin-left: 41px;
}

.edit-pianoroll #pianoroll-drum-container {
    padding-bottom: 40px;
    margin-left: 22px;
    position: relative;
    overflow: hidden;
}

#pianoroll-drum-mapping {
    position: absolute;
    top: 907px;
    width: 40px;
    padding-top: 30px;
}

.add-drum-sample:hover {
    background-color: grey !important;
}

.add-drum-sample {
    width: 288px !important;
    line-height: 24px !important;
    font-size: 80% !important;
    background-color: rgb(164, 164, 164) !important;
}

.tracks-body #track-view #track-view-titles .track-title {
    margin-left: 6px;
}

.tracks-body #track-view #track-view-titles div {
    display: inline-block;
    text-transform: uppercase;
    font-size: 70%;
    font-weight: 700;
    line-height: 32px;
}

#selected-preset {
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 90%;
    opacity: 0.9;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.disabled-preset-parameter {
    opacity: 0.3;
}

.disabled-preset-parameter:hover {
    cursor: not-allowed;
}

#button-grey.disabled-preset-button {
    background-color: rgba(255, 255, 255, 0.17) !important;
    color: rgba(255, 255, 255, 0.4) !important;
}

#button-grey.disabled-preset-button img {
    opacity: 0.4;
}

.disabled-preset-button.modal-blue-button {
    background-color: rgba(0, 162, 255, 0.397) !important;
    color: rgba(255, 255, 255, 0.4) !important;
}

#button-grey.disabled-preset-button:hover {
    cursor: not-allowed !important;
    background-color: rgba(255, 255, 255, 0.17) !important;
    color: rgba(255, 255, 255, 0.4) !important;
}

.disabled-preset-button.modal-blue-button:hover {
    cursor: not-allowed !important;
    background-color: rgba(0, 162, 255, 0.397) !important;
    color: rgba(255, 255, 255, 0.4) !important;
}

.preset-parameter-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2px;
}

.modal-button-containers {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    .modal-button {
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-bottom: 0px !important;
        width: calc(50% - 10px) !important;

        margin-left: 5px !important;
        margin-right: 5px !important;

        margin-top: 20px !important;
    }
}

.upload-influence-key-signature {
    .preset-parameter-wrapper {
        max-width: 490px !important;
        width: 100%;
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .preset-parameter-wrapper .preset-parameter {
        width: calc(100% - 10px);
        margin-top: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 0px !important;
        max-width: 490px !important;
    }

    .upload-influence-key-signature-title {
        max-width: 490px;
        width: calc(100% - 10px);
        margin-left: auto;
        text-align: left;
        margin-right: auto;
        font-weight: 600;
        font-size: 120%;
        margin-bottom: 5px;
    }

    .upload-influence-key-signature-subtitle {
        max-width: 490px;
        width: calc(100% - 10px);
        margin-left: auto;
        text-align: left;
        margin-right: auto;
        line-height: 21px;
        font-weight: 300;
        font-size: 90%;
        text-align: justify;
        margin-bottom: 10px;
    }

    .preset-parameter-wrapper .preset-parameter {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        margin-top: 5px !important;
        font-size: 60% !important;
    }

    .preset-parameter-list {
        font-size: 150% !important;
        margin-top: 7px !important;
    }

    .preset-parameter-content {
        font-size: 150% !important;
    }
}

.customize-tooltip .tooltiptext {
    width: 160px !important;
    font-size: 100% !important;
    text-align: left;
    margin-left: -7px !important;
    margin-top: 15px;
    top: auto !important;
    line-height: 1.4;
    left: auto !important;
}

.preset-parameter-wrapper .parameter-info {
    display: block;
    font-size: 80%;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-right: 0px !important;
    margin-top: 0px;
    width: 130px;
}

.preset-parameter-wrapper .parameter-info .tooltiptext {
    margin-left: 4px !important;
    text-align: left;
    font-size: 100% !important;
}

#boolean-parameters {
    margin-top: 5px;
    margin-bottom: 5px;
}

.boolean-parameter {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 90%;
    margin-top: 15px;
}

.boolean-parameter-title:hover {
    cursor: pointer;
}

.boolean-parameter-staging {
    margin-top: 0px !important;
}

.preset-buttons-container {
    margin-top: 20px;

    .loader {
        font-size: 4px !important;
    }
}

.preset-parameter-wrapper .preset-parameter {
    -webkit-transition: background 0.08s; /* For Safari 3.0 to 6.0 */
    transition: background 0.08s; /* For modern browsers */
    min-width: 100px;
    font-size: 85%;
    display: inline-block;
    vertical-align: middle;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    background-color: #1a1848;
    padding: 5px;
    border-radius: 8px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
}

.preset-parameter:hover {
    cursor: pointer;
}

.preset-parameter .tooltiptext {
    z-index: 99999;
}

.selected-preset-subtitle {
    margin-top: 12px;
    font-size: 90%;
    opacity: 0.7;
    text-transform: capitalize;
}

.preset-parameter-wrapper:hover .preset-parameter {
    background-color: rgba(0, 162, 255, 0.925);
}

.preset-parameter-content {
    font-size: 190%;
    margin-bottom: 2px;
    text-transform: capitalize;
    padding-left: 15px;
    padding-right: 15px;
}

.preset-parameter-content-with-info {
    padding-right: 0px !important;
}

.preset-parameter-list-item {
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 80%;
    text-transform: capitalize;
}

#preset-settings-container .slider-difficulty {
    margin-top: 20px;
    margin-bottom: 5px !important;
}

.preset-parameter-list-item:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

#preset-settings-container #button-grey {
    margin-left: -110px;
}

#preset-settings-container .modal-blue-button {
    margin-left: -27px !important;
}

#preset-settings-container .modal-button {
    position: absolute;
    bottom: 15px;
}

.key-signatures-container-title {
    margin-bottom: 15px;
    margin-top: 10px;
    font-size: 90%;
    font-weight: 500;
}

.key-signatures-container {
    width: 250px;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}

.key-signatures-wrapper {
    width: 600px;
    height: 255px;
    margin-top: 20px;
    overflow: scroll;
    margin-left: -5px;
}

.key-signatures-container-right {
    border-left: 0.5px solid rgba(255, 255, 255, 0.2);
    float: right;
}

.preset-parameter-list {
    background-color: rgba(35, 35, 43, 1);
    position: absolute;
    width: 100%;
    font-size: 110%;
    margin-top: 25px;
    margin-left: 0px;
    max-height: 120px;
    overflow: scroll;
    border-radius: 6px;
    z-index: 99999;
}

.extended-preset-parameter-list {
    max-height: 160px !important;
}

#pianoroll-component-container .ps__rail-y {
    position: fixed !important;
    right: 0px !important;
    z-index: 9999999;
    width: 15px !important;
    height: calc(100% - 128px) !important;
    background-color: rgb(255, 255, 255) !important;
    margin-top: 163px !important;
    opacity: 1 !important;
}

#pianoroll-component-container .ps__rail-y:hover {
    background-color: white !important;
}

#pianoroll-component-container .ps__rail-y:active {
    background-color: white !important;
}

#pianoroll-component-container .ps__rail-y .ps__thumb-y {
    background-color: var(--secondary-color);
    width: 9px;
    margin-top: 2px;
    margin-right: 2px;
}

#pianoroll-component-container .ps__rail-x {
    background-color: rgba(255, 255, 255, 1) !important;
    position: fixed;
    top: 62px !important;
    left: calc(var(--sidebar-width) + var(--layerlist-width)) !important;
    z-index: 9999999;
    opacity: 1 !important;
}

#pianoroll-grid .ps__rail-x {
    width: calc(100% - var(--sidebar-width, 205px)) !important;
    // margin-left: var(--layerlist-width) !important;
}

#pianoroll-component-container .ps__rail-x:hover {
    height: 15px !important;
}

#pianoroll-component-container .ps__rail-x:active {
    height: 15px !important;
}

#pianoroll-component-container .ps__thumb-x {
    bottom: 3px !important;
    height: 9px !important;
    background-color: var(--secondary-color) !important;
    opacity: 1 !important;
}

.parameter-title {
    // width: 105px;
    // display: inline-block;
}

.preset-parameter-title {
    font-size: 80%;
    text-transform: uppercase;
    opacity: 0.7;
    padding-left: 20px;
    padding-right: 20px;
}

#selected-preset img {
    width: 20px;
    vertical-align: middle;
    margin-right: 7px;
    margin-left: 6px;
    margin-top: -2px;
}

#preset-description {
    font-size: 85%;
    height: 25px;
    margin-top: 20px;
}

#presets-dropdown {
    width: 290px;
    margin-left: auto;
    margin-right: auto;
}

.dropdown-toggle {
    margin-right: -10px;
    margin-left: 10px;
    width: 16px;
    vertical-align: middle;
    margin-top: -1px;
    display: inline-block;
}

.dropdown {
    background-color: rgba(0, 0, 80, 0.125);
    border-radius: 3px;
    font-size: 100%;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    box-shadow: none !important;
}

.dropdown-button {
    padding-top: 15px;
    padding-bottom: 15px;
}

.dropdown-button:hover {
    cursor: pointer;
}

#similarity-degree {
    margin-top: 20px;

    padding-left: 25px !important;
    padding-right: 25px !important;

    font-weight: 500;
    font-size: 90%;
    background-color: rgba(30, 30, 30, 0.5);
    padding: 10px;
    border-radius: 5px;

    display: inline-block;
}

.dropdown-item {
    font-size: 90%;
    padding-bottom: 10px;
    padding-top: 10px;
    color: rgba(255, 255, 255, 0.9);
}

.dropdown-item:hover {
    color: white;
    background-color: rgb(0, 217, 255);
    cursor: pointer;
}

.dropdown-content {
    max-height: 200px;
    overflow-y: scroll;
    position: relative;
}

#youtube-embed {
    width: 300px;
    position: absolute;
    bottom: 54px;
    right: 5px;
}

.right-click-menu-background {
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.4);
    content: "";
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 0px;

    background-color: rgba(34, 34, 34, 1);
    width: 100%;
    height: 100%;
    border-radius: 11px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.right-click-menu {
    position: absolute;
    min-width: 220px;
    z-index: 99999990;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.modal-skip-tutorial {
    text-decoration: underline;
}

.tutorial-modal .tutorial-skip {
    width: 8px;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px;
}

.tutorial-modal .tutorial-play {
    width: 10px;
    vertical-align: middle;
    margin-right: 7px;
    margin-top: -2px;
}

.rick-click-menu::before {
    content: "";
    margin: -35px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);
    z-index: -1;
}

.toggled-canvas {
    opacity: 0 !important;
}

.note-preview-canvas:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.025);
    opacity: 0.85;
}

.note-preview-canvas {
    margin-left: var(--editor-padding);
    position: absolute;
    z-index: 1;
}

.right-click-menu-item:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
}

.right-click-menu .delete-button:hover {
    background-color: rgba(255, 0, 0, 1) !important;
}

.right-click-menu .delete-button:active {
    background-color: rgba(200, 0, 0, 1) !important;
}

.right-click-menu-item:active {
    background-color: rgba(255, 255, 255, 0.4);
}

.layer-tempo-map-item {
    height: 82px !important;
    width: 100%;
    line-height: initial;
}

.layer-tempo-map-item .automation-submenu-setting-title {
    margin-top: 12px;
}

.dynamic-point {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: red;
    position: relative;
    display: inline-block;
}

.layer-list-settings-graph {
    margin-right: 40px !important;
    width: 18px !important;
    margin-top: -26px !important;
}

.layer-list-settings-pianoroll {
    margin-right: 40px !important;
    width: 14px !important;
    margin-top: -24px !important;
}

.sustain-pedal-settings-element input {
    margin-left: 21px !important;
}

.sustain-pedal-layer-list-item {
    margin-left: 8px;
}

#pianoroll-tempo-map {
    height: 90px;
    margin-left: 22px;
    width: 100%;
    background-color: rgba(10, 10, 10, 0.25);
}

.tempo-event {
    background-color: var(--secondary-color);
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.automation-submenu-button img {
    width: 14px;
    vertical-align: middle;
    margin-top: -1px;
    margin-right: 5px;
}

.automation-submenu-button-container {
    margin-left: 8px;
}

.aiva-slider-templates {
    margin-top: 5px;
}

.template-automation-settings .ngx-slider .ngx-slider-pointer {
    width: 16px !important;
    height: 16px !important;
    margin-left: 8px;
    top: -6px !important;
}

.template-automation-settings-title {
    margin-top: 10px;
    margin-bottom: 2px;
    margin-left: 5px;
}

.template-automation-settings .ngx-slider .ngx-slider-pointer:after {
    width: 4px !important;
    height: 4px !important;
    top: 6px !important;
    left: 6px !important;
}

.template-automation-settings
    .ngx-slider
    .ngx-slider-pointer.ngx-slider-active:after {
    background-color: white !important;
}

.template-automation-settings .ngx-slider {
    width: calc(100% - 15px) !important;
}

.template-automation-settings .ngx-slider .ngx-slider-bar-wrapper {
    margin-left: 8px !important;
}

.template-automation-settings .ngx-slider .ngx-slider-bubble.ngx-slider-limit {
    color: white !important;
    font-size: 85%;
}

.template-automation-settings .ngx-slider .ngx-slider-bubble {
    color: white !important;
    font-size: 85%;
    margin-left: 8px;
}

.template-automation-settings .ngx-slider .ngx-slider-tick-value {
    top: -26px !important;
    font-size: 85% !important;
}

.template-automation-settings .ngx-slider .ngx-slider-tick-legend {
    top: 14px !important;
    font-size: 85% !important;
}

.automation-submenu-setting-title {
    margin-bottom: 4px;
    font-size: 80%;
    font-weight: 600;
    opacity: 0.9;
    margin-left: 5px;
}

.automation-submenu-setting {
    margin-top: 8px;
    margin-bottom: 5px;
}

.automation-submenu-button {
    vertical-align: middle;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.35);
    width: 151px;
    border-radius: 3px;
    font-size: 75%;
    -webkit-text-decoration-line: none !important;
    text-decoration-line: none !important;
    margin-left: 5px;
    line-height: 25px;
    position: relative;
    margin-top: 10px;
    background-image: linear-gradient(gray, #6e6e6e);
    border-radius: 4px;
    overflow: hidden;
    font-weight: 600;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.automation-submenu-button:hover {
    cursor: pointer;
}

.automation-submenu-button:active {
    background-color: var(--secondary-color) !important;
}

// .layer-instrument-list-item .layer-list-settings {
// 	margin-top: 11px!important;
// 	vertical-align: middle;
// 	position: absolute;
// 	margin-left: 10px;
// 	right: 3px;
// }

.note-overlap {
    opacity: 0.2 !important;
    z-index: 999996;
}

.resizer {
    z-index: 999997;
    max-width: 25px;
    height: 100%;
    position: relative;
    display: inline-block;
    border-radius: 3px;
}

.resizer-left:active {
    border-left-color: rgba(255, 255, 255, 0.75);
}

.resizer-right:active {
    border-right-color: rgba(255, 255, 255, 0.75);
}

.resizer-left {
    float: left;
    margin-left: 0px;
    border-left: 3px solid rgba(255, 255, 255, 0.5);
}

.resizer-right {
    float: right;
    margin-right: 0px;
    border-right: 3px solid rgba(255, 255, 255, 0.5);
}

.dropdown-menu {
    margin-left: 5px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: #4e4e4e;
    width: 151px;
    position: relative;
    display: inline-block;
    text-align: left;
    font-size: 80%;
    font-weight: 600;
}

.dropdown-menu.custom-select option {
    background-color: #272727;
    color: white;
    -webkit-appearance: none;
}

.track-parameters-tooltip-wrapper {
    position: relative !important;
}

.track-parameters-tooltip-wrapper p {
    margin-bottom: 4px !important;
    margin-top: 0px !important;

    .parameters {
        margin-bottom: 8px !important;
    }
}

.tooltip .track-parameters-tooltip-wrapper .tooltiptext {
    position: absolute;
    left: auto !important;
    top: auto !important;
    font-size: 100% !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    line-height: 15px !important;
    vertical-align: middle;
    width: fit-content !important;
    margin-left: 10px !important;
    margin-top: -15px;
    min-width: 215px !important;
    // max-width: 250px;

    p {
        display: grid;
        grid-template-columns: 105px auto;

        .parameter.title,
        .parameter.ensemble {
            white-space: nowrap;
        }
    }
}

.track-parameters-interactable:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

.dropdown-menu-subcontainer {
    width: calc(100% - 10px);
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.instrument-submenu {
    margin-left: 171px;
    width: 250px !important;
}

.patch-submenu {
    margin-left: 422px;
    width: 390px !important;
}

.dropdown-menu-container-list-hover {
    cursor: pointer;
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
}

.layer-list-item-title-clicked {
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
    border: 1px solid rgba(0, 0, 0, 0.73);
}

.patch-submenu-container:hover {
    background-image: linear-gradient(
        rgb(201, 201, 201),
        rgb(201, 201, 201)
    ) !important;
    color: rgb(74, 74, 74) !important;
    cursor: pointer;
}

.submenu-img {
    margin-top: 6px !important;
    margin-right: 11.5px !important;
    float: right;
}

.save-loader {
    font-size: 3px !important;
    vertical-align: middle !important;
    margin-left: 17px !important;
    border-left-color: white !important;
}

.layer-loading {
    font-size: 3px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    border-left-color: white !important;
    width: 14px !important;
    height: 14px !important;
    top: 7px;
}

.bpm-loader {
    font-size: 3px !important;
    width: 20px !important;
    height: 20px !important;
    border-left-color: white !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
    margin-top: 15px !important;
}

.track-bus-loader {
    width: 15px !important;
    height: 15px !important;
    font-size: 2.5px !important;
    vertical-align: middle !important;
    margin-left: 17px !important;
    margin-right: 16px !important;
    border-left-color: white !important;
}

.simplebar-scrollbar:before {
    background-color: white !important;
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.75 !important;
}

.dropdown-menu-container {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    height: 150px;
    z-index: 999999;

    overflow-x: hidden;

    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    line-height: 22px;
    min-width: 170px;
    position: absolute !important;
    display: inline-block;
    text-align: left;
    left: 5px !important;
    margin-top: -145px;
    font-size: 75%;
    font-weight: 600;
    background-color: rgb(70, 70, 70);
}

.right-click-menu-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
    font-size: 80%;
}

.variations-button img {
    margin-top: 0px !important;
}

.right-click-menu-item img {
    width: 13px;
    vertical-align: middle;
    margin-right: 13px;
    margin-top: -3px;
}

#track-view .track-icon:hover {
    cursor: pointer;
}

#track-view .track-icon {
    vertical-align: top !important;
    width: 52px;
}

#track-view .track-icon.track-loading {
    margin-top: 10px !important;
}

.new-composition-badge-hidden {
    opacity: 0;
}

#track-view .track-icon-play img {
    width: 30px !important;
    margin-left: 8px !important;
    opacity: 0.8;
}

#track-view .track-icon-play img:hover {
    cursor: pointer;
    opacity: 1;
}

.track-title-container:hover {
    cursor: text;
}

.track-title-gp:hover {
    cursor: pointer !important;
    text-decoration: underline;
}

#track-view .track-duration {
    max-width: 60px;
}

#project-buttons-container {
    display: block;
    width: auto;
}

.project-small-button {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -3px;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
}

.project-small-button:hover {
    cursor: pointer;
    opacity: 0.8;
}

#project-header-container {
    vertical-align: top;
    margin-top: 25px;
    margin-left: 40px;
    max-width: 70%;
}

#project-component-header #project-artwork {
    width: 200px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    margin-top: 20px;
}

#project-component-header #project-title {
    margin-bottom: 24px;
    vertical-align: top;
    font-size: 270%;
    font-weight: 600;
    max-width: 900px;
    letter-spacing: -2px;
}

#project-component-header {
    padding-left: 25px;
    padding-right: 25px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    height: 240px;
    color: white;
    margin-top: 7px;
}
#project-component-header div {
    display: inline-block;
}

#project-component #navigation-chevrons {
    width: 7px;
    margin-left: 30px;
    margin-right: 30px;
}

#project-component-header-title {
    font-size: 140%;
    margin-left: 30px;
    margin-top: 14px;
}

.track-view-list-subfolder {
    max-height: calc(100% - 75px) !important;
}

#project-component-header-subtitle {
    font-size: 110%;
}

#track-view-list {
    overflow-y: scroll;
    max-height: calc(100% - 40px);
    /* max-height: 100%; */
    position: relative;
}

#track-view-list .track-title {
    font-weight: 500 !important;
}

.list-item-toggled {
    background-color: rgba(0, 162, 255, 0.8) !important;
}

.track-icon {
    display: inline-block !important;
    vertical-align: middle;
}

.list-item-additional .track-icon {
    margin-left: 42px;
}

.track-icon-loading {
    vertical-align: middle !important;
    display: inline-block;
    margin-top: -2px;

    margin-left: 10px;
    width: 26px !important;
    height: 26px !important;
    -webkit-animation: load8 1.8s infinite linear;
    animation: load8 1.8s infinite linear;
}

.tracks-body .list-item-composition div {
    display: inline-block;
    font-weight: 400;
    line-height: 41px;
    height: 33px !important;
    color: white;
    vertical-align: top;
}

.drag-block .folder-icon {
    width: auto !important;
    display: inline-block;
}

.drag-block .track-icon {
    width: auto !important;
    display: inline-block;
}

.upload-influence-dropzone img {
    width: 24px;
    margin-right: 15px;
    vertical-align: middle;
    margin-top: -4px;
}

.upload-influence-dropzone {
    padding-top: 43px;
    padding-bottom: 43px;
    border: none !important;
    width: 500px !important;
    font-size: 110%;
    font-weight: 600;
    border-radius: 11px !important;
    background-color: #1a1848;
}

.upload-influence-dropzone:hover {
    cursor: pointer;
    background-color: rgba(30, 27, 75, 1);
}

.upload-influence-dropzone:active {
    background-color: rgba(33, 30, 78, 1);
}

#upload-midi-container {
    .ngx-file-drop__drop-zone {
        border: none !important;
    }

    .ngx-file-drop__drop-zone {
        color: white !important;
        width: 500px !important;
        border-radius: 15px !important;
        margin-left: auto !important;
        margin-right: auto !important;
        height: auto !important;
        margin-bottom: 10px;
    }

    .ngx-file-drop__content {
        height: auto !important;
        color: white !important;
        width: 500px !important;
        border-radius: 10px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .drag-block .folder-icon img {
        margin-left: 5px !important;
        margin-right: 15px !important;
    }

    .drag-block .track-icon img {
        margin-left: 5px !important;
        margin-right: 15px !important;
    }
}

#track-view-list .track-icon img {
    width: 12px;
    margin-left: 17px;
    vertical-align: middle;
    margin-top: -2px;
}

.track-view-list-gp .track-icon img {
    width: 15px !important;
    margin-left: 22px !important;
    vertical-align: middle;
    margin-top: -2px;
}

.empty-list-item {
    height: 32px;
    width: 100%;
}

.drag-block {
    transform: scale(1);
    z-index: 9999;
    background-color: rgba(10, 10, 10, 0.6);
    backdrop-filter: blur(10px);
    font-weight: 500;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    box-shadow: black 0px 0px 4px;
    border-radius: 1px;
}

.list-item-back:hover {
    text-decoration: underline !important;
    background-color: rgba(255, 255, 255, 0.08) !important;
}

.list-item-back {
    font-weight: 800 !important;
    font-size: 80%;
    background-color: rgba(0, 0, 0, 0.1);
    line-height: 40px;
}

#track-view-list .list-item-composition {
    border: 2px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    width: calc(100% - 4px);
    margin-bottom: 2px;
    height: 41px;
    font-size: 80%;
}

.list-item-composition:hover {
    cursor: pointer;
}

.list-item-composition:active {
    cursor: pointer;
}

#track-view-list .list-item-variation {
    display: block;
}

.track-more-download img {
    margin-right: 0px !important;
    margin-top: 1px;
}

.download-modal .modal-preset-title {
    opacity: 1 !important;
}

.download-modal .modal-preset-title:hover {
    cursor: auto !important;
}

.download-modal .loader-container {
    margin-bottom: 30px;
    margin-top: 20px;
    font-size: 90% !important;

    text-align: center;
}

.loader.create-with-influence-loader {
    font-size: 4px !important;
    margin-top: 15px;
    margin-bottom: 5px;
    width: 20px !important;
    height: 20px !important;
}

.billing-preview-modal .loader-container {
    text-align: center !important;
}

.billing-preview-modal .loader-container .loader {
    margin-top: 15px;
    font-size: 3px;
    margin-bottom: 15px;
}

.subscription-status-info-modal .modal-background {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.subscription-status-info-modal .modal-container {
    width: 500px;
}

.subscription-status-info-modal .status-text {
    text-align: center;
    margin-bottom: 10px;
}

.subscription-status-info-modal .checkmark-icon {
    width: 50px;
    margin: 20px auto;
}
.subscription-status-info-modal .cross-icon {
    width: 50px;
    margin: 15px auto;
}

.do-action-before-modal .modal-container {
    width: 500px;
}

.publish-gp {
    .error-message {
        background-color: red;
        border-radius: 8px;
        margin-top: 10px;
        color: white;
        line-height: 26px;
        font-size: 80%;
        font-weight: 600;
        text-align: center;
        width: 100%;
    }

    .checkmark-green {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 0px !important;
        margin-bottom: 10px !important;
        width: 45px !important;
        height: 45px !important;
    }
}

/* checkmark animation - for additional info see: https://stackoverflow.com/questions/41078478/css-animated-checkmark*/
.checkmark-green .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: var(--primary-color);
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-green {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: var(--primary-color);
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px var(--primary-color);
    animation: ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark-green .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.subscription-status-info-modal .cross__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #f00;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.subscription-status-info-modal .modal-title {
    text-align: center;
    margin-bottom: 5px !important;
}

.changelog-versions-container {
    padding-bottom: 10px;
    -webkit-user-select: text !important; /* webkit (safari, chrome) browsers */
    -moz-user-select: text !important; /* mozilla browsers */
    -khtml-user-select: text !important; /* webkit (konqueror) browsers */
    -ms-user-select: text !important; /* IE10+ */
    user-select: text !important;
    height: 593px;
    padding-right: 20px;
}

.cross-red {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #f00;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}
.subscription-status-info-modal .cross__crossed {
    transform-origin: 50% 50%;
    stroke-dasharray: 29;
    stroke-dashoffset: 29;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

.download-modal .loader {
    margin-bottom: 15px;
    font-size: 3px !important;
    width: 26px !important;
    height: 26px !important;
}

.modal-preset-title {
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 5px;

    margin-left: 20px;
    margin-right: 20px;
    font-size: 92%;

    padding-bottom: 5px;

    border-bottom: 2px solid transparent;

    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 600;
}

.modal-preset-title:hover {
    cursor: pointer;
    color: white;
    opacity: 1 !important;
}

.modal-preset-title-selected {
    opacity: 1 !important;
    color: white;
    border-bottom-color: var(--secondary-color);
}

.modal-preset-title img {
    display: inline-block;
    width: 16px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -1px;
}

.disabled-preset {
    opacity: 0.4;
}

.disabled-preset:hover {
    background-color: rgba(100, 100, 100, 0.2) !important;
    cursor: default !important;
}

.download-type-disabled {
    border: 1px solid rgba(210, 210, 210, 0.05);
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(100, 100, 100, 0.1) !important;
}

.download-type-disabled img {
    opacity: 0.5;
}

.download-type-disabled:hover {
    cursor: auto !important;
    border: 1px solid rgba(210, 210, 210, 0.05);
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(100, 100, 100, 0.1) !important;
}

.download-type {
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 11px;
    width: 155px;
    line-height: 32px;
    background-color: rgba(171, 171, 171, 0.15);
    border: 1px solid rgba(210, 210, 210, 0.1);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 80%;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 6px;
}

.download-type-disabled .tooltiptext {
    line-height: 15px !important;
    top: 80px !important;
    left: auto !important;
    font-size: 100% !important;
    margin-left: 80px !important;
    text-align: left;
    width: 155px !important;
}

.download-type img {
    display: inline-block;
    width: 14px;
    vertical-align: middle;
    margin-right: 8px;
}

.download-type:hover {
    cursor: pointer;
    background-color: rgba(250, 250, 250, 0.15);
}

.download-type:active {
    cursor: pointer;
    background-color: rgba(250, 250, 250, 0.25);
}

.selected-preset {
    border-color: var(--secondary-color) !important;
    background-color: rgba(99, 122, 151, 0.2) !important;
}

.create-button.upload-influence-button {
    width: 150px !important;
}

.subtitle img {
    height: 16px;
    margin-right: 11px;
    vertical-align: middle;
    margin-top: -2px;
}

.edit-influence-modal .modal-subtitle {
    font-size: 90%;
    font-style: italic;
    margin-bottom: 8px;
    width: 770px;
    margin-left: auto;
    margin-right: auto;
}

.modal-button-wrapper {
    margin-top: 10px;
}

.create-with-influence-modal .modal-subtitle {
    margin-top: 30px;
    margin-bottom: 5px;
    text-align: center;
}

.edit-influence-modal .modal-title {
    text-align: center;
    margin-top: 10px;
}

.ensemble-category-container .ps__thumb-y {
    height: 45px !important;
}

.ensemble-category-container .ps__rail-x,
.ps__rail-y {
    opacity: 0.6;
}

.ensemble-category-container .ps__rail-y {
    margin-top: 174px !important;
    right: 7px !important;
}

.influence-name {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none;
    padding: 5px;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-size: 110%;
    margin-bottom: 25px;
}

.ensembles-container {
    text-align: center;
}

.key-signature-item {
    height: 25px;
    line-height: 25px;
    background-color: rgba(100, 100, 100, 0.2);
    border: 1px solid rgba(210, 210, 210, 0.1);
    font-size: 80%;
    font-weight: 500;
    text-align: center;
    width: 225px;
    margin-top: 7px;
    display: inline-block;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.key-signatures-container {
    margin-top: 10px;
}

.key-signature-item:hover {
    cursor: pointer;
    background-color: rgba(210, 210, 210, 0.1);
}

.ensemble-item:hover {
    cursor: pointer;
    background-color: rgba(210, 210, 210, 0.1);
}

.ensemble-item-right-margin {
    margin-right: 6px;
}

.selected-influence-ensemble {
    border-color: var(--secondary-color);
}

.ensemble-item {
    height: 25px;
    line-height: 25px;
    background-color: rgba(100, 100, 100, 0.2);
    border: 1px solid rgba(210, 210, 210, 0.1);
    font-size: 80%;
    font-weight: 500;
    text-align: center;
    width: 245px;
    margin-top: 7px;
    display: inline-block;
    border-radius: 8px;
}

.ensemble-list-container {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.ensemble-category-item {
    background-color: rgba(150, 150, 150, 0.3);
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
    line-height: 25px;
    border: 1px solid rgba(210, 210, 210, 0.1);
    font-size: 80%;
    font-weight: 500;
    text-align: left;
    padding-left: 15px;
    margin-top: 7px;
    border-radius: 8px;
}

.ensemble-category-item:hover {
    cursor: pointer;
    background-color: rgba(210, 210, 210, 0.2);
}

.add-layer-type {
    margin-top: 10px;
    -webkit-transition: background 0.07s; /* For Safari 3.0 to 6.0 */
    transition: background 0.07s; /* For modern browsers */
    background-color: rgba(202, 202, 202, 0.09);
    border: 1px solid rgba(210, 210, 210, 0.1);
    vertical-align: middle;
    text-align: center;
    border-radius: 11px;
    font-size: 95%;
    font-weight: 500;
    padding-top: 6px;
    padding-bottom: 6px;
}

.add-layer-type img {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;
    height: 22px;
    margin-right: 14px;
}

.layer-type-title {
    width: 110px;
    text-align: left;
    display: inline-block;
}

.preset {
    margin-left: 3px;
    margin-right: 3px;
    width: 95px;
    height: 75px;
    -webkit-transition: background 0.07s; /* For Safari 3.0 to 6.0 */
    transition: background 0.07s; /* For modern browsers */
    background-color: #1a1848;
    border: 1px solid rgba(210, 210, 210, 0.1);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    border-radius: 11px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 75%;
    font-weight: 500;
}

.preset:hover {
    background-color: rgba(30, 27, 75, 1);
    cursor: pointer;
}

.preset:active {
    cursor: pointer;
    background-color: rgba(33, 30, 78, 1);
}

.add-layer-type:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
}

#myinfluences-component #component-header {
    margin-top: 60px !important;
}

#myinfluences-component .path-element {
    margin-bottom: 0px !important;
}

.preset-subtitle {
    margin-top: 6px;
    font-size: 78%;
    font-style: italic;
}

.zEWidget-launcher {
    bottom: 55px !important;
}

.license {
    margin-top: 5px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-size: 110%;
}

#download-bottom-container .download-subtitle {
    display: inline-block;
    width: auto !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.download-bottom-subcontainer {
    margin-bottom: 15px;
}

#download-bottom-container .download-quota-remaining {
    margin-bottom: 0px !important;
    display: inline;
    margin-left: 5px;
}

.download-separator {
    height: 5px;
}

.download-subtitle {
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    font-size: 75%;
    margin-top: 10px;
    margin-bottom: 12px;
    width: 545px;
    margin-left: auto;
    margin-right: auto;
}

.download-all {
    height: auto;
    line-height: 32px;
    font-size: 80%;
    padding: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 6px !important;
    width: 542px;
}

.download-all .export-wav {
    margin-bottom: 20px !important;
}

.selected-note {
    z-index: 999998 !important;
}

.not-selected-note {
    opacity: 0.75;
    z-index: 0 !important;
}

.download-all img {
    width: 17px;
    margin-top: 0px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 0px;
    line-height: 30px;
}

.stems-group-type-title {
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    font-size: 90%;
    display: inline-block;
    margin-top: 3px;
}

.stems-group-type-subtitle {
    font-size: 85%;
    font-style: italic;
    display: block;
    font-weight: 400;
    margin-top: 4px;
}

.stems-group-type:hover {
    cursor: pointer;
    background-color: rgba(250, 250, 250, 0.15);
}

.stems-group-type:active {
    cursor: pointer;
    background-color: rgba(250, 250, 250, 0.25);
}

.stems-group-type img {
    margin-top: 2px;
    width: 20px;
    display: inline-block;
    margin-right: 20px;
    margin-left: 10px;
    vertical-align: middle;
}

.stems-group-type {
    margin-left: auto;
    margin-right: auto;
    padding-top: 5px;
    padding-bottom: 9px;
    border-radius: 11px;
    background-color: rgba(171, 171, 171, 0.15);
    border: 1px solid rgba(210, 210, 210, 0.1);
    vertical-align: middle;
    text-align: left;
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 7px;
    margin-bottom: 7px;
}

.download-type-title {
    display: inline-block;
    vertical-align: middle;
}

.download-all .download-type-title {
    width: 290px;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
}

.preset img {
    margin-bottom: 13px;
    height: 30px;
}

.track-more {
    position: relative;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
    right: 10px;
}

.track-more:hover {
    cursor: pointer;
    opacity: 0.8;
}

.track-more img {
    width: 20px;
    vertical-align: middle;
}

#track-view-list .list-item-composition .loader {
    font-size: 3px;
    width: 15px !important;
    height: 15px !important;
    margin-left: 18px;
    vertical-align: middle;
}

.overflow-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

#influence-disabled-feature {
    text-align: center;
    top: 40%;
    font-size: 110%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 380px;
    position: absolute;
    left: calc(50% + 105px);
    margin-left: -190px;
    border-radius: 5px;
    font-weight: 500;
}

.billing-preview-modal .modal-background {
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

#track-view-list .list-item-drag-hovered {
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
}

#track-view-list .list-item-multi-selection {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-left-color: var(--secondary-color);
}

#track-view-list .list-item-right-clicked {
    background-color: rgba(255, 255, 255, 0.08);
}

#component-right {
    float: right;
    margin-right: 65px;
}

.selected-influence-category {
    margin-top: 10px;
    border-color: transparent !important;
    background-color: transparent !important;
    border-bottom: 1px solid #d2d2d2;
}

.selected-influence-ensemble {
    background-color: var(--secondary-color) !important;
}

#component-right img {
    display: inline-block;
    height: 18px;
    padding: 10px;
}

#pianoroll-loader .loader {
    margin-bottom: 20px;
    font-size: 3.5px;
    margin-left: -2px;
    margin-right: auto;
}

.bass-boost-pianoroll {
    display: inline-block;
}

.update-banner img {
    width: 22px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 8px;
}

.update-banner {
    text-align: center;
    padding: 7px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 184px;
    position: fixed;
    background-color: orange;
    bottom: 99px;
    border-radius: 4px;
    line-height: 16px;
    font-size: 78%;
    margin-left: 4px;
    z-index: 999;
    font-weight: 600;
    color: white;
}

.bass-boost-pianoroll img {
    width: 48px !important;
    margin-top: -5px !important;
}

#bass-boost {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
}

#bass-boost img {
    width: 80px;
    margin-top: 10px;
    margin-bottom: 5px;
}

#bass-boost:hover {
    cursor: pointer;
    opacity: 0.95;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

#pianoroll-component-container .ps__thumb-y {
    background-color: var(--secondary-color) !important;
}

.edit-pianoroll .ps__thumb-y {
    background-color: var(--secondary-color) !important;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

#pianoroll-loader {
    width: 500px;
    background-color: rgba(10, 10, 20, 0.6);
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    margin-top: -160px;
    position: absolute;
    margin-left: -150px;
    text-align: center;
    font-size: 110%;
    font-weight: 600;
}

#note-description {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999999;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 90%;
    font-weight: 600;
    line-height: 18px;
}

#note-description-title {
    font-weight: 400;
}

#component-right img:hover {
    cursor: pointer;
    background-color: rgb(0, 162, 255);
    border-radius: 50%;
}

.round-button-next {
    width: 150px !important;
    margin-top: 30px;
}

.round-button-green {
    background-color: #05eb8b !important;
}

.round-button-green:hover {
    border-color: rgb(59, 255, 173) !important;
}

.round-button-green:active {
    background-color: rgb(59, 255, 173) !important;
}

.round-button {
    background-color: var(--secondary-color);

    font-size: 90%;
    font-weight: 700 !important;
    color: white;

    text-align: center;
    width: 195px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 45px;
    margin-left: auto;
    margin-right: auto;

    border: 3px solid transparent;

    -moz-transition: all ease 0.3s;
    -o-transition: all ease 0.3s;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.round-button:hover {
    cursor: pointer;
    color: white !important;
    border-color: #00fcff;
}

.round-button:active {
    background-color: #00fcff;
    border-color: transparent;
}

.close-icon {
    width: 14px;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 15px;
}

.close-warning {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 500;
}

.close-icon:hover {
    cursor: pointer;
    opacity: 0.8;
}

#search-more {
    background-color: rgb(200, 200, 200);
    width: 125px;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    margin-bottom: 15px;
    margin-top: 10px;
}

#search-more:hover {
    border-color: transparent;
    background-color: rgb(180, 180, 180);
}

#search-results .loader {
    margin-bottom: 15px;
    margin-top: 10px;
    width: 15px !important;
    height: 15px !important;
    font-size: 4px !important;
    position: relative;
    border-left-color: var(--secondary-color);
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.normal-pointer {
    cursor: pointer;
}

.drag-pointer {
    cursor: move;
}

.resizer-pointer {
    cursor: col-resize;
}

#search-results #no-result {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.search-bar-empty {
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

#search-bar .close-icon {
    float: right;
    position: relative;
    margin-top: -67px;
    border-left-color: var(--secondary-color);
    display: block;

    left: 33px;
}

.billing-plan-container .white-text-button {
    margin-top: 5px;
    margin-bottom: 7px;
    display: block;
}

.white-text-button {
    color: white !important;
    font-size: 90% !important;
    font-weight: 500 !important;
}

.green-text-button {
    color: #07ff05 !important;
}

.text-button {
    font-weight: 500;
    color: var(--secondary-color);
}

.text-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

#error-message-band {
    background-color: red;
    padding: 8px;
    text-align: center;
    position: fixed;
    z-index: 999;

    color: white;
    font-weight: 500;
    font-size: 90%;
    width: 100%;
}

.spinner {
    margin: 30px auto auto;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 10px;
    height: 10px;

    background-color: #00e4ff;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.ng-modal-dialog .spinner > div {
    background-color: rgb(74, 220, 47) !important;
}
.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.error-message-banner {
    background-color: red;
    border-radius: 8px;
    margin-top: 10px;
    color: white;
    line-height: 26px;
    font-size: 80%;
    font-weight: 600;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
}

.small-loader {
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.05);
    border-right: 1.1em solid rgba(255, 255, 255, 0.05);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.05);
    border-left: 1.1em solid rgb(0, 196, 255);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.8s infinite linear;
    animation: load8 1.8s infinite linear;

    width: 18px;
    height: 18px;
}
.small-loader,
.small-loader:after {
    border-radius: 50%;
}

.loader {
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.05);
    border-right: 1.1em solid rgba(255, 255, 255, 0.05);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.05);
    border-left: 1.1em solid rgb(0, 196, 255);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.8s infinite linear;
    animation: load8 1.8s infinite linear;
}
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader.ng-hide-add-active {
    display: none !important;
}

.layer-label-dynamics {
    height: 143px;
}

.dynamic-editing {
    margin-left: 21px;
    height: 150px;
}

.add-instrument {
    vertical-align: middle;
    color: white;
    background-color: var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.35);
    width: calc(100% - 43px);
    border-radius: 3px;
    font-size: 90%;
    text-align: center;
    text-decoration-line: none !important;
    margin-left: auto;
    margin-right: auto;
    margin-left: 5px;
    text-align: center;
    line-height: 28px;
    position: relative;
    margin-top: 7px;
    margin-bottom: 6px;
}

.add-instrument:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

.add-instrument img {
    margin-right: 4px;
    width: 12px;
    vertical-align: middle;
    margin-top: -3px;
}

#terms-and-conditions {
    font-size: 85%;
    color: 0.9;
    display: inline-block;
    width: 74%;
    text-align: center;
    margin: 0px auto 20px;
}

#account-creation-window #button-container {
    margin-top: 25px !important;
}

#terms-and-conditions input {
    display: inline-block !important;
    width: auto !important;
    margin-right: 4px !important;
}

#success-message {
    background-color: rgb(0, 221, 0);
    padding: 10px;
    text-align: center;

    color: white;
    font-weight: 500;
    font-size: 100%;
}

.recaptcha {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 305px;
    margin-top: 20px;
}

/* #reset-password-window {
    height: 400px;
    margin-top: -200px;
} */

#reset-password-window .text-button {
    margin-top: 20px;
}
#reset-password-window #terms-and-conditions {
    margin: 10px;
}

#version-number {
    font-size: 80%;
    color: white;
    margin-top: 20px;
    background-color: var(--secondary-color);
    padding: 6px;
    width: 140px;
    text-align: center;
}

#login-component,
#createaccount-component {
    width: 100%;
    height: 100%;
    background: -webkit-linear-gradient(
        bottom right,
        rgba(0, 211, 208, 0.9) 0%,
        rgba(0, 162, 255, 0.9) 50%,
        rgba(43, 0, 173, 0.9) 100%
    );
    background: linear-gradient(
        to top left,
        rgba(0, 255, 251, 0.9) 0%,
        rgba(0, 162, 255, 0.9) 50%,
        rgba(64, 0, 255, 0.9) 100%
    );
}
/* 
#login-component #reset-password-window .round-button {
    margin-top: 20px;
} */

#reset-password-window #title {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 140%;
}

#login-component .loader,
#createaccount-component .loader {
    width: 19px !important;
    height: 19px !important;
    font-size: 4px !important;
}

#login-component #header-logo {
    width: 520px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 80px;
}

#account-creation-window {
    height: 330px;
    margin-top: -180px;
}

#account-creation-window #title {
    margin-top: 45px;
}

#account-creation-window .text-button {
    margin-top: 20px;
}

#login-window-wrapper {
    top: 50%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 440px;
    left: 50%;
    margin-left: -220px;
    margin-top: -310px;
}

#login-component .window,
#createaccount-component .window {
    background-color: white;
    border-radius: 20px;
    overflow-y: auto;

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

    text-align: center;
}

.aiva-logo-text {
    font-style: "Play";
    font-size: 200%;
    color: white;
    font-weight: 600;
    letter-spacing: 2px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
}

.aiva-logo {
    text-align: center;
    z-index: 99999;
    margin-bottom: 25px;
}

.aiva-logo img {
    height: 70px;
    margin: auto;
    vertical-align: middle;
}
#login-window .message-box,
#createaccount-window .message-box,
#reset-password-window .message-box {
    width: 74%;
    margin: 10px auto;
}
.message-box {
    display: block;
    font-size: 80%;
    border-radius: 5px;
    background-color: white;
    border: 1px solid black;
}
.message-box .text {
    padding: 5px;
    color: white;
    font-weight: 700;
}

.message-box > .close-button img {
    width: 13px;
    height: 13px;
}

.message-box > .close-button {
    padding: 4px;
    float: right;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.message-box.info {
    background-color: #c8ebff;
    border: 1px solid var(--secondary-color);
}
.message-box.success {
    background-color: rgb(0, 210, 0);
    border: 1px solid rgb(0, 158, 0);
}
.message-box.warn {
    background-color: #fff1bf;
    border: 1px solid orange;
}
.message-box.error {
    background-color: red;
    border: 1px solid red;
}

#login-window .service-login-container .button-container,
#createaccount-window .service-login-container .button-container {
    display: flex;
    justify-content: center;
}
#login-window .round-button,
#createaccount-window .round-button,
#reset-password-window .round-button {
    border-radius: 5px;
}
.or-seperator {
    display: flex;
    align-items: center;
    max-width: 95%;
    width: 324px;
    color: #888888;
    font-weight: 600;
    font-size: 90%;
    margin: 20px auto;
}
.or-seperator hr {
    flex-grow: 1;
    margin: 0;
    border: none; /* removes ugly default style of hr */
    border-top: 1px solid #c5c5c5;
}
.or-seperator > span {
    padding: 0em 1.2em;
}
#login-window .create-account-container {
    padding: 20px 0px;
}
#login-window .login-final-step .text-button,
#createaccount-window .createaccount-final-step .text-button,
#reset-password-window .text-button {
    margin-bottom: 30px;
}
#login-window .additional-options {
    width: 74%;
    margin: 50px auto 0px;
    display: flex;
    justify-content: space-between;
}
#createaccount-window .additional-options {
    width: 74%;
    margin: 30px auto 0px;
}
#createaccount-window .additional-options .go-to-login {
    text-align: center;
    font-size: 90%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 30px;
    cursor: pointer;
}

#forgot-password {
    text-align: center;
    font-size: 90%;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 30px;
}

#forgot-password:hover {
    cursor: pointer;
}

#login-component #title {
    margin-top: 25px !important;
    margin-bottom: 20px !important;
}

.drag-and-drop-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: red;
    z-index: 999999999999999999;
}

#title {
    font-size: 170%;
    margin-top: 30px;
    margin-bottom: 25px;
}

#login-component input,
#createaccount-component input {
    width: 310px;
    max-width: calc(95% - 14px);
    display: block;
    font-size: 100%;
    padding: 7px;
    margin: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid #b9b9b9d2;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: none;
}

#login-component input:focus,
#createaccount-component input:focus {
    box-shadow: 0 0 5px var(--secondary-color);
    border: 1px solid var(--secondary-color);
}

#login-component input.email,
#createaccount-component input.email {
    margin: initial;
    margin-bottom: 15px;
}

#login-component #button-container,
#createaccount-component #button-container {
    margin-bottom: 20px;
}
#login-component #button-container .loader,
#createaccount-component #button-container .loader {
    margin: 10px auto;
}

#login-component .round-button,
#createaccount-component .round-button {
    max-width: 95%;
    /* width: 130px!important; */
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 100%;
    width: 324px;
    border: 0px solid transparent;
}

#login-component .round-button:hover,
#createaccount-component .round-button:hover {
    box-shadow: 0 2px 6px rgba(117, 117, 117, 0.4);
    border-color: transparent !important;
}

#reset-password .text-button {
    margin-bottom: 30px;
}

#or {
    margin-top: 20px;
    margin-bottom: 20px;
}

#navbar li {
    display: inline;
    list-style: none !important;
}

#navbar ul {
    display: inline;
}

#navbar a {
    text-decoration: none;
    color: white;
}

#navbar {
    width: 100%;
    color: white;
    background-color: rgba(10, 10, 20, 0.85);
    line-height: 47px;
    top: 0px;
    position: fixed;
    z-index: 9999;
}

.sidebar-title-community #sidebar-title-icon {
    width: 20px !important;
    height: 20px !important;
    margin-left: -1px;
    margin-right: 14px !important;
}

.sidebar-title-billing #sidebar-title-icon {
    width: 17px !important;
    height: 17px !important;
    margin-left: 0px;
    margin-right: 16px !important;
}

#sidebar-title-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -2px;
}

.tracks-icon {
    background-image: url("assets/img/navbar/projects.svg");
}

.tracks-icon,
.editor-icon {
    width: 16px !important;
    height: 16px !important;
}

.influences-icon {
    width: 19px !important;
    height: 19px !important;
    margin-right: 11px !important;
    background-image: url("assets/img/upload.svg");
}

.folder-icon {
    width: 60px;
}

.folder-icon img {
    width: 19px !important;
    margin-left: 21px !important;
    vertical-align: middle;
    margin-top: -2px;
}

#logo {
    font-size: 180%;
    font-family: "Play" !important;
    margin-top: 2px;
    color: white;
    letter-spacing: 1px;
    margin-left: 5px;
    text-decoration: none;
}

#logo img {
    width: 44px;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -4px;
}

#menus-left {
    vertical-align: top;
    display: inline-block;
    margin-top: 2px;
}

#menus-right {
    float: right;
}

.menu {
    font-size: 90%;
    margin-right: 25px;
    display: inline-block;

    border-bottom: 3px solid transparent;
    opacity: 0.9;
}

.password-modal .modal-container {
    width: 400px;
    text-align: center;
}

.password-modal .current-password {
    margin-top: 20px !important;
}

.password-modal input {
    margin-bottom: 8px;
}

#user-menu-container {
    bottom: 55px;
    position: fixed;
    width: var(--sidebar-width, 205px);
    margin-left: -30px;
}

.user-menu-container-open {
    max-height: 346px;
}

#user-menu-content {
    width: 100%;
}

#user-menu-button {
    padding-top: 8px;
    padding-bottom: 8px;
    top: 0px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 5px;
    opacity: 0.95;
    color: white;
    background-color: rgba(255, 255, 255, 0.19);
    border-radius: 5px;
}

.list-of-invited-emails {
    text-align: left;
    margin-top: 10px;
    max-height: 130px !important;
    overflow-y: scroll;
}

.modal-container.settings-modal-container {
    width: 600px;

    .network-connection-option {
        margin: 14px 0px;
    }

    .button img {
        width: 14px;
        margin-left: 5px;
        position: relative;
        top: 1px;
    }
}

.settings-modal-title.settings-modal-title-selected {
    border-bottom-color: var(--secondary-color) !important;
}

.settings-modal-title.settings-modal-title-selected:hover {
    border-bottom-color: var(--secondary-color) !important;
}

.settings-modal-titles {
    text-align: left;
    margin-bottom: 20px;
}

.settings-modal-title {
    border-bottom: 2px solid transparent;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 20px;
    font-size: 90%;
    font-weight: 600;
    display: inline-block;
}

.settings-modal-title:hover {
    border-bottom-color: var(--secondary-color) 23 !important;
}

.settings-modal-title:hover {
    cursor: pointer;
}

.download-modal .modal-container {
    width: 570px;
    margin-left: -305px;
    text-align: center;
    margin-top: -260px;
}

.create-modal .loader {
    font-size: 3px;
    vertical-align: middle;
    margin-top: 14px;
}

.create-modal.emotion-view .loader {
    font-size: 3px;
    vertical-align: middle;
    margin-top: 36px;
}

.create-modal .loader-subtitle {
    margin-top: 10px;
    font-size: 95%;
}

.preview-line-item {
    font-size: 90%;
    margin-top: 3px;
    margin-bottom: 3px;
}

.preview-item-total {
    margin-top: 14px;
    padding-top: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    font-weight: 600;
    font-size: 110%;
}

.preview-item-total-title {
    display: inline-block;
}

#subscription-plan {
    text-transform: capitalize;
}

.balance-description {
    font-size: 75%;
    margin-top: 15px;
}

.preview-line-item div {
    display: inline-block;
}

.preview-item-amount {
    float: right;
}

.preview-container {
    text-align: left;
}

.modal-title-billing-preview {
    text-transform: uppercase;
    margin-top: 4px;
    margin-bottom: 15px !important;
}

.billing-preview-modal .modal-title {
    text-align: left !important;
    opacity: 1 !important;
    font-size: 120% !important;
    margin-bottom: 25px !important;
}

.billing-preview-modal .modal-title:hover {
    cursor: auto;
}

.plan-name {
    text-transform: capitalize;
}

.billing-preview-modal .preview-container .plan-button {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 4px !important;
    margin-top: 10px !important;
}

/* .downgrade-subscription-survey-modal .plan-button-downgrade {
	width: 100%!important;
	margin-left: auto!important;
	margin-right: auto!important;
	padding-left: 0px!important;
	padding-right: 0px!important;
	border-radius: 4px!important;
	margin-top: 10px!important;
} */

.downgrade-subscription-survey-modal .emoji-image {
    font-size: 50px;
    margin-bottom: 10px;
}

.downgrade-subscription-survey-modal .modal-container {
    width: 400px;
    text-align: center;
    margin-top: -275px;
}

.downgrade-subscription-survey-modal .title {
    text-align: left;
    text-align: center;
    margin-bottom: 5px;
    font-size: 20px;
}

.downgrade-subscription-survey-modal .sub-title {
    font-size: 14px;
    text-align: center;
    opacity: 0.8;
}

.downgrade-subscription-survey-modal .option-box {
    margin-top: 20px;
    text-align: left;
}

.downgrade-subscription-survey-modal .option-box input[type="radio"] {
    -ms-transform: scale(0.9); /* IE 9 */
    -webkit-transform: scale(0.9); /* Chrome, Safari, Opera */
    transform: scale(0.9);
}

.downgrade-subscription-survey-modal .option-box input[type="radio"] + label {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: text-top;
}

.downgrade-subscription-survey-modal .required-fields-hint {
    font-size: 12px;
    text-align: left;
    opacity: 0.6;
    padding: 4px 8px;
}

.downgrade-subscription-survey-modal textarea#explanation {
    width: 94%;
    padding: 10px;
    margin-top: 20px;
    resize: none;
    border-radius: 4px;
    font-size: 14px;
}

.downgrade-subscription-survey-modal .button-box {
    margin-top: 20px;
}

.downgrade-subscription-survey-modal button.downgrade {
    width: 100%;
    cursor: pointer;
    background-color: var(--primary-color) !important;
    color: white;
    padding: 5px;
    text-align: center;
    border-radius: 4px !important;
    display: inline-block;
    font-size: 80%;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
}

.downgrade-subscription-survey-modal button.downgrade:disabled,
.downgrade-subscription-survey-modal button.downgrade[disabled] {
    background-color: #cccccc !important;
}

.downgrade-subscription-survey-modal .dont-cancel {
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.payment-disclaimer {
    margin-top: 12px;
    font-size: 80%;
    font-style: italic;
    text-align: center;
    color: white;
}

.payment-disclaimer a {
    color: white !important;
}

.billing-preview-modal .modal-container {
    width: 670px;
    text-align: center;
}

.tutorial-modal .modal-container {
    width: 500px;
    margin-left: -260px;
    text-align: center;
    margin-top: -190px;
}

.tutorial-modal .modal-title {
    margin-top: 20px !important;
    text-align: center !important;
}

.instrument-palette-container-empty-space {
    height: 30px;
    display: block;
    width: 100%;
}

.double-palette-container {
    min-width: 225px !important;
}

.instrument-palette-container {
    .instruments-wrapper {
        padding-left: 10px;
        padding-right: 15px;

        .loader {
            position: absolute;
            right: 0px;
            font-size: 50%;
            width: 1em !important;
            height: 1em !important;
            border-left-width: 0.3em;
            border-right-width: 0.3em;
            border-top-width: 0.3em;
            border-bottom-width: 0.3em;
        }
    }

    border-right: 1px solid rgba(255, 255, 255, 0.1);
    text-align: left;
    min-width: 250px;
    height: 439px;
    vertical-align: top;
    display: inline-block;

    .simplebar-track {
        background-color: rgba(255, 255, 255, 0.05);
    }
}

.tutorial-modal .modal-subtitle {
    font-size: 90%;
    margin-top: 15px !important;
    margin-bottom: 8px !important;
    text-align: center !important;
}

.instrument-search-left-empty-space {
    width: 35px;
    display: inline-block;
}

.instrument-selection-search-result-hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.instrument-selection-search-result {
    padding-top: 6px;
    padding-bottom: 6px;
    display: inline-block;
    width: calc(100% - 35px);
}

.instrument-selection-modal-middle-container {
    position: relative;
    white-space: nowrap;
    height: 439px;
    text-align: left;
    font-size: 90%;
}

.instrument-palette-list-item {
    line-height: 15px;
    border-radius: 5px;
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    white-space: pre-line;
    vertical-align: middle;

    &.not-recommended {
        .instrument-palette-list-item-title {
            opacity: 0.35;
        }
    }
}

.instrument-palette-list-item:hover {
    background-color: rgba(255, 255, 255, 0.05);
    cursor: pointer;
}

.selected-instrument-palette-list-item {
    background-color: rgb(168, 168, 168) !important;
    font-weight: 600;
}

.selected-instrument-palette-list-item-blue {
    background-color: var(--secondary-color) !important;
}

.instrument-palette-list-item-title {
    width: calc(100% - 15px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.download-all-instruments-modal .modal-container {
    width: 500px;
}

.resume-download-all-instruments-modal .modal-container {
    width: 500px;
}

.pitch-range-control-modal .modal-container {
    width: 700px;
}

.instrument-palette-list-item img {
    width: 6px;
    float: right;
    vertical-align: middle;
    margin-top: 5px;
    margin-left: 5px;

    &.preview-patches,
    &.download-patches {
        width: 10px;
        margin-top: 1px;
    }
}

.show-preview-instrument-palette-list-item {
    width: calc(100% - 50px);
    display: inline-block;
}

.instrument-palette-list-item {
    img.preview-patches,
    img.download-patches {
        opacity: 0;
    }

    img.preview-patches-content {
        margin-right: 5px;
    }

    &:hover img.preview-patches,
    &:hover img.download-patches {
        opacity: 100;
    }
}

.auto-mix-modal .loader {
    width: 21px !important;
    height: 21px !important;
    font-size: 3px;
    margin-top: 10px;
}

.instrument-selection-modal .modal-container {
    width: 1356px;
    height: 500px;
    text-align: center;
    margin-top: -290px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.template-automation-container {
    text-align: left;
}

.template-automation-title {
    overflow: hidden;
    font-size: 65%;
    font-weight: 700;
    margin-left: 7px;
    margin-top: 5px;
    text-transform: uppercase;
}

.template-automation canvas {
    margin-left: 7px;
    margin-top: 8px;
}

.template-automation-settings {
    text-align: left;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    font-size: 90%;
    margin-bottom: 5px;
}

.automation-range-slider-container {
    height: 79px;
}

.template-overlay {
    height: 150px;
    position: absolute !important;
    vertical-align: top;
    z-index: 999;

    background-color: rgba(0, 0, 0, 0.15);

    margin-top: -3px;

    margin-left: var(--editor-padding);
    backdrop-filter: blur(45px);
    border: 2px dotted #b3b3b3a1;
}

#template-overlay-canvas {
    position: absolute;
    top: 0px;
    margin-left: 0px !important;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.template-overlay-icon {
    width: 25px !important;
}

.template-overlay-title {
    text-align: center;
    line-height: 150px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 600;
}

.template-hover-overlay {
    width: 100%;
    height: 150px;
    position: absolute;
    z-index: 1000;
}

.template-automation:active {
    background-color: rgba(74, 74, 74, 1) !important;
}

.dragged-template {
    width: calc($template-menu-width / 2) - 15px !important;
    position: fixed !important;
    z-index: 9999999;
    background-color: rgba(225, 225, 225, 0.35) !important;
    backdrop-filter: blur(10px);
    color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
}

.template-automation {
    margin-top: 10px;
    display: inline-block;
    height: 75px;
    text-align: left;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    width: calc(50% - 15px);
    position: relative;

    -moz-transition: transform ease 0.1s;
    -o-transition: transform ease 0.1s;
    -webkit-transition: transform ease 0.1s;
    transition: transform ease 0.1s;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
    }
}

.template-modal .modal-container {
    text-align: center;
    top: 67px !important;
    margin-top: 0px !important;
    margin-left: 0 !important;
    left: auto !important;
    width: $template-menu-width;
    padding: 0px !important;
    z-index: 9999999; // to have it above the drum sequencer
    box-shadow: 0px 0px 12px rgb(0 0 0 / 20%) !important;

    -moz-transition: all ease 0.15s;
    -o-transition: all ease 0.15s;
    -webkit-transition: all ease 0.15s;
    transition: all ease 0.15s;

    right: -$template-menu-width - 15px;
}

.template-modal-show .modal-container {
    right: 5px !important;
}

.auto-mix-modal .modal-container {
    width: 500px;
    margin-left: -278px;
    text-align: center;
    margin-top: -210px;
}

.subscription-modal .modal-container {
    width: 800px;
    text-align: center;
}

.subscription-modal .modal-title {
    margin-top: 20px !important;
    text-align: center !important;
}

.subscription-modal .modal-subtitle {
    font-size: 90%;
    margin-top: 15px !important;
    margin-bottom: 8px !important;
    text-align: center !important;
}

.tooltip-parameter .tooltiptext {
    font-size: 100% !important;
    margin-left: 0px !important;
    left: 0px !important;
    top: 68px !important;
    width: 160px !important;
}

.download-midi-mp3 {
    width: 100px !important;
}

.mp3-and-midi {
    vertical-align: middle;
    margin-top: -35px;
    margin-left: 7px;
    margin-right: 7px;
    display: inline-block;
}

.preset-parameter-disabled:hover {
    cursor: not-allowed;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 145px;
    background-color: rgba(10, 10, 20, 1) !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 11px;
    padding: 8px 15px;
    font-size: 75%;
    margin-left: 8px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 105%;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.ps > .ps__scrollbar-x-rail,
.ps > .ps__scrollbar-y-rail {
    opacity: 0.6 !important;
}

#pianoroll-edited-badge {
    position: absolute;
    background-color: red;
    right: 80px;
    margin-top: -20px;

    width: 10px;
    height: 10px;

    border-radius: 50%;
}

.list-item-composition #new-composition-badge {
    width: 7px !important;
    height: 7px !important;
    right: auto !important;
    left: 34px !important;
    top: 50px;
    position: relative !important;
    z-index: 999;
}

#new-composition-badge {
    position: absolute;
    background-color: red;
    right: 90px;
    margin-top: -20px;

    width: 10px;
    height: 10px;

    border-radius: 50%;
}

.incompatible-composition-modal .modal-container {
    width: 550px;
    margin-left: -275px;
    padding: 30px;
    padding-top: 30px !important;
    text-align: center !important;
    padding-bottom: 30px !important;
}

.incompatible-composition-modal .modal-title {
    margin-bottom: 20px;
}

.discard-pianorolledit-modal .modal-title {
    text-align: center !important;
    margin-bottom: 15px;
}

.discard-pianorolledit-modal .modal-subtitle {
    text-align: center !important;
    margin-bottom: 15px;
}

.discard-pianorolledit-modal .modal-text-button {
    margin-left: 0px !important;
}

.discard-pianorolledit-modal .modal-container {
    width: 500px;
    text-align: center !important;
}

.discard-pianorolledit-modal .modal-title {
    margin-top: 5px;
}

.modal-section-container {
    width: 100%;
}

.regenerate-with-source-section {
    width: 40px;
    line-height: 30px;
    display: inline-block;
    background-image: linear-gradient(rgb(96, 96, 96), rgb(74, 74, 74));
    overflow: hidden;
}

.regenerate-with-source-modal .modal-container {
    width: 700px;
    margin-left: -220px;
    text-align: center !important;
}

.delete-modal .modal-container {
    width: 400px;
    text-align: center !important;
}

.delete-modal .modal-button {
    margin-top: 30px;
    font-size: 90%;
}

.plan-willdowngrade {
    font-size: 70%;
    margin-top: 32px;
    margin-bottom: 8px;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    font-style: italic;
}

.upload-influence-dropzone-mobile {
    padding: 14px;
    padding-right: 19px;
    padding-left: 19px;
    background-color: #ffffff21;
    border-radius: 8px;
    font-size: 90%;

    img {
        width: 20px;
        margin-right: 10px;
        margin-top: -4px;
        vertical-align: middle;
    }
}

@media (max-device-width: 700px) {
    #upload-midi-container {
        .ngx-file-drop__content {
            width: auto !important;
        }

        .ngx-file-drop__drop-zone {
            width: auto !important;
        }
    }

    .preset {
        margin-top: 2px !important;
    }

    #upload-midi-container {
        margin-top: 10px !important;
    }

    .upload-secondstep .headline {
        margin-bottom: 10px !important;
    }

    .upload-secondstep .button-row {
        margin-top: 5px !important;
    }

    .upload-secondstep .header {
        display: none !important;
    }

    .upload-secondstep .influence-audio-waveform .loader-container {
        padding-top: 10px !important;
    }
}

@media (max-device-width: 1050px) {
    #preset-settings-container .modal-blue-button {
        margin-left: 0px !important;
    }

    #preset-settings-container .modal-button {
        position: relative !important;
        bottom: auto !important;
    }

    #preset-settings-container #button-grey {
        margin-left: 0px !important;
    }
}

@media (max-width: 1050px) {
    #preset-settings-container .modal-blue-button {
        margin-left: 0px !important;
    }

    #preset-settings-container .modal-button {
        position: relative !important;
        bottom: auto !important;
    }

    #preset-settings-container #button-grey {
        margin-left: 0px !important;
    }
}

#student-discounts {
    margin-top: 25px;
    margin-bottom: 15px;
    font-size: 100%;
}

.side-panel {
    width: 240px !important;
    max-width: 240px !important;
    background-color: rgba(232, 232, 232, 0.1) !important;
}

.side-panel-cycle {
    line-height: 60px !important;
}

.side-panel .side-panel-element {
    text-align: left !important;
    padding-left: 18px;
    padding-right: 18px;
}

.side-panel-element .tooltip .tooltiptext {
    left: auto !important;
    top: auto !important;
    margin-left: 20px;
    font-size: 100%;
}

.side-panel-element .tooltip {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    line-height: 15px;
}

.side-panel-element .tooltip img {
    width: 15px;
    margin-top: 1px;
}

.side-panel-element .tooltip img:hover {
    cursor: pointer;
    opacity: 0.7;
}

.side-panel-element {
    font-weight: 500;
    font-size: 82%;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.billing-plan-container.free-plan-container {
    height: 280px !important;
}

.copyright-element {
    text-decoration: underline;
}

.billing-faq {
    margin-top: 40px;
}

.billing-plan-header {
    font-size: 100%;
    text-align: left;
    width: 985px;
    margin-left: auto;
    margin-right: auto;
}

.faq-question-blue {
    background-color: var(--secondary-color) !important;
}

.faq-section {
    margin-left: auto;
    margin-right: auto;
    width: 1007px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;
}

.faq-question:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.452);
}

.faq-question {
    background-color: rgba(255, 255, 255, 0.252);
    font-size: 110%;
    font-weight: 500;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.152);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.faq-answer a {
    color: rgb(0, 183, 255);
    font-weight: 600;
}

.has-melody {
    font-size: 80%;
    font-weight: 400;
}

.backing-track {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

.faq-answer {
    line-height: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: rgba(0, 0, 0, 0.225);
    margin-left: auto;
    margin-right: auto;
    font-size: 100%;
    margin-bottom: 40px;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.billing-plan-container {
    height: 540px;
    vertical-align: top;
    border-radius: 2px;
    max-width: 150px;
    width: 18%;
    display: inline-block;
    background-color: rgba(100, 100, 100, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 0px;
}

.plan-price-container {
    text-align: center;
}

.plan-header {
    font-weight: 400;
}

.plan-price-subtitle {
    font-style: italic;
    font-size: 80%;
    margin-top: 5px;
    display: inline-block;
    margin-left: 7px;
}

.billing-plan-container .plan-price {
    display: inline-block;
    font-weight: 600;
    margin-top: 5px;
    font-size: 120%;
}

#preset-suggestion {
    color: white;
    font-size: 85%;
    height: 25px;
    margin-top: 20px;
    text-decoration: underline;
}

.learn-more {
    margin-top: 20px;
    font-weight: 500;
}

.billing-plan-container .plan-title-nobg {
    background-color: transparent !important;
}

.billing-plan-container .plan-title {
    font-weight: 500;
    font-size: 92%;
    padding-top: 18px;
    padding-bottom: 7px;
    height: 52px;
    background-color: rgba(255, 255, 255, 0.1);
}

.billing-plan-container .plan-features {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    white-space: normal;
}

.parameter-info {
    margin-top: -10px;
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
    padding-right: 13px;
}

.parameter-info:hover {
    cursor: pointer;
}

.parameter-info img {
    width: 15px;
    vertical-align: middle;
}

.parameter-info .tooltiptext {
    font-size: 90% !important;
    margin-left: 28px !important;
    margin-top: -1px !important;
    top: auto;
    left: auto;
}

.copyright-info {
    display: inline-block;
}

.copyright-info:hover {
    cursor: pointer;
}

.copyright-info img {
    width: 18px;
    vertical-align: middle;
    margin-left: 4px;
}

.copyright-info .tooltiptext {
    font-size: 90% !important;
    margin-left: 28px !important;
    margin-top: -1px !important;
    top: auto;
    left: auto;
}

.billing-plan-container .plan-features li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.plan-button {
    background-color: var(--secondary-color);
    font-weight: 900 !important;
    text-transform: uppercase;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-radius: 5px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;

    width: 100%;
}

.plan-button-downgrade {
    background-color: rgb(190, 190, 190);
}

.red {
    color: rgb(255, 80, 27);
    font-weight: 500;
}

.green {
    color: rgb(0, 194, 48);
}

.cross-icon {
    font-size: 110%;
}

.plan-current {
    padding-top: 7px;
    padding-bottom: 7px;
    font-weight: 600;
    font-size: 90%;
}

.plan-current img {
    margin-right: 5px;
    vertical-align: middle;
    margin-top: -2px;
    width: 15px;
    opacity: 0.8;
}

.modal-text-button {
    padding-left: 0px !important;
    margin-left: 22px;
    padding-right: 0px !important;
    margin-right: 22px;
}

.modal-text-button:hover {
    background-color: transparent !important;
    color: rgba(255, 255, 255, 0.9) !important;
}

.delete-modal .modal-subtitle {
    font-size: 95%;
    text-align: center !important;
}

.delete-modal .modal-title {
    font-size: 150% !important;
    text-align: center !important;
    margin-top: 15px;
}

.survey-modal .modal-title {
    text-align: center;
    margin-top: 10px;
}

.survey-modal .user-type {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 7px;
    padding-bottom: 7px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 90%;
    border-radius: 8px;
}

.survey-modal .user-type:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.15);
}

.survey-modal .user-type:active {
    cursor: pointer;
    background-color: var(--secondary-color);
}

.survey-question {
    margin-bottom: 22px;
    font-size: 90%;
}

.survey-modal .modal-container {
    width: 450px;
    text-align: center;
}

.modal-embed img {
    width: 250px;
    margin-top: 15px;
    cursor: pointer;
}

.modal-embed img:hover {
    opacity: 0.85;
}

.upload-thirdstep img {
    width: 65px;
    opacity: 0.9;
}

.edit-influence-container .modal-subtitle {
    width: 100%;
    text-align: center;
    font-style: normal;
}

.upload-successful-subtitle {
    font-size: 90%;
}

.upload-successful {
    margin-top: 8px;
    margin-bottom: 10px;
    font-size: 120%;
    font-weight: 500;
}

.upload-thirdstep-title {
    text-align: left;
}

.edit-influence-modal .modal-container {
    width: 600px;
    margin-left: -300px;
    margin-top: -230px;
    height: 360px;
    text-align: center;
}

.create-with-influence-modal .modal-title {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0px;
}

.parameter-item-emotion-title {
    font-size: 70%;
    font-size: 50%;
    font-weight: 600;
    vertical-align: top;
}

.create-with-influence-modal .modal-container {
    width: 590px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}

.create-modal.emotion-view .modal-container {
    width: 650px !important;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}

.selected-ensemble-gold {
    background-color: #eec300 !important;
    color: white !important;
}

.selected-ensemble-silver {
    background-color: silver !important;
    color: white !important;
}

.selected-ensemble-bronze {
    background-color: brown !important;
    color: white !important;
}

.create-with-influence-modal.ensemble-selection .modal-container {
    min-width: 500px;
    width: 95%;
    max-width: 1435px;
    margin-left: -460px;
    min-height: 340px;
    text-align: center;
}

.create-with-influence-modal.mode-change .modal-container {
    min-height: 240px;
    padding: 0px 15px;
    max-width: calc(590px - 15px);
}

.create-with-influence-modal-container {
    max-height: 95%;
}

.ambient-container {
    vertical-align: top;
}

.re-captcha a {
    color: white !important;
}

.re-captcha {
    font-size: 60% !important;
    display: block;
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: white;
}

.grecaptcha-badge {
    visibility: hidden;
}

.create-modal .modal-container {
    width: 1236px;
    text-align: center;
    padding-top: 10px !important;
    padding-bottom: 15px;
}

.change-instrumentation-modal .modal-container {
    width: 335px;
    padding: 3px;
    margin-left: 0px;
    margin-top: 0px;
    height: calc(100% - 80px);
    left: auto;
    right: 0px;
    top: 0px;
    text-align: center;
    position: fixed;
    overflow-y: scroll;
}

.change-instrumentation-modal .create-modal-button {
    width: calc(100% - 44px) !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.change-instrumentation-modal .text-button {
    margin-top: 0px;
}

#download-filename-parameters {
    font-size: 80%;
    margin-top: 10px;
}

#download-filename-parameters:hover {
    cursor: pointer;
}

.change-instrumentation-modal .instrument-list-item img {
    width: 18px;
}

.change-instrumentation-modal .instrument-list-item {
    font-size: 85%;
}

.change-instrumentation-modal .modal-preset-title {
    opacity: 1 !important;
}

.change-instrumentation-modal .modal-preset-title:hover {
    cursor: auto;
}

.create-modal input {
    display: inline-block;
    font-size: 95%;
}

.create-modal .modal-send-button {
    display: inline-block;
}

#preset-feedback {
    margin-top: 15px;
    text-align: center;
}

.modal-send-button.modal-send-invitation-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    top: 57px;
    position: absolute;
}

#preset-feedback #button-grey {
    margin-left: auto;
    margin-right: auto;
    position: relative;

    background-color: rgba(255, 255, 255, 0.3);

    width: 200px;
}

#preset-feedback #button-grey:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
}

#preset-feedback #button-grey:active {
    background-color: var(--secondary-color);
}

.modal-share-input {
    font-size: 95% !important;
    display: inline-block !important;
    width: 230px !important;
    vertical-align: middle;
    line-height: 28px !important;
}

.modal-send-button {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    margin: 0px !important;
    border-radius: 0px !important;
    display: inline-block;
    float: none;
}

#invite-bar-container {
    text-align: left;
}

.invited-email {
    font-size: 90%;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
}

.invited-email img {
    vertical-align: middle;
    margin-top: -2px;
    width: 20px;
    margin-right: 10px;
    display: inline-block;
}

.invited-email-cancel {
    width: 10px !important;
    float: right;
    margin-top: 5px !important;
}

.invited-email-cancel:hover {
    cursor: pointer;
    opacity: 0.8;
}

.invited-email-img-question {
    width: 30px !important;
    margin-left: -7px;
    margin-right: 7px !important;
    margin-top: -4px !important;
}

.move-menu-blank-item {
    width: 25px;
    margin-right: 8px;
    display: inline-block;
}

.modal-copyright {
    text-align: left;
    font-size: 80%;
}

.regenerate-with-source-menu .right-click-menu-item {
    font-size: 100% !important;
}

.right-click-menu-item-title {
    border-bottom: 1px solid #ffffff17;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 700;
}

.right-click-menu-scroll {
    margin-left: 1px;
    top: 0px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    width: calc(100% - 14px);
    padding-left: 7px;
    padding-right: 7px;
    left: 0px;
}

.right-click-sub-menu {
    font-size: 120% !important;
    height: 150px;
    left: 237px;
    margin-top: -100px;
    position: absolute;
}

.regenerate-with-source-menu {
    min-width: 190px;
    max-height: 30vh;
    height: 30vh;
    min-height: 100px;
    left: 350px;
    margin-top: -109px;
}

.modal-version {
    font-size: 90%;
    font-weight: 500;
    margin-top: 20px;
    text-align: left;
}

#additional-img {
    margin-top: 5px;
    margin-right: 15px !important;
    position: absolute !important;
    width: 3px;
    right: 0px;
    vertical-align: middle;
    margin-left: 10px;
}

.user-menu-main-button {
    background-color: var(--secondary-color) !important;
}

#user-menu-content #user-menu-button {
    font-weight: 500;
    font-size: 80%;
    padding-top: 10px;
    padding-bottom: 10px;
}

#user-menu-content #user-menu-button:active {
    background-color: rgba(220, 220, 220, 0.4) !important;
}

#user-menu-content #user-menu-button:hover {
    background-color: rgba(220, 220, 220, 0.2);
}

#user-menu-button:hover {
    background-color: var(--secondary-color);
    cursor: pointer;
    opacity: 1;
}

#user-picture {
    width: 20px;
    vertical-align: middle;
    margin-right: 9px;
}

#down-chevron {
    width: 10px;
    vertical-align: middle;
    display: inline-block;
}

#menu-email {
    margin-top: -3px;
    width: 55%;
    font-size: 90%;
    margin-right: 10px;
    display: inline-block !important;
    vertical-align: middle;
}

.menu:hover {
    cursor: pointer;
    border-color: var(--secondary-color);
    opacity: 1;
}

.trackslist-button-container .create-button.white-button:active {
    background-color: #6fabff !important;
    border-color: transparent !important;
}

.plan-name {
    text-transform: capitalize;
}

.create-button.white-button {
    color: #002c69 !important;
    background-color: white !important;
    display: inline-block;
}

#billing-container .loader {
    font-size: 3px;
    width: 17px !important;
    height: 17px !important;
    margin-bottom: 1px !important;
    margin-top: 3px !important;
}

.create-button.white-button:hover {
    border-color: #004fbe77 !important;
    color: #002c69 !important;
}

.trackslist-button-container .create-button {
    margin-top: 3px !important;
}

.create-button.white-button:active {
    background-color: rgb(180, 180, 180) !important;
    color: white !important;
}

.create-button {
    background-color: var(--primary-color) !important;
    border-radius: 7px !important;
    width: 140px !important;
    margin-top: 14px;
}

.create-button:hover {
    border-color: rgb(59, 255, 173) !important;
}

.create-button:active {
    background-color: rgb(59, 255, 173) !important;
}

.create-button img {
    width: 12px;
    margin-left: 4px;
}

.menu img {
    width: 16px;
    vertical-align: middle;
}

#menus-right .non-button-menu {
    border: none;
}

.menu.round-button {
    opacity: 1;
    line-height: 0px;
    padding-right: 14px;
    padding-left: 14px;
    border-radius: 5px;
    margin-right: 25px;
    margin-left: -15px;
    width: auto;
    letter-spacing: 1;
    font-size: 95%;
    font-weight: 700 !important;
    line-height: 12px;
}

#sidebar {
    height: calc(100% - 69px);
    background-color: rgba(0, 0, 0, 0.49);
    z-index: 99;
    width: 175px;
    color: rgba(255, 255, 255, 0.85);
    padding-left: 30px;
    position: fixed;
    left: 0px;
    padding-top: 18px;
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

#sidebar-title {
    color: white;
    font-size: 95%;
    font-weight: 400;
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    opacity: 0.8;
    border-left: 3px solid transparent;
    padding-left: 18px;
}

.modal-background {
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;

    &--disabled {
        pointer-events: none;
    }
}

.modal-container-download {
    text-align: center !important;
}

.modal-container {
    text-align: right;
    border-radius: 11px;
    width: 300px;
    position: absolute;
    z-index: 9999;
    color: white;
    background-color: rgba(38, 38, 60, 0.98);
    left: 50%;
    margin-left: -150px;
    top: 50%;
    margin-top: -270px;
    padding: 20px;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
    border: 1px solid #00000042;
}

.licensing-button {
    display: block !important;
    text-decoration: underline;
    margin-top: 30px !important;
    font-size: 90% !important;
    margin-left: 15px !important;
}

.auto-mix-modal .modal-subtitle {
    font-size: 95%;
}

.auto-mix-modal .modal-button img {
    width: 15px !important;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -2px;
}

.create-modal-button {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 15px !important;
}

.create-modal-button:hover {
    color: white !important;
}

.modal-button {
    line-height: 20px;
    text-align: center;
    font-size: 80%;
    border-radius: 3px;
    padding: 5px;
    display: inline-block;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 8px;
    font-weight: 500;
}

.right-click-menu-item .loader {
    font-size: 3px !important;
    width: 15px !important;
    height: 15px !important;
    margin-right: 0px !important;
    vertical-align: middle;
    margin-top: -5px !important;
    margin-left: -2px !important;
}

.modal-button:hover {
    cursor: pointer !important;
}

.modal-green-button {
    background-color: var(--primary-color) !important;
    color: white;
    font-weight: 600;
    border-radius: 3px;
}

.modal-green-button:hover {
    background-color: rgb(1, 185, 93) !important;
}

.modal-green-button:active {
    background-color: rgb(59, 255, 173) !important;
}

.modal-red-button {
    background-color: red !important;
    color: white;
    font-weight: 600;
    border-radius: 3px;
}

.influence-ensemble-category-close {
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-left: 12px;
    vertical-align: middle;
}

.track-icon-influences img {
    width: 19px !important;
    margin-left: 14px !important;
}

.modal-red-button:hover {
    background-color: rgb(200, 0, 0) !important;
    color: white;
}

.reset-cache-loader {
    width: 18px !important;
    height: 18px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: block;
    margin-top: 20px;
    font-size: 3px;
    left: 10px;
}

.reset-cache {
    font-size: 90%;
    margin-top: 20px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.reset-cache:hover {
    cursor: pointer;
}

.modal-blue-button {
    background-color: var(--secondary-color) !important;
    color: white;
    font-weight: 600;
    border-radius: 3px;
}

.modal-grey-button {
    background-color: rgb(161, 161, 161) !important;
    color: white;
    font-weight: 600;
    border-radius: 3px;
}

.modal-blue-button:hover {
    color: white;
    background-color: rgb(0, 132, 255) !important;
}

.download-license {
    background-color: var(--secondary-color);
    text-align: center;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 11px;
    width: 550px;
    margin-left: auto;
    margin-right: auto;
    font-size: 80%;
    font-weight: 600;

    text-transform: capitalize;
}

.license-capitalization {
    text-transform: capitalize;
}

#download-bottom-container {
    width: 545px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 5px;
    text-align: left;
}

#download-bottom-container div u {
    margin-bottom: 3px;
    display: block;
}

.upgrade-your-license {
    margin-top: 5px;
    margin-bottom: 10px;
}

.download-upgrade-license {
    display: inline;
}

.download-quota-remaining {
    font-size: 80%;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.modal-download-button {
    background-color: white;
    color: black;
    text-align: left;
    display: inline-block;
    padding: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 90%;
}

.modal-download-button:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
    color: white;
}

.modal-input {
    display: block;
    outline: none;
    height: 20px;
    width: calc(100% - 15px);
    padding: 4px;
    font-size: 105%;
    margin-left: 1px;
}

.modal-close img {
    width: 15px;
    right: 22px;
    top: 21px;

    position: absolute;
}

.modal-close img:hover {
    cursor: pointer;
    opacity: 0.8;
}

.share-button-container {
    width: 55px;
    height: 32px;
    vertical-align: top;
    display: inline-block;
    background-color: var(--secondary-color);
    font-weight: 600;
    font-size: 90%;
    text-align: center;
}

.share-button-container:hover {
    cursor: pointer;
    background-color: rgb(0, 132, 255) !important;
}

@-moz-document url-prefix() {
    .share-button-container {
        height: 34px !important;
    }
}

.modal-title {
    text-align: left;
    margin-bottom: 10px;
    font-size: 125%;
    font-weight: 600;
}

.modal-subtitle {
    text-align: left;
}

#myprojects-component #project {
    margin-top: 15px;
    margin-bottom: 15px;
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;

    text-align: left;
}

#myprojects-component #project img:hover {
    cursor: pointer;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
}

#myprojects-component #project img {
    width: 150px;
    margin-bottom: 3px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

#myprojects-component #project-name {
    font-size: 85%;
    font-weight: 500;
    margin-left: 1px;
}

#myprojects-component #project-name:hover {
    cursor: pointer;
    text-decoration: underline;
}

#myprojects-component #project-subtitle {
    font-size: 75%;
    font-style: italic;
    font-weight: 400;
}

#sidebar-title:hover {
    cursor: pointer;
    opacity: 1;
}

#sidebar-projects {
    font-size: 80%;
    font-weight: 600;
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: calc(100% - 400px);
    width: calc(100% + 30px);
    margin-left: -30px;
}

.sidebar-projects-smaller {
    max-height: calc(100% - 515px) !important;
}

#sidebar-projects div {
    margin-bottom: 8px;
    border-left: 3px solid transparent;
    padding-left: 27px;
    padding-top: 1px;
    padding-bottom: 1px;
}

#sidebar-projects div:hover {
    color: white;
    cursor: pointer;
}

.highlighted-menu {
    opacity: 1 !important;
    color: white;
    border-color: var(--secondary-color) !important;
}

#sidebar-addproject {
    font-size: 90%;
    margin-top: 20px;
    color: white;
    opacity: 0.85;
}

#sidebar-addproject img {
    width: 12px;
    margin-right: 10px;
    vertical-align: middle;
    margin-top: -2px;
}

#sidebar-addproject:hover {
    cursor: pointer;
    opacity: 1;
}

.layer-soloed-tracks {
    color: rgb(255, 169, 0);
}

.layer-soloed {
    background-color: rgb(255, 169, 0) !important;
    color: white !important;
}

.layer-muted-tracks {
    color: red;
}

.layer-muted {
    background-color: red !important;
    color: white !important;
}

.dynamic-activated {
    background-image: none !important;
    background-color: orange !important;
}

.layer-playback-buttons {
    vertical-align: top;
    width: auto !important;
    margin-right: 3px;
    top: 6px !important;
    margin-left: 4px;
}

.layer-buttons {
    float: right;
    margin-left: calc(var(--layerlist-width) - 44px);
    margin-top: -36px;
    position: absolute;
}

#timeline-cursor-indicator {
    position: relative;
    width: 0;
    height: 0;
    margin-left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;

    border-top: 10px solid rgb(240, 240, 240);
    margin-top: 17px;
}

#player #controls {
    display: inline-block;
    margin-right: 5px;
    margin-left: 35px;
    margin-right: 35px;
    vertical-align: top;

    width: 135px;
    text-align: center;
}

#player #controls div {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    margin-right: 2px;

    vertical-align: middle;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    display: inline-block;

    opacity: 0.8;
}

#player #controls div:hover {
    opacity: 1;
}

#player #controls div:active {
    opacity: 0.5;
}

#player .loader {
    width: 19px !important;
    height: 19px !important;
    font-size: 3px !important;
    margin-top: -15px;
}

.no-audio-converted {
    color: red;
    font-size: 90%;
    margin-top: 15px;
}

#player #play {
    padding-left: 16px;
    padding-right: 14px;

    width: 35px !important;
    height: 35px !important;

    background-image: url("assets/img/player/play-circle-white.svg");
}

.player-loader-container {
    padding-left: 16px;
    padding-right: 14px;

    width: 35px !important;
    height: 35px !important;
    display: inline-block;
    vertical-align: middle;
}

#player #play:hover {
    cursor: pointer;
}

#player #pause {
    padding-left: 16px;
    padding-right: 14px;

    width: 35px !important;
    height: 35px !important;

    background-image: url("assets/img/player/pause-circle.svg");
}

#player #pause:hover {
    cursor: pointer;
}

#player #previous {
    background-image: url("assets/img/player/previous.svg");
}

#player #previous:hover {
    cursor: pointer;
}

#player #next:hover {
    cursor: pointer;
}

#player #next {
    background-image: url("assets/img/player/next.svg");
}

#player #progress {
    width: 520px;
    display: inline-block;
    vertical-align: top;

    margin-top: 2px;
    height: 10px;

    font-size: 75%;
    letter-spacing: 1px;
}

#player #time-elapsed {
    color: #3990f5;
    display: inline-block;
    line-height: 0px;
}

#player #progress-bar-wrapper {
    margin-left: 11px;
    margin-right: 11px;
    margin-top: -2px;

    display: inline-block;
    vertical-align: middle;
    border-radius: 15px;
    overflow: hidden;
    height: 4px;

    padding-top: 8px;
    padding-bottom: 8px;
}

.playing-anim-container-radio {
    float: right;
    margin-top: 6px;
    height: 5px !important;
}

#player #progress:hover {
    cursor: pointer;
}

.radio-like img {
    width: 16px;
}

.radio-like:hover {
    cursor: pointer;
}

.radio-like {
    margin-top: 10px;
    float: right;
}

#player #progress-bar {
    border-radius: 5px;
    overflow: hidden;
    width: 400px;
    height: 4px;
    background-color: rgba(31, 31, 31, 0.5);
    position: relative;

    z-index: 1;
}

#player #loading-progress-bar {
    position: absolute;
    background-color: rgb(30, 30, 30);
    z-index: 99 !important;
    height: 4px;
    border-radius: 6px;
}

#player #progress-bar-time {
    position: absolute;
    z-index: 99 !important;
    width: 0%;
    height: 4px;
    border-radius: 6px;
}

#player #total-time {
    color: white;
    display: inline-block;
    line-height: 0px;
}

#paypal-checkout-btn {
    margin-top: 20px;
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
}

#paypal-checkout-btn:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
}

#paypal-checkout-btn img {
    width: 80px;
}

#billing-component .modal-preset-title {
    min-width: 180px;
}

#player {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;

    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 1);
    height: $player-height;

    line-height: 48px;

    border-top: 1px solid rgb(30, 30, 30);
}

.pianoroll-loader-subtitle {
    font-size: 80%;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
}

.player-right-control {
    vertical-align: middle;
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    float: right;
}

.player-right-control input {
    margin-left: 10px;
}

.volume-slider input {
    vertical-align: middle;
    display: inline-block;
}

.player-right-control img {
    display: inline-block;
    vertical-align: middle;
    height: 17px;
}

#player #player-wrapper {
    margin-left: auto;
    margin-right: auto;
}

#player #right-controls {
    display: inline-block;
    color: white;
    font-size: 90%;
    vertical-align: middle;
    max-width: calc(100% - 1025px);
}

.no-list-item {
    color: white;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 90%;
}

.pianoroll-bottom-section-container-smaller-offset {
    margin-right: 15px;
    margin-top: 6px;
}

.settings-container {
    text-align: left;
}

#arrangement-switch {
    margin-left: auto;
    margin-right: auto;

    font-weight: 500;

    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 100%;
    color: white;
}

.email-opt-in {
    font-size: 90%;
    margin-bottom: 8px;
}

#arrangement-switch label {
    display: inline-block !important;
    vertical-align: middle;
    width: 50px;
}

#arrangement-switch div {
    margin-left: 25px;
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#arrangement-switch *,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*transition*/
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    outline: none;
}

#arrangement-switch-label {
    text-align: left !important;
    display: block !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 10px !important;
    margin-top: 4px;
    display: block !important;
    font-display: 110%;
}

#period-billing-switch {
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 100%;
}

.preset-logo {
    font-weight: 400;
    font-size: 200%;
}

#period-billing-switch label {
    display: inline-block !important;
    vertical-align: middle;
    width: 50px;
}

#period-billing-switch div {
    margin-left: 25px;
    margin-right: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

#period-billing-switch *,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /*transition*/
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    outline: none;
}

#toggles {
    width: 60px;
    margin: 50px auto;
    text-align: center;
}

.ios-toggle,
.ios-toggle:active {
    position: absolute;
    top: -5000px;
    height: 0;
    width: 0;
    opacity: 0;
    border: none;
    outline: none;
}

.checkbox-label {
    display: block;
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 17px;
    width: 100%;
    height: 27px;
    /*border-radius*/
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    background: #bbbbbb;
    cursor: pointer;
}

.hide-separator {
    opacity: 0;
}

.checkbox-label:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 34px;
    text-indent: 40px;
    height: 30px;
    width: 30px;
    /*border-radius*/
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: -1px;
    left: 0px;
    right: auto;
    background: white;
    border: 2px solid rgba(128, 128, 128, 0.479);
    /*box-shadow*/
    /*-webkit-box-shadow:0 3px 3px rgba(0,0,0,.2),0 0 0 2px #dddddd;
	-moz-box-shadow:0 3px 3px rgba(0,0,0,.2),0 0 0 2px #dddddd;
			box-shadow:0 3px 3px rgba(0,0,0,.2),0 0 0 2px #dddddd;*/
}

.checkbox-label:after {
    content: attr(data-off);
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: -300px;
    padding: 10px;
    height: 100%;
    width: 300px;
    text-align: right;
    color: #bfbfbf;
    white-space: nowrap;
}

.ios-toggle:checked + .checkbox-label {
    /*box-shadow*/
    /*-webkit-box-shadow:inset 0 0 0 20px rgba(19,191,17,1),0 0 0 2px rgba(19,191,17,1);
	-moz-box-shadow:inset 0 0 0 20px rgba(19,191,17,1),0 0 0 2px rgba(19,191,17,1);
			box-shadow:inset 0 0 0 20px rgba(19,191,17,1),0 0 0 2px rgba(19,191,17,1);*/
}

.ios-toggle:checked + .checkbox-label:before {
    left: calc(100% - 25px);
}

.ios-toggle:checked + .checkbox-label:after {
    content: attr(data-on);
    left: 60px;
    width: 25px;
}

/*#checkbox3 + .checkbox-label{
	-webkit-box-shadow:inset 0 0 0 0px #1fc1c8,0 0 0 2px #dddddd;
	-moz-box-shadow:inset 0 0 0 0px #1fc1c8,0 0 0 2px #dddddd;
			box-shadow:inset 0 0 0 0px #1fc1c8,0 0 0 2px #dddddd;
}*/

#checkbox3:checked + .checkbox-label:after {
    color: #1fc1c8;
}

.billed-option-unselected {
    opacity: 0.7;
}

#google-btn {
    min-height: 40px;
    height: 40px;
}

.google-btn {
    max-width: 95%;
    width: 322px;
    min-width: 184px;
    height: 36px;
    background-color: #fcfcfc;
    border-radius: 5px;
    cursor: pointer;
    cursor: hand;
    align-self: center;
    user-select: none;
    transition: all 200ms ease 0s;
    border: 2px solid var(--secondary-color);
    display: flex;
    justify-content: center;
}
.google-btn .google-icon-wrapper {
    height: 40px;
    border-radius: 2px;
    user-select: none;
    text-align: initial;
}
.google-btn .google-icon-svg {
    position: absolute;
    margin-top: 10px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
    user-select: none;
}
.google-btn .btn-text {
    float: right;
    margin: 6px 0px 0px 40px;
    font-size: 17px;
    letter-spacing: 0.2px;
    user-select: none;
    color: var(--secondary-color);
    font-weight: bold;
}
.google-btn:hover {
    box-shadow: 0 2px 6px rgba(117, 117, 117, 0.4);
    user-select: none;
}
.google-btn:active {
    box-shadow: 0 1px 1px #757575;
    background: #f8f8f8;
    color: #fff;
    user-select: none;
}

@media (max-device-height: 480px) {
    #public-composition-container,
    #public-playlist-container {
        margin-top: 0px !important;
    }

    #public-player-background #logo {
        display: none;
    }
}

@media (max-device-height: 650px) {
    #public-playlist-container
        .composition-container
        .composition-item
        .composition-playback {
        width: 30px !important;
    }

    #public-playlist-container .composition-playback .playback-button {
        display: none;
    }

    #public-playlist-container .composition-playback .loader {
        display: none;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-playback {
        width: 0px !important;
        height: 22px !important;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-duration {
        font-size: 90% !important;
    }

    #public-playlist-container
        .composition-container
        .composition-item
        .composition-meta
        .composition-title {
        margin-top: 0px !important;
    }
}

@media (max-height: 600px) {
    .sidebar-title-faq {
        display: none !important;
    }
}

@media (max-height: 675px) {
    .feedback-button {
        display: none;
    }
}

@media (max-device-height: 600px) {
    .path {
        display: none;
    }

    .trackslist-toolbar .toggle-track-view {
        display: none;
    }
}

@media (max-height: 750px) {
    #public-player-background .composition-title {
        margin-top: 5px !important;
    }

    #public-playlist-container #progress #time-elapsed {
        font-size: 90%;
        margin-top: 5px !important;
    }

    #public-playlist-container #progress #total-time {
        font-size: 90%;
        margin-top: 5px !important;
    }

    #public-player-background {
        padding-top: 20px !important;
    }

    #public-playlist-container #progress-bar-wrapper {
        margin-top: 15px !important;
    }

    #public-playlist-container #progress-bar-wrapper #progress-bar-time {
        height: 3px !important;
    }

    #public-playlist-container .player-container {
        margin-top: 7px !important;
    }

    #public-playlist-container .main-playback .controls {
        width: 140px !important;
    }

    #public-playlist-container .main-playback .controls .next,
    #public-playlist-container .main-playback .controls .previous {
        width: 14px !important;
        height: 14px !important;
    }

    #public-playlist-container .main-playback .playback-button {
        width: 15px !important;
        height: 15px !important;
        padding: 7px !important;
    }

    #public-playlist-container .composition-container {
        min-height: 200px !important;
    }

    .pricing-note {
        margin-top: 20px !important;
    }

    #billing-component .modal-preset-title {
        margin-top: 5px !important;
    }

    #billing-component .licensing-button {
        margin-top: 5px !important;
        margin-bottom: 0px !important;
    }

    .plan-current {
        margin-top: 0px !important;
    }

    .plan-button {
        margin-top: 0px !important;
    }

    #student-discounts {
        margin-top: 10px !important;
    }

    #period-billing-switch {
        font-size: 90% !important;
        margin-top: 10px !important;
    }
}

#radar-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.desktop-grid-16 {
    width: 16.6%;
}

@media (max-width: 1500px) {
    .track-modificationdate {
        display: none !important;
    }

    .track-instrumentation {
        width: 125px !important;
    }

    .track-title {
        width: calc(53% - 303px);
    }

    // .track-title {
    // 	width: calc(55% - 378px);
    // }

    // .trackslist-single-playlist .track-title {
    // 	width: calc(55% - 400px);
    // }

    // .track-from {
    // 	width: calc(39% - 315px);
    // }

    /* .trackslist-single-playlist .track-from {
		width: calc(47% - 345px);
	} */

    .track-instrumentation-influence {
        width: 200px !important;
    }

    /* .playlists-title-container {
		margin-left: auto;
		margin-right: auto;
		width: 97%;
	} */
}

@media (max-width: 1450px) {
    .track-title-influence {
        width: 350px !important;
    }

    .desktop-grid-16 {
        width: 20%;
    }
}

@media (max-width: 1370px) {
    .track-modificationdate {
        display: none !important;
    }

    .track-title {
        width: calc(100% - 800px);
    }

    .track-instrumentation {
        width: 115px !important;
    }

    .track-bpm {
        width: 50px !important;
    }

    .track-title {
        width: calc(100% - 858px);
    }

    .track-from {
        width: 90px;
    }
}

@media (max-width: 1300px) {
    .track-title-influence {
        width: 250px !important;
    }

    .composition-parameters-title {
        display: none !important;
    }
}

@media (max-width: 1200px) {
    .track-instrumentation-influence {
        width: 160px !important;
    }

    .track-title-influence {
        width: 200px !important;
    }

    .track-view-list-compositions {
        .track-timesignature {
            display: none !important;
        }

        .track-bpm {
            display: none !important;
        }

        .track-key {
            display: none !important;
        }

        .track-title {
            width: calc(100% - 590px);
        }
    }
}

@media (max-width: 1150px) {
    .mobile-grid-25 {
        width: 25%;
    }
}

@media (max-width: 750px) {
    .mobile-grid-30 {
        width: 30%;
    }
}

.currently-playing {
    display: inline-block;
    font-weight: 500;
    vertical-align: middle;
}

.playing-anim-container {
    height: 6px;
    width: 30px;
    display: inline-block;
}

.playing-anim-container > div {
    position: relative;
    display: inline-block;
    background: white;
    height: 100%;
    width: 3px;
    margin-left: 1px;
    margin-right: 1px;
    -webkit-animation: playing-anim 3s ease-in-out infinite;
    animation: playing-anim 3s ease-in-out infinite;
}

.playing-anim-container .rectangle-2 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

.playing-anim-container .rectangle-3 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.playing-anim-container .rectangle-4 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.playing-anim-container .rectangle-5 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

.playing-anim-container .rectangle-6 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

@-moz-keyframes playing-anim {
    0%,
    100% {
        -moz-transform: scaleY(1);
    }
    16.67% {
        -moz-transform: scaleY(3);
    }
    33.33% {
        -moz-transform: scaleY(1);
    }
    50% {
        -moz-transform: scaleY(3);
    }
    66.67% {
        -moz-transform: scaleY(1);
    }
    83.34% {
        -moz-transform: scaleY(3);
    }
}

@-webkit-keyframes playing-anim {
    0%,
    100% {
        -webkit-transform: scaleY(1);
    }
    16.67% {
        -webkit-transform: scaleY(3);
    }
    33.33% {
        -webkit-transform: scaleY(1);
    }
    50% {
        -webkit-transform: scaleY(3);
    }
    66.67% {
        -webkit-transform: scaleY(1);
    }
    83.34% {
        -webkit-transform: scaleY(3);
    }
}

@keyframes playing-anim {
    0%,
    100% {
        transform: scaleY(1);
    }
    16.67% {
        transform: scaleY(3);
    }
    33.33% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(3);
    }
    66.67% {
        transform: scaleY(1);
    }
    83.34% {
        transform: scaleY(3);
    }
}

.tracks-header {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 1600px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.trackslist-content {
    height: 100%;
}

.trackslist-toolbar {
    position: relative;
    max-width: 1600px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.autofill-modal {
    .modal-container {
        width: 400px;
    }

    .button-container {
        margin-top: 10px;
    }

    .loader {
        font-size: 4px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.back-to-playlists-button {
    cursor: pointer;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    width: 192px;
    height: 27px;
    font-weight: 600;
    background: rgba(150, 162, 201, 0.19);
    transition: all 100ms;
    padding: 5px;
}

.back-to-playlists-button:hover {
    background-color: var(--secondary-color);
}

.back-to-playlists-button .back-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("assets/img/back.svg");
}

.back-to-playlists-button .back-text {
    margin-left: 10px;
}

.toggle-track-view {
    display: flex;
    /* z-index: 999;
	position: absolute;
	right: 0; */
    position: relative;
    float: right;
}

.toggle-track-view > .trackslist-view,
.toggle-track-view > .playlist-view {
    width: 35px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgba(150, 162, 201, 0.19);
    transition: all 100ms;
    padding: 4px;
}

.toggle-track-view > .trackslist-view {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.toggle-track-view > .playlist-view {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.toggle-track-view > .trackslist-view:hover,
.toggle-track-view > .trackslist-view.selected,
.toggle-track-view > .playlist-view:hover,
.toggle-track-view > .playlist-view.selected {
    background: var(--secondary-color);
}

.toggle-track-view .trackslist-view .tracks-icon,
.toggle-track-view .playlist-view .playlists-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.toggle-track-view .playlist-view .playlists-icon {
    background-image: url("assets/img/grid.svg");
}

.playlists-content,
#playlists-component {
    height: 100%;
    /* margin-left: 10px;
	margin-right: 20px; */
}

#playlists-component .playlists {
    height: 100%;
    width: 100%;
}

.playlists-title-container {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
}

.playlists-component-title {
    font-family: apple-system, roboto, lato;
    width: 95%;
    max-width: 1550px;
    font-size: 250%;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 20px;
}

.playlists-component-subtitle {
    margin-bottom: 10px;
}

.playlist-items-container {
    margin-top: 15px;
}

.playlist-item {
    margin-right: auto;
    width: 90%;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
}

.playlist-item-info {
    position: relative;
    border-radius: 5px;
    padding: 50%;
    background-repeat: no-repeat;
    opacity: 1;
}

.create-with-gp.parameters-view {
    width: 98% !important;
}

.composition-creation-gp .modal-container {
    width: 590px;
    text-align: center;

    .parameters-view {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    .modal-title {
        text-align: center !important;
    }

    .parameter-wrapper-title {
        text-align: left !important;
    }

    .parameter-wrapper {
        .modal-subtitle {
            margin-bottom: 10px;
        }

        text-align: left;
        margin-top: 20px;
    }

    select.key-signature-selection-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: "";
        outline: none;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 400;
    }

    .key-signature-selection-button {
        display: inline-block;
        width: 100px;
        line-height: 34px;
        transition: background 0.07s;
        background-color: rgba(202, 202, 202, 0.09);
        border: 1px solid rgba(210, 210, 210, 0.1);
        vertical-align: middle;
        text-align: center;
        border-radius: 11px;
        font-size: 80%;
        font-weight: 500;
        color: white !important;

        img {
            width: 12px;
            height: 12px;
            margin: initial;
            margin-left: 6px;
        }

        &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.15);
        }
    }
}

.playlist-item-name {
    position: absolute;
    left: 15px;
    font-size: 170%;
    width: 80%;
    font-weight: 700;
    bottom: 15px;
}

.playlist-item-songs {
    font-size: 100%;
    opacity: 0.8;
    margin-bottom: 10%;
    margin-top: 5px;
    margin-left: 10px;
}

/* .playlist-item-image-background {
	position: absolute !important;
	background-image: url('assets/img/vinyl-disc-4.svg');
	background-size: 85%;
	background-position: center;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-repeat: no-repeat;
	opacity: 0.075;
} */

.playlist-item-image-background {
    position: absolute !important;
    background-image: url("assets/img/vinyl-disc-4.svg");
    background-size: 20%;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    opacity: 0.275;
}

.playlist-item-image-background.ripples {
    position: absolute !important;
    background-image: url("assets/img/vinyl-disc-4.svg");
    background-size: 100%;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    opacity: 1;
}

.playlist-item-play {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
}

.playlist-item-play > img {
    position: relative;
    width: 35%;
    top: 32.5%;
    left: 32.5%;
}

.playlist-item-play img:hover {
    width: 39%;
    top: 30.5%;
    left: 30.5%;
    cursor: pointer;
}

.playlist-item-play-volume {
    width: 25% !important;
    top: 38% !important;
    left: 38% !important;
}

.playlist-loader-container {
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    text-align: center;
    margin-top: 15px;
}

.playlist-loader-container .loader {
    font-size: 5px !important;
}

.single-playlist {
    margin-bottom: 15px;
    padding-right: 10px;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 100%;
}

.single-playlist-container {
    width: 100%;
    margin-top: 15px;
}

.single-playlist-container .playlist-discography {
    position: relative;
    flex-shrink: 0;
    width: 200px;
    margin-right: 20px;
    height: 200px;
    border: 1px solid transparent;
    border-radius: 5px;
}

/* .single-playlist-container .playlist-discography .background-image {
	position: absolute !important;
	background-image: url('assets/img/vinyl-disc-4.svg');
	background-size: 85%;
	background-position: center;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-repeat: no-repeat;
	opacity: 0.075;
} */

.single-playlist-container .playlist-discography .background-image {
    position: absolute !important;
    background-image: url("assets/img/vinyl-disc-4.svg");
    background-size: 20%;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    opacity: 0.275;
}

.single-playlist-container .playlist-discography .background-image.ripples {
    position: absolute !important;
    background-image: url("assets/img/vinyl-disc-4.svg");
    background-size: 100%;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    opacity: 1;
}

.single-playlist-container .playlist-discography .playlist-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 170%;
    text-align: left;
}

.single-playlist-container .playlist-info {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.single-playlist-container .playlist-info .description {
    margin-top: 30px;
    font-size: 150%;
    text-align: left;
    font-weight: bold;
}
.single-playlist-container .playlist-info .compositions-info {
    margin-top: 10px;
    font-size: 120%;
    margin-bottom: 10px;
    opacity: 0.8;
    text-align: left;
}

.single-playlist-container .playlist-info .toolbar {
    /* position: absolute;
	bottom: 0px; */
}

.single-playlist .create-button {
    z-index: 999;
    position: relative;
}

.playlist-header {
    display: flex;
    margin-top: 10px;
}

#mytracks-component.playlist-view {
    background: #460092 !important;
}

#mytracks-component.playlist-view .path {
    display: none;
}

.trackslist-single-playlist .track-rank {
    opacity: 1 !important;
}

.trackslist-single-playlist .track-rank .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: rgba(10, 10, 20, 0.9) !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 11px;
    padding: 8px 15px;
    font-size: 75%;
    margin-left: 8px;
    position: absolute;
    z-index: 1;
    height: 60px;
    overflow: auto;
    word-wrap: break-word;
    white-space: normal;
    opacity: 1 !important;
    text-decoration: none !important;
    text-transform: none !important;
    line-height: 15px !important;
    font-size: 100% !important;
}

.trackslist-single-playlist .track-rank:hover .tooltip {
    visibility: visible;
}

.rank-levels {
    width: 100%;
}

.rank-levels .level {
    width: 5px;
    margin-right: 2px;
    background: white;
    vertical-align: baseline !important;
    border-radius: 3px;
}

.rank-levels .level.transparent {
    background: rgba(150, 162, 201, 0.3);
}

.tooltip-container-title {
    font-weight: 700;
    white-space: pre;
}

.tracks-body .list-item-composition .rank-levels .level-1 {
    height: 5px !important;
}
.tracks-body .list-item-composition .rank-levels .level-2 {
    height: 8px !important;
}
.tracks-body .list-item-composition .rank-levels .level-3 {
    height: 10px !important;
}
.tracks-body .list-item-composition .rank-levels .level-4 {
    height: 13px !important;
}

/* Include rank in single playlist trackslist */
.trackslist-single-playlist .track-key {
    width: 80px;
}
.trackslist-single-playlist .track-timesignature {
    width: 50px;
}
.trackslist-single-playlist .track-creationdate {
    width: 90px;
}
.trackslist-single-playlist .track-modificationdate {
    width: 90px;
}
.trackslist-single-playlist .track-duration {
    max-width: 60px;
    width: 60px;
}
/* .trackslist-single-playlist .track-from {
	width: calc(47% - 465px);
} */

.playlist-view:not(.single-playlist-view) .tracks-header {
    height: 35px;
}

.empty-playlists-container {
    margin-top: 20px;
}

.empty-playlists-container .title {
    font-size: 150%;
    font-weight: bold;
}
.empty-playlists-container .text {
    margin-top: 10px;
    font-size: 110%;
}

/* DEMO for SASS mixin usage */
@mixin green-block {
    background-color: #07ff05 !important;
}

@mixin huge-font {
    font-size: 200% !important;
    @content; // used to extend our mixin by additional declarations
}

.mixin-test {
    @include green-block(

    ); // mixin without parameters. Mixin code will be placed here

    @include huge-font() {
        color: white !important; // this is the additional content used in the mixin
    }

    @include border-radius(
        20px
    ); // parametrized mixin to set the border-radius (see vendor-prefixes.scss)
}

.toggle-collapse-button {
    $toggle-collapse-button-width: 20px;

    @include border-radius(50%);
    opacity: 0.6;
    position: absolute;
    right: calc(5px); // put the button half over the edge
    top: 25px;
    cursor: pointer;
    height: $toggle-collapse-button-width;
    width: $toggle-collapse-button-width;
    display: grid;
    place-items: center;
    background-color: rgba(255, 255, 255, 0.19);
    opacity: 1;
    background-color: #5550e2;
    transition: all ease 0.15s;

    img {
        width: 12px;

        &.reversed {
            transform: rotate(180deg);
        }
    }

    &:hover {
        opacity: 1;
        background-color: var(--secondary-color);
    }
}

#sidebar-collapsed {
    position: fixed;
    left: 0;
    width: var(--sidebar-width, 85px); // this one will be set using JavaScript
    height: calc(100% - 50px);
    background-color: rgba(0, 0, 0, 0.49);
    z-index: 99;
    color: rgba(255, 255, 255, 0.85);
    display: block;

    &.sidebar-mac-electron {
        height: calc(100% - 101px);
        padding-top: 50px !important;

        .logo {
            margin: 0px auto 15px auto;
        }
    }

    .logo {
        margin: 20px auto 15px auto;
        cursor: pointer;

        img {
            width: 40px;
            margin: 0 auto;
            display: block;
        }
    }

    .create-button-circle {
        position: relative;
        @include border-radius(12px);
        background-color: var(--primary-color);
        color: white;
        width: 28px;
        height: 28px;
        margin: 0 auto;
        font-weight: bold;
        font-size: 140%;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        border: 2px solid transparent;
        transition: all ease 0.3s;

        &:hover {
            border-color: #3bffad !important;
        }

        img {
            width: 20px;
        }

        .tooltiptext {
            top: -3px;
            left: 115%;
            font-size: 60%;
            width: 90px;
            font-weight: initial;
        }
    }

    .menu-item {
        position: relative;
        opacity: 0.8;
        cursor: pointer;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent !important; // give it the same border left and right, so it stays centered
        font-size: 105%;

        &:hover {
            opacity: 1;
        }

        .badge {
            position: absolute;
            left: calc(
                var(--sidebar-width, 85px) -
                    calc(var(--sidebar-width, 85px) / 2) + 8px
            ); // badge position scales with sidebar-width
            top: -3px;
            right: 0px !important;
            margin-top: 0px !important;
            margin-left: -6px;
        }

        .collapsed-sidebar-icon {
            margin: 18px auto;
            width: 21px;
            height: 21px;
            display: block;
        }

        .tooltiptext {
            visibility: hidden;
        }

        .badge.tooltip:hover .tooltiptext {
            visibility: visible;
        }

        img:hover + .tooltiptext {
            visibility: visible;
            left: 80% !important;
            width: 100px !important;
            text-align: center;
        }
    }

    .navbar-buttons-container {
        margin-left: 0px !important;
        height: calc(100% - 100px - 150px) !important;
    }

    .sidebar-collapsed-content {
        width: 100%;
        height: 100%;
    }

    #user-menu-container {
        margin-left: 0px !important;

        .download-quota-subtitle {
            text-align: center;
        }

        .user-menu-button {
            display: grid;
            place-items: center;
            height: 38px;
            width: calc(100% - 19px);
            margin: 20px auto 9px;
            border: 1px solid transparent;
            @include border-radius(5px);
            background-color: rgba(255, 255, 255, 0.19);
            cursor: pointer;

            &:hover {
                background-color: var(--secondary-color);
            }

            img {
                margin: 0 auto;
            }
        }
    }
}

.layer-no-instrument-assigned-list-item {
    display: block;
    margin-left: var(--editor-padding);
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.no-instrument-assigned {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-top: -2px;
}

.template-menu {
    font-size: 90%;
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-gap: 5px;
    height: 30px;
    font-weight: 600;
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.template-sub-menu {
    border-radius: 8px;
    line-height: 26px;
    text-align: center;
}

.template-sub-menu:hover {
    cursor: pointer;
}

.template-sub-menu-selected {
    background-color: var(--secondary-color) !important;
}

.selected-template-toggle {
    background-color: var(--primary-color) !important;
}

.spectator-notes-container {
    position: absolute;
    top: 0px;
    height: 100%;
    width: 100%;
    margin-top: 67px;
    // z-index: 99999;
}

.note-layer-canvas {
    z-index: 9999;
    position: absolute;
    top: 0px;
}

.spectator-layer-toggles {
    display: inline-block;
    position: relative;

    .pianoroll-top-icon-text {
        margin-left: 0px;
    }
}

#pianoroll-note-container.spectator {
    margin-top: 49px;
    // background: rgba(0,0,0,0.2);
}

.modal .layers-dropdown {
    margin-top: 10px;
    margin-bottom: 5px;
}

.layer-instrument-list-item.selected {
    background-color: rgb(255 255 255 / 14%);
}

.settings-menu-button-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    margin-top: 10px;

    .modal-button {
        display: flex;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 0.3);
        margin: 5px 10px;
        padding: 5px 10px;
        width: auto;

        &.delete-instrument {
            background-color: red;
        }

        img {
            width: 12px;
            top: -1px;
            position: relative;
            margin-left: 0px;
        }

        .keyboard-shortcut {
            font-size: 75%;
            font-style: italic;
            font-weight: 900;
        }
    }
}

.instrument-action-row {
    position: absolute;
    bottom: 5px;
    width: calc(var(--layerlist-width) - 12px);
    padding-left: 5px;
    height: 28px;

    .action-button-row {
        display: flex;
        // justify-content: space-evenly;
        justify-content: space-between;
        align-items: center;
        font-size: 90%;
        height: 100%;

        .btn {
            border: 1px solid #00000036;
            margin-top: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0px;
            border-radius: 4px;
            background-color: rgb(255 255 255 / 25%);
            // background-image: linear-gradient(gray, #6e6e6e);
            cursor: pointer;
            height: 20px;
            width: 10%;
            margin-right: 2px;

            img {
                width: 12px;
            }

            &.add {
                background-color: var(--primary-color);
                width: 78% !important;
            }

            &.delete {
                background-color: red;
                margin-right: 0px !important;
            }
        }
    }

    .btn-big-add-instrument {
        border: 1px solid #00000036;
        width: 100%;
        border-radius: 4px;
        max-height: 28px;
        display: inline-block;
        background: var(--primary-color);
        padding: 5px 0px;
        font-size: 90%;
        line-height: initial;
        cursor: pointer;
        text-align: center;
        margin-top: -1px;

        img {
            width: 9px !important;
            vertical-align: middle;
            margin-right: 5px;
            margin-left: 0px;
            margin-top: -3px;
        }
    }
}

.btn.add {
    width: 10px !important;
    margin-top: 1px;
}

.add-percussion-instrument {
    line-height: 20px;
    position: absolute;
    top: 0.45em;
    right: 48px;
    font-size: 75%;
    background: var(--primary-color);
    border-radius: 5px;
    padding: 2px 4px;
    // padding-right: 10px;
    opacity: 0;
    cursor: pointer;

    img {
        margin-left: 0px;
    }
}

.layer-list-item:hover .add-percussion-instrument {
    opacity: 1;
}

$piano-roll-header-height: 167px;

.piano-roll-header {
    width: 100%;
    height: 100%;
    position: fixed;

    .piano-roll-header-left,
    .piano-roll-header-right {
        position: absolute;
        height: 100%;
    }

    .piano-roll-header-left {
        width: var(--layerlist-width);
    }

    .piano-roll-header-right {
        left: var(--layerlist-width);
        width: calc(100% - var(--layerlist-width) - var(--sidebar-width));
        background: rgba(0, 0, 0, 0.2);
        overflow-x: scroll;

        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        .timeline-clickable-area {
            height: 30px;
            position: absolute;
        }

        .pianoroll-layer-list {
            position: absolute;
            top: 97px;
            z-index: 99999;
        }
    }

    .piano-roll-header-right::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
        display: none;
    }

    .pianoroll-top-section {
        position: absolute;
        z-index: 99999;
        top: 0px;
    }

    &.piano-roll-header-spectator {
        overflow-x: scroll;
        overflow-y: visible;
        position: absolute !important;
        width: calc(100% - var(--sidebar-width));
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        .pianoroll-top-section {
            z-index: 1;
        }
    }

    &.piano-roll-header-spectator::-webkit-scrollbar {
        width: 0 !important;
        height: 0;
        display: none;
    }
}

.spectator .timeline-clickable-area {
    width: calc(100% - var(--spectator-padding));
}

.edit-pianoroll .timeline-clickable-area {
    width: calc(100% - var(--editor-padding));
}

.spectator .pianoroll-piano-background {
    position: fixed;
    overflow-y: scroll;
    height: calc(100% - 114px);
    margin-left: 0px !important;
    left: var(--sidebar-width) !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    margin-top: 137px;
    z-index: 9999999 !important;
}

.spectator .pianoroll-piano-background::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
    display: none;
}

.piano-roll-body::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
    display: none;
}

.spectator .piano-roll-body {
    top: 98px !important;
    height: calc(100% + 40px) !important;
}

.edit-pianoroll .piano-roll-body {
    max-height: calc(100% - 132px);
}

.piano-roll-body {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    overflow-y: scroll;
    position: relative;
    width: calc(100% - var(--sidebar-width));
    top: 132px;

    .layer-labels {
        position: absolute;
        background-color: #848484;
        width: var(--layerlist-width);
        z-index: 9999999;
    }

    .mini-pianoroll #pianoroll-grid {
        display: inline-block;
        background-color: rgba(0, 0, 0, 0.2);
        position: relative;
        height: 100%;

        padding-top: 0px !important;
        overflow-x: visible !important;
        width: 100% !important;
        left: var(--editor-padding) !important;
    }
}

.spectator #pianoroll-grid,
.influence #pianoroll-grid {
    left: 21px !important;
    background: rgba(0, 0, 0, 0.2);
    width: auto !important;
    margin-top: -137px;
}

.spectator,
.influence {
    .pianoroll-top-section {
        position: absolute;
    }
}

#pianoroll-component-container.spectator {
    height: calc(100% - 113px) !important;
    width: 100%;
    top: 62px;
    position: absolute;
}

#pianoroll-component-container.edit-pianoroll {
    // height: 70%!important;
}

#pianoroll-component-container.spectator .ps__rail-x,
#pianoroll-component-container.influence .ps__rail-x {
    z-index: 9999;
    left: calc(var(--sidebar-width)) !important;
}

.automation-templates-button {
    width: 171px !important;
    text-align: center;
}

.automation-templates-button img {
    width: 13px;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: -2px;
}

.pitch-range-actions {
    width: 40px;
    position: absolute;
    right: 4px;
    top: 138px;

    .increase-decrease-buttons {
        border: 1px solid rgba(0, 0, 0, 0.18);
        border-radius: 5px;
        background-color: transparent;
        background-image: linear-gradient(gray, rgb(110, 110, 110));

        img {
            width: 10px;
            margin: auto;
            display: block;
        }

        & > div {
            padding: 2px 3px;
            height: 20px;
            display: flex;

            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        & > div.disabled {
            background-color: #969090;
            cursor: not-allowed;
        }
    }
}

#fit-notes-button {
    position: absolute;
    left: 40px;
    top: 10px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 200ms ease;

    &:hover {
        opacity: 1;
    }

    .img {
        background: rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
            width: 10px;
            height: 10px;
            padding: 4px;
        }
    }
}

.pitch-drag-border {
    width: 100%;
    position: absolute;
    height: 2px;
    background: transparent;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    z-index: 999;

    $border-color: aqua;
    $border-size: 3px;

    transition: border-bottom 200ms ease, border-top 200ms ease;

    $border-alpha: 0.3;

    &.low {
        &.visible {
            cursor: row-resize;
            border-bottom: $border-size solid $border-color;
        }

        border-bottom: 3px solid rgba(255, 255, 255, $border-alpha);
        bottom: 0px;
    }

    &.high {
        &.visible {
            cursor: row-resize;
            border-top: $border-size solid $border-color;
        }

        border-top: 3px solid rgba(255, 255, 255, $border-alpha);
        top: 0px;
    }
}

.error {
    background-color: red;
    border-radius: 8px;
    color: white;
    line-height: 26px;
    font-size: 80%;
    font-weight: 600;
    text-align: center;
    width: 100%;
}

.add-layer-to-gp-modal {
    .error {
        margin-top: 10px;
    }
}

.floating-pitch-drag-border {
    width: 100%;
    position: fixed;
    height: 2px;
    background-color: aqua;
    z-index: 999;
    transition: opacity 200ms ease, background-color 150ms ease,
        box-shadow 150ms ease;
    opacity: 0;

    &.visible {
        opacity: 1;
        box-shadow: 0px 0px 8px white;
    }

    &.alert {
        background-color: red;
        box-shadow: 0px 0px 8px black;
    }
}

#tempo-graph {
    position: absolute;
    margin-left: var(--editor-padding);
}

.copy-automation-to-all-layers .modal-container {
    width: 320px;
}

.instrument-toggle img {
    display: inline-block;
    width: 7px;
    vertical-align: middle;
    margin-top: -1px;
    margin-left: 7px;
    position: absolute;
}

.instrument-header:hover {
    cursor: pointer;
}

.instrument-toggle {
    width: 14px;
}

/* Adblocker message - class names are not obvious so the elements will not be blocked by adblockers as well */
.aiva-ab-loading-screen {
    display: grid;
    place-items: center;

    .aiva-ab-message-container {
        margin-top: -50px;

        .aiva-ab-img {
            width: 55px;
        }

        .aiva-ab-message {
            font-size: 120%;
            color: white;
            margin: 10px 0px;

            & div {
                margin: 10px;
            }

            .aiva-ab-message-subtitle {
                font-size: 80%;
                max-width: 830px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }

            .aiva-ab-message-subtitle a {
                color: white !important;
            }
        }

        .refresh-button {
            img {
                margin-right: 5px;
            }

            margin-right: 0px !important;
        }
    }
}

.separator-canvas {
    width: calc(100% - var(--sidebar-width) - var(--layerlist-width));
    position: fixed;
    height: calc(100% - 78px - var(--player-bar-height));
}

.loading-virtual-instruments {
    .color-indication-title {
        margin-top: 2px;
    }

    .progress-bar-container {
        height: 10px !important;
    }

    .progress {
        height: 6px !important;
    }
}

.progress-bar-container {
    position: relative;
    // width: $waveform-area-width;
    height: 14px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 30px;

    .progress {
        height: 10px;
        background: var(--secondary-color);
        border-radius: 30px;
        top: 2px;
        position: absolute;
        transition: width 300ms ease-in;
        background: linear-gradient(
            50deg,
            var(--secondary-color),
            var(--primary-color)
        );
        background-size: 300% 100%;
        animation: progress-animation 2s linear infinite;
        left: 2px;
        max-width: calc(100% - 4px);
    }

    @keyframes progress-animation {
        0% {
            background-position: 100%;
        }

        25% {
            background-position: 0%;
        }

        100% {
            background-position: 100%;
        }
    }
}

.upload-secondstep {
    $drag-handle-width: var(--drag-handle-width);
    $waveform-area-width: 620px;
    $waveform-area-height: 100px;
    $drag-area-width: 6px;
    $drag-area-height: 6px;
    $border-radius: 8px;
    $seek-bar-width: var(--seek-bar-width);

    width: 90%;
    margin: auto;

    .progress-bar-container {
        margin-top: 15px;
    }

    .headline {
        font-size: 120%;
        margin-bottom: 30px;
        position: relative;
        font-weight: bold;
        text-align: left;
    }

    .header {
        // width: $waveform-area-width;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        margin-bottom: 5px;

        .title {
            text-transform: none;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .text {
                font-size: 90%;
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 10px;
            }

            .time {
                min-width: 80px;
                margin-right: 10px;
            }
        }

        .controls {
            border-radius: 5px;
            background-color: rgba(255, 255, 255, 0.2);
            width: 120px;
            min-width: 110px;
            transition: background-color 100ms ease-in;

            &:active {
                // background-color: rgba(255,255,255,0.4);
            }

            .play,
            .pause {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                justify-content: space-around;
                font-size: 85%;

                img {
                    width: 12px;
                    height: 12px;
                    margin-top: 2px;
                }
            }
        }

        .meta-data {
            font-size: 120%;
            height: 25px;
            min-width: 0;
            // margin-left: -11px;

            .title {
                min-width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .start-at,
            .end-at,
            .time {
                font-size: 75%;
                opacity: 0.7;
            }
        }
    }

    .influence-audio-waveform {
        margin: auto;
        width: 100%;
        height: $waveform-area-height;
        border-radius: $border-radius;
        position: relative;

        .background {
            position: absolute;
            width: 100%;
            height: 100px;
            z-index: -1;
            border-radius: 6px;

            &.dark-background {
                background-color: rgba(0, 0, 0, 0.3);
            }
        }

        #influence-canvas-container {
            width: 100%;

            canvas {
                width: calc(100% - (2 * #{$drag-area-width}));
                height: $waveform-area-height;
                border-radius: $border-radius;
                // margin-left: 12px;
            }

            canvas.below-amp-threshold {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                pointer-events: none;
                z-index: -1;
                border-radius: initial;
                transition: opacity 300ms ease-in;
                left: $drag-area-width;
            }

            .seek-bar-container {
                width: calc(100% - (2 * #{$drag-area-width}));
                position: absolute;
                top: 6px;
                left: $drag-area-width;

                .seek-bar {
                    position: absolute;
                    height: 90px;
                    width: $seek-bar-width;
                    left: 0%;
                    z-index: -1;
                    border-left: $seek-bar-width solid #00dcff;
                    margin-left: calc(-2 * var(--seek-bar-width));
                }
            }
        }

        .audio-trim-controls {
            position: absolute;
            height: 100px;
            width: 100%;
            pointer-events: none;

            .trim-area {
                position: absolute;
                height: 96px;
                background-color: rgba(0, 0, 0, 0);
                left: 0%;
                top: 2px;
                pointer-events: none;

                .start,
                .end {
                    position: absolute;
                    height: 100px;
                    width: $drag-area-width;
                    background-color: var(--primary-color);
                    top: -2px;
                    pointer-events: auto;
                    transition: background-color 150ms ease-in;
                    cursor: col-resize;

                    &.red-background {
                        background-color: rgb(255, 74, 74);
                    }

                    & .start-time,
                    & .end-time {
                        position: absolute;
                        bottom: -25px;
                        width: 80px;
                        font-size: 80%;
                        opacity: 0;
                        transition: opacity 300ms ease-in;
                    }
                }

                .top,
                .bottom {
                    height: $drag-area-height;
                    z-index: 1;
                    pointer-events: auto;
                    transition: background-color 150ms ease-in;
                    border-radius: $border-radius;

                    &.red-background {
                        background-color: rgb(255, 74, 74);
                    }
                }

                .top {
                    width: 100%;
                    background: var(--primary-color);
                    position: absolute;
                    top: -2px;
                }

                .bottom {
                    width: 100%;
                    background: var(--primary-color);
                    position: absolute;
                    bottom: -2px;
                }

                .drag-indicator {
                    position: absolute;
                    height: 40px;
                    width: 2px;
                    top: 30%;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 2px;
                    transition: background-color 0.35s;
                }

                .start {
                    left: 0px;
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;

                    .drag-indicator {
                        left: 2px;
                    }

                    .start-time {
                        left: -25px;
                    }
                }

                .end {
                    right: 0px;
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;

                    .drag-indicator {
                        right: 2px;
                    }

                    .end-time {
                        right: -25px;
                    }
                }

                &:hover {
                    cursor: move;
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;

                    .start-time {
                        opacity: 1;
                    }

                    .end-time {
                        opacity: 1;
                    }
                }

                &:active {
                    cursor: grabbing;
                    cursor: -moz-grabbing;
                    cursor: -webkit-grabbing;

                    .drag-indicator {
                        background-color: white;
                    }

                    .start-time {
                        opacity: 1;
                    }

                    .end-time {
                        opacity: 1;
                    }
                }
            }

            .left-mask,
            .right-mask {
                position: absolute;
                height: 100%;
                width: 0px;
                background: rgba(0, 0, 0, 0.6);
                // z-index: -1;
            }

            .left-mask {
                left: 0px;
                border-top-left-radius: $border-radius;
                border-bottom-left-radius: $border-radius;
            }

            .right-mask {
                right: 0px;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }

        .loader-container {
            padding-top: 30px;

            .message {
                font-size: 120%;
                margin-bottom: 5px;
            }

            .message-subtitle {
                font-size: 80%;
            }
        }
    }

    .audio-trim-warning,
    .low-amplitude-info {
        position: absolute;
        width: 100%;
        bottom: -50px;
        opacity: 0;
        transition: opacity 250ms ease-in;
        font-size: 100%;
        color: rgb(255, 74, 74);
        font-weight: bold;
    }

    .low-amplitude-info {
        font-size: 85%;
    }

    .message {
        font-size: 100%;
    }

    .button-row {
        margin-top: 45px;

        .modal-button.modal-blue-button img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
        }
    }
}

.select-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    margin: auto;
    margin-left: 0px;

    img {
        width: 8px;
        margin-top: 8px;
    }

    .previous-view,
    .next-view {
        width: 30px;
        text-align: center;

        &:hover:not(.disabled) {
            cursor: pointer;
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        &.disabled {
            opacity: 0.4;
        }
    }
}

.edited-chords {
    font-size: 80%;
    line-height: 28px;
}

.edited-chords .button:hover {
    cursor: pointer;
}

.edited-chords .button {
    text-decoration: underline;
    display: inline-block;
    margin-left: 5px;
    font-weight: 600;
}

.create-with-influence-modal {
    .loader {
        font-size: 30%;
        margin-top: 30px;
    }
}

#tutorials-component {
    .tutorial-container img,
    .guideline-container {
        border-radius: 5px;
        box-shadow: 0px 2px 15px -6px black;
        transition: transform 150ms ease-in;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
    }

    .guideline-container {
        width: 250px;
        height: 141px;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 25px;

        background-image: repeating-linear-gradient(
                135deg,
                rgba(141, 191, 242, 0.05) 0px,
                rgba(141, 191, 242, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                rgba(141, 191, 242, 0.05) 11px,
                rgba(141, 191, 242, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                45deg,
                hsla(210, 79%, 75%, 0.05) 0px,
                hsla(210, 79%, 75%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(210, 79%, 75%, 0.05) 11px,
                hsla(210, 79%, 75%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                90deg,
                hsla(210, 79%, 75%, 0.05) 0px,
                hsla(210, 79%, 75%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(210, 79%, 75%, 0.05) 11px,
                hsla(210, 79%, 75%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            repeating-linear-gradient(
                0deg,
                hsla(210, 79%, 75%, 0.05) 0px,
                hsla(210, 79%, 75%, 0.05) 1px,
                transparent 1px,
                transparent 11px,
                hsla(210, 79%, 75%, 0.05) 11px,
                hsla(210, 79%, 75%, 0.05) 12px,
                transparent 12px,
                transparent 32px
            ),
            linear-gradient(90deg, #00885c, #3a4eae);

        .guideline-content {
            position: relative;
            height: 100%;

            .title {
                position: absolute;
                top: 5px;
                width: 100%;
                font-size: 110%;
                font-weight: 300;
                margin-top: 10px;
            }

            img {
                width: 80px;
                margin-top: 55px;
            }

            .guide-icon-container {
                position: absolute;
                width: 75px;
                height: 74px;
                background-image: linear-gradient(
                    45deg,
                    #013a6b 50%,
                    rgb(255, 255, 255, 0) 50%
                );
                bottom: 0px;
                left: 0px;
                border-bottom-left-radius: 5px;

                img {
                    position: absolute;
                    margin: unset;
                    width: 25px;
                    bottom: 8px;
                    left: 10px;
                }
            }
        }
    }

    img {
        &.book {
            width: 80px !important;
        }
        &.upload {
            width: 65px !important;
        }
        &.user {
            width: 65px !important;
        }
    }
}

.steps-settings-title {
    font-weight: 700;
    margin-left: 9px;
    font-size: 90%;
    text-transform: uppercase;
    margin-top: -3px;
}

.list-item {
    .influence {
        img {
            width: 15px;
            margin-bottom: -2px;
            margin-right: 4px;
        }
    }
}

.layer-instrument-list-item.percussion {
    display: block;
    width: 100%;
}

.drum-sequencer-container {
    width: var(--drum-sequencer-total-width);
    border-bottom: 1px solid rgba(50, 50, 50, 0.7);

    .drumkit-label {
        .dropdown-menu {
            width: 210px;
            display: flex;
            line-height: 24px;
            height: 24px;
            margin-top: 0px;
            z-index: 1 !important;
            border-radius: 4px !important;
            border: 1px solid rgba(0, 0, 0, 0.4) !important;
            background-color: #4e4e4e !important;
            font-size: 75% !important;
            font-weight: 800 !important;
        }
        .dropdown-menu-container {
            width: 210px;
            margin-top: -178px;
            z-index: 9999999 !important;
        }
        .dropdown-title {
            width: 185px;
        }
    }

    .dropdown-menu-drum-sequencer {
        $dropdown-menu-height: 24px;
        background-color: #a4a4a4 !important;
        margin-left: 5px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.4) !important;
        width: 140px;
        position: relative;
        display: inline-block;
        text-align: left;
        font-size: 80%;
        font-weight: 800;
        line-height: $dropdown-menu-height;
        background-image: unset !important;

        &:hover {
            cursor: pointer;
        }

        // &:hover .dropdown-options {
        // 	visibility: visible;
        // 	opacity: 1;
        // }

        .dropdown-selected-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 5px;

            .arrow-down {
                width: 10px;
                margin-top: 2px;
                margin-right: 4px;
            }
        }

        .dropdown-options {
            // visibility: hidden;
            // opacity: 0;
            position: absolute;
            bottom: 100%;
            left: 0;
            background-color: #2f2f2f;
            width: 100%;
            border-radius: 5px;
            transition: opacity ease-in 100ms;
            max-height: 150px;
            z-index: 1;

            .dropdown-options-item {
                height: 20px;
                padding: 5px;

                &:first-child {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                &:last-child {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                &:hover {
                    background-color: var(--secondary-color);
                    cursor: pointer;
                }
            }

            // &:active {
            // 	visibility: hidden;
            // 	opacity: 0;
            // }
        }
    }

    .pattern-menu {
        margin-top: 75px;
        margin-left: 240px;
    }

    .channel-menu {
        margin-left: 250px;
    }

    .add-channel-button {
        width: 77px;
        text-align: center;
        margin-right: 2px;

        img {
            width: 12px;
            vertical-align: middle;
            margin-top: -3px;
        }

        &:hover {
            background-color: grey;
            cursor: pointer;
        }
    }

    .instrument-header {
        display: flex;
        height: var(--drum-sequencer-instrument-header-height);
        align-items: center;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        .instrument-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            margin-left: 11px;
            font-size: 80%;
            width: 201px;
            font-weight: 600;
        }

        .instrument-action-button-row {
        }
    }

    .pattern-settings,
    .channel-settings {
        height: 100%;
        display: flex;
        margin-left: 6px;
        margin-right: 7px;

        img {
            width: 15px;
            vertical-align: middle;
            margin-left: 5px;
            margin-right: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .top-bar {
        display: flex;
        width: 100%;
        height: var(--drum-sequencer-top-bar-height);

        .controls-settings,
        .steps-settings {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .controls-settings {
            width: var(--drum-sequencer-controls-width);

            .channel-selection {
                width: 150px !important;
            }
        }

        .steps-settings {
            justify-content: space-between;
            width: var(--drum-sequencer-steps-width);
            background-color: #404040;
            margin-left: 1px;

            .pattern-parameters {
                margin-right: 26px;
                position: relative;

                .dropdown-menu {
                    line-height: 24px;
                    height: 24px;
                    margin-top: 0px;
                    border: 1px solid rgba(0, 0, 0, 0.4) !important;
                    background-color: #4e4e4e !important;
                    font-size: 75% !important;
                    font-weight: 800 !important;
                }
                .dropdown-menu-container {
                    margin-top: -150px;
                    min-width: 150px;
                    max-width: 150px;
                    z-index: 9999999 !important;
                }
                .dropdown-title {
                }

                .pattern-length .dropdown-menu-container {
                    left: 163px !important;
                }
            }
        }

        .pattern-selection {
            width: 210px !important;
        }
        .note-resolution-selection {
            width: 140px !important;
        }
        .pattern-length-selection {
            width: 135px !important;
        }
    }

    .mute-solo-buttons {
        display: flex;
        width: 39px;
        font-size: 75%;
        font-weight: 600;

        .mute,
        .solo {
            width: 50%;

            &:hover {
                background-color: grey;
                cursor: pointer;
            }

            &.pattern-muted-channels {
                color: red !important;
            }

            &.pattern-soloed-channels {
                color: #ffa900 !important;
            }
        }

        .mute {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        .solo {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .add-channel-button,
    .mute-solo-buttons,
    .delete-channel-button {
        vertical-align: middle;
        color: white;
        border: 1px solid rgba(0, 0, 0, 0.35);
        border-radius: 3px;
        font-size: 80%;
        -webkit-text-decoration-line: none !important;
        text-decoration-line: none !important;
        text-align: center;
        height: 24px;
        line-height: 24px;
        position: relative;
        cursor: pointer;
        background-color: #a4a4a4;
    }

    .delete-channel-button {
        background-color: red;
        margin-right: 2px;
        width: 20px;

        img {
            width: 12px;
        }
    }

    .drum-sequencer {
        width: 100%;
        display: flex;

        .drum-sequencer-controls-container,
        .drum-sequencer-steps-container {
            display: inline-block;

            .header {
                height: var(--drum-sequencer-header-height);
                width: 100%;
                display: flex;
            }
        }

        .drum-sequencer-controls-container {
            flex: 0 0 var(--drum-sequencer-controls-width);

            .timeline {
                width: 100%;
                height: var(--drum-sequencer-timeline-height);
            }

            .channels {
                .channel {
                    display: flex;
                    align-items: center;
                    height: var(--drum-sequencer-channel-height);

                    .dropdown-menu {
                        width: 210px;
                        display: flex;
                        line-height: 24px;
                        height: 24px;
                        margin-top: 0px;
                        border-radius: 4px !important;
                        border: 1px solid rgba(0, 0, 0, 0.4) !important;
                        background-color: #4e4e4e !important;
                        font-size: 75% !important;
                        font-weight: 800 !important;
                    }
                    .dropdown-menu-container {
                        width: 210px;
                        margin-top: -178px;
                        z-index: 9999999 !important;
                    }
                    .dropdown-title {
                        width: 185px;
                    }

                    .panning-knob {
                        margin-left: 22px;
                        margin-right: 20px;
                        margin-top: 5px;
                    }

                    .channel-selection {
                        line-height: 24px;
                        width: 151px;

                        .arrow-down {
                            top: 4px;
                        }
                    }

                    .drum-channel-selection {
                        width: 210px !important;
                    }
                }
            }
        }

        .drum-sequencer-steps-container {
            flex: 0 0
                calc(
                    var(--drum-sequencer-steps-width) - 2 *
                        var(--drum-sequencer-step-container-padding)
                );
            background: #232323;
            padding-bottom: var(--drum-sequencer-timeline-height);
            padding-left: var(--drum-sequencer-step-container-padding);
            padding-right: var(--drum-sequencer-step-container-padding);

            .timeline {
                height: var(--drum-sequencer-timeline-height);
            }

            .instrument-header {
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;
            }

            .steps {
                .channel {
                    display: flex;
                    align-items: center;
                    height: var(--drum-sequencer-channel-height);
                    // border-top: 1px solid black;

                    .beat {
                        display: flex;
                        margin-right: var(--drum-sequencer-beat-margin);
                        flex: 1 0;

                        &:last-child {
                            margin-right: 10px !important;
                        }

                        .step {
                            flex: 1 0 var(--drum-sequencer-step-min-width);
                            height: calc(
                                var(--drum-sequencer-channel-height) - 8px
                            );
                            background: rgba(0, 211, 0, 0.1);
                            border-radius: 3px;
                            border: 1px solid black;
                            display: inline-block;
                            margin: var(--drum-sequencer-step-margin);
                            vertical-align: top;

                            &.active {
                                background-color: rgb(0, 211, 0);
                            }

                            &.triggered {
                                border: 1px solid white;
                            }

                            &:hover {
                                cursor: pointer;
                            }

                            &:not(.active):hover {
                                background-color: rgb(0, 211, 0, 0.5);
                            }
                        }
                    }
                }
            }
        }
    }
}

.drum-sequencer-patterns-container {
    margin-left: var(--editor-padding);
    width: 100%;
}

.resizer-left .resizer-image-container {
    height: 100% !important;
}

.resizer-image-container {
    height: 50%;
    overflow-x: hidden;
    overflow-y: visible;
}

.resizer-image-container-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.resizer-image-container img {
    width: 11px;
    vertical-align: middle;
    margin-left: 4px;
}

.resizer-transparent {
    border-color: transparent !important;
}

.resizer-pattern-region {
    position: absolute;
    margin-top: 1px;
    background-color: #ffffff4a;
    backdrop-filter: blur(10px);
    border: none !important;
}

.resizer-pattern-region.resizer-left {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.resizer-pattern-region.resizer-right {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.pattern-region-block {
    height: 100%;
    position: absolute;
    margin-left: 1px;
    border: 1px solid #0000006b;
    border-radius: 8px;
    overflow: hidden;
}

.pattern-region-block:hover {
    cursor: pointer;
}

.pattern-region-block-no-right-border {
    border-right-color: transparent !important;
}

.pattern-region-block-no-left-border {
    border-left-color: transparent !important;
}

.create-with-influence-modal .modal-title {
    text-align: center;
    margin-bottom: 0px;
    max-width: 90%;
    margin: 0px auto;
}

.create-with-influence-container {
    .parameters-view {
        text-align: left !important;
        width: 90%;
        margin: auto;

        .parameter-wrapper-title {
            text-align: left !important;
            margin-left: 3px;
        }

        .parameter-item {
            text-align: center !important;
        }

        .modal-subtitle {
            margin-top: 10px;
        }

        .ensemble-and-key-signature {
            display: flex;
            flex-wrap: wrap;

            .parameter-wrapper.ensemble {
                margin-right: 81px;
            }

            .parameter-wrapper {
                display: inline-block;
                flex: 0 1 225px;
            }

            .key-signature-list {
                background-color: #23232b;
                position: absolute;
                width: 225px;
                font-size: 90%;
                max-height: 120px;
                overflow: scroll;
                border-radius: 6px;
                z-index: 99999;

                .key-signature-list-item {
                    padding-top: 7px;
                    padding-bottom: 7px;
                    font-size: 80%;
                    text-transform: capitalize;
                    text-align: center;

                    .key-signature-subtitle {
                        font-size: 80%;
                        opacity: 0.9;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: var(--secondary-color);
                    }
                }
            }
        }

        .save-ensemble-and-key-signature-changes {
            font-size: 90%;

            .boolean-parameter {
                margin-left: 0px;
                margin-right: 0px;
            }

            .text-small {
                font-size: 80%;
            }
        }

        select.ensemble-selection-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            outline: none;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: 400;
        }

        .ensemble-selection-button.custom-select option {
            background-color: #272727;
            color: white;
            -webkit-appearance: none;
        }

        .key-signature-selection-button.custom-select option {
            background-color: #272727;
            color: white;
            -webkit-appearance: none;
        }

        .ensemble-selection-button.custom-select optgroup {
            background-color: #666666;
            color: white;
            -webkit-appearance: none;
        }

        .key-signature-selection-button.custom-select optgroup {
            background-color: #666666;
            color: white;
            -webkit-appearance: none;
        }

        select.key-signature-selection-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            outline: none;
            padding-left: 10px;
            padding-right: 10px;
            font-weight: 400;
        }

        .ensemble-selection-button,
        .key-signature-selection-button {
            display: inline-block;
            width: 220px;
            line-height: 34px;
            transition: background 0.07s;
            background-color: rgba(202, 202, 202, 0.09);
            border: 1px solid rgba(210, 210, 210, 0.1);
            vertical-align: middle;
            text-align: center;
            border-radius: 11px;
            font-size: 80%;
            font-weight: 500;
            color: white !important;

            img {
                width: 12px;
                height: 12px;
                margin: initial;
                margin-left: 6px;
            }

            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.15);
            }
        }

        .modal-button-wrapper {
            margin-top: 20px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .error-message {
            padding: 5px;
            padding-left: 0px;
            padding-right: 0px;
            font-size: 90%;
            font-weight: 500;
            border: 1px solid transparent;
            border-radius: 3px;
            color: white;
            background: #ff2929;
            margin: 10px auto;
            text-align: center;
        }
    }

    .ensemble-selection-view {
        $column-width: 218px;
        overflow-y: hidden;
        margin-top: 20px;

        .simplebar-track {
            background-color: rgba(255, 255, 255, 0.05);
        }

        .ensembles-row {
            display: flex;
            margin-top: 15px;
            margin-bottom: 10px;

            .ensemble-title {
                text-align: center;
                margin-bottom: 15px;

                img {
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }

            .ensemble-category {
                .ensembles {
                    height: 348px;
                    width: $column-width;
                    text-align: left;
                    padding-left: 10px;
                    padding-right: 10px;
                    display: inline-block;

                    .ensemble-pill {
                        display: inline-block;
                        margin-left: 3px;
                        margin-right: 3px;
                        width: 225px;

                        line-height: 34px;
                        -webkit-transition: background 0.07s; /* For Safari 3.0 to 6.0 */
                        transition: background 0.07s; /* For modern browsers */
                        background-color: rgba(202, 202, 202, 0.09);
                        border: 1px solid rgba(210, 210, 210, 0.1);
                        vertical-align: middle;
                        text-align: center;
                        border-radius: 11px;
                        font-size: 80%;
                        font-weight: 500;
                        margin-bottom: 6px;
                        position: relative;

                        .top-ensemble {
                            // position: absolute;
                            // right: 5px;
                            // top: 3px;

                            position: relative;
                            top: 3px;
                            width: 16px;
                            display: inline-block;
                            margin-right: 4px;

                            img {
                                width: 14px;
                            }
                        }

                        &:active {
                            cursor: pointer;
                            background-color: rgba(255, 255, 255, 0.24);
                        }

                        &:hover {
                            cursor: pointer;
                            background-color: rgba(255, 255, 255, 0.15);
                        }
                    }
                }
            }
        }

        .modal-button-wrapper {
            margin-top: 10px;
            text-align: center;
        }
    }

    .mode-change-view {
        text-align: center;

        .warning-icon {
            margin-top: 25px;

            img {
                width: 50px;
                height: 50px;
            }
        }

        .warning-text {
            margin-top: 20px;

            .description {
                font-size: 85%;
            }
        }
    }
}

.create-with-influence-container
    .ensemble-selection-view
    .ensembles-row
    .ensemble-category:last-child
    .ensembles {
    border-right: 1px solid transparent;
}

.clear-all-steps-for-instrument-element:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
}

.request-patch-error-modal {
    .request-patch-error-container {
        width: 500px;
        margin-top: -180px;

        .warning {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 20px auto;

            img {
                width: 50px;
            }
        }
    }
}

select.show-arrow {
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 3px);
    background-position-y: center;
    background-size: 1.25em;
}

select:disabled {
    cursor: not-allowed;
}

.error-banner-createwithgp {
    margin-bottom: 10px;
    font-size: 90%;
    border-radius: 7px;
    text-align: center;
    color: white;
    padding: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    position: relative;
}

.patches-tooltip {
    position: fixed !important;
    min-width: 85px !important;
    font-size: 80%;
    background-color: #a8a8a8 !important;
    color: white;
    padding: 5px;
    border-radius: 7px;
}

.instrument-palette-list-item-title-reduced {
    width: calc(100% - 35px) !important;
}

.why-not-recommended {
    margin-right: 4px;
    width: 14px !important;
    margin-top: 1px !important;
}

.pitch-range-control-container {
    .title {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        text-align: left;
        margin-bottom: 15px;

        .range {
            font-size: 80%;
        }
    }

    .keyboard {
        display: block;
        width: 100%;
        height: 50px;
        position: relative;
        cursor: pointer;

        .key {
            position: absolute;
            width: 11px;
            border-left: 1px solid rgb(0 0 0 / 25%);
            height: 100%;
            display: inline-block;
            background-color: white;
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;

            .c {
                color: black;
                font-size: 6px;
                position: absolute;
                left: 2px;
                bottom: 2px;
            }
        }

        .key.black {
            background-color: black;
            margin-bottom: 30px;
            height: 50%;
            z-index: 1;
            width: 7px;
        }

        .key.disabled {
            cursor: not-allowed;

            &:not(.in-range) {
                border-left: 1px solid rgb(0 0 0 / 20%);

                &.black {
                    background-color: #2a2a2a;
                }

                &.white {
                    background-color: rgba(255, 255, 255, 0.3);
                }
            }
        }

        .key.in-range {
            cursor: move;
            background-color: hsl(150, 81%, 44%);
            border-left: 1px solid hsl(150, 81%, 60%);

            &.black {
                background-color: hsl(150, 81%, 70%);
                border-left: 1px solid hsl(150, 81%, 70%);
            }
        }
    }

    .hint {
        opacity: 0;
        font-size: 80%;
        width: 95%;
        color: white;
        margin-top: 10px;
        text-align: center;

        &.show {
            opacity: 1;
        }
    }
}

.recommendations-are-applied .recommendations-button button {
    &:hover {
        background-color: rgba(255, 255, 255, 0) !important;
        text-decoration: underline;
    }

    background-color: rgba(255, 255, 255, 0) !important;

    img {
        margin-right: 8px !important;
    }
}

@supports (backdrop-filter: blur(20px)) {
    .upload-influence-dropzone {
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.09) !important;
    }

    .parameter-item {
        backdrop-filter: blur(10px);
    }

    .preset {
        backdrop-filter: blur(10px);

        background-color: rgba(202, 202, 202, 0.09) important;

        &:hover {
            background-color: rgba(202, 202, 202, 0.14) !important;
        }
    }

    .tooltip .tooltiptext {
        background-color: rgba(10, 10, 20, 0.5) !important;
        backdrop-filter: blur(20px) !important;
    }

    .settings-menu {
        backdrop-filter: blur(15px) !important;
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    #note-description {
        background-color: rgba(10, 10, 20, 0.4) !important;
        backdrop-filter: blur(15px) !important;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
    }

    .right-click-menu-background {
        backdrop-filter: blur(20px) !important;
        background-color: rgba(34, 34, 34, 0.82) !important;
    }

    /* don't use this for Chrome / Opera because it crashes position fixed elements */
    .modal-wrapper .modal-container:not(.modal-wrapper-no-blur) {
        background-color: rgba(30, 30, 30, 0.7) !important;
        backdrop-filter: blur(40px) !important;
    }
}

/*
	For safari. For some reason, combining with the above statement doesn't work anymore on Safari
*/
@supports (-webkit-backdrop-filter: blur(20px)) {
    .upload-influence-dropzone {
        -webkit-backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.09) !important;
    }

    .parameter-item {
        -webkit-backdrop-filter: blur(10px);
    }

    .preset {
        -webkit-backdrop-filter: blur(10px);
        background-color: rgba(202, 202, 202, 0.09) important;

        &:hover {
            background-color: rgba(202, 202, 202, 0.14) !important;
        }
    }

    .tooltip .tooltiptext {
        background-color: rgba(10, 10, 20, 0.5) !important;
        -webkit-backdrop-filter: blur(20px) !important;
    }

    .settings-menu {
        -webkit-backdrop-filter: blur(15px) !important;
        background-color: rgba(0, 0, 0, 0.7) !important;
    }

    #note-description {
        background-color: rgba(10, 10, 20, 0.4) !important;
        -webkit-backdrop-filter: blur(15px) !important;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
    }

    .right-click-menu-background {
        -webkit-backdrop-filter: blur(20px) !important;
        background-color: rgba(34, 34, 34, 0.82) !important;
    }

    /* don't use this for Chrome / Opera because it crashes position fixed elements */
    .modal-wrapper .modal-container:not(.modal-wrapper-no-blur) {
        background-color: rgba(30, 30, 30, 0.7) !important;
        -webkit-backdrop-filter: blur(40px) !important;
    }
}

.popover-class {
    background-color: rgba(30, 30, 30) !important;
    color: #fff !important;
    .driver-popover-prev-btn {
        color: black;
        background-color: white;
        border: none;
        text-shadow: unset;

        &:hover {
            background-color: white;
        }
    }

    .driver-popover-next-btn {
        color: black;
        background-color: white;
        border: none;
        text-shadow: unset;

        &:hover {
            background-color: white;
        }
    }

    .driver-popover-close-btn {
        color: white;

        &:hover {
            color: white;
        }
    }

    .driver-popover-arrow {
        border: 5px solid rgba(30, 30, 30);
    }
}

.bounce {
    animation: pulse 2s infinite;
    &--blue {
        animation: pulse-blue 2s infinite;
    }
}

.disable-clicks {
    pointer-events: none !important;
}

.skip-button {
    background-color: transparent !important;
    border: none !important;
    text-decoration: underline !important;
    color: white !important;
    text-shadow: unset !important;
}
