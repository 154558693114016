/* 
Vendor prefixes SCSS mixins
Whenever we are in need of vendor prefixing a css property, we could add this to this file and use the mixin instead

Many are from here https://andycrone.com/thoughts/current-list-sass-mixins-vendor-prefixes
*/

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
        -moz-boder-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

@mixin user-select($value){
    -webkit-touch-callout: $value;
    -webkit-user-select: $value;
    -khtml-user-select: $value;
    -moz-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}

@mixin user-drag($value) {
    -webkit-user-drag: $value;
	-khtml-user-drag: $value;
	-moz-user-drag: $value;
	-o-user-drag: $value;
	user-drag: $value;
}

@mixin linear-gradient($angle, $gradient1, $gradient2) {
    background-color: $gradient2; /* Fallback Color */
    background-image: -webkit-gradient(linear, $angle, from($gradient1), to($gradient2)); /* Saf4+, Chrome */
    background-image: -webkit-linear-gradient($angle, $gradient1, $gradient2); /* Chrome 10+, Saf5.1+, iOS 5+ */
    background-image:    -moz-linear-gradient($angle, $gradient1, $gradient2); /* FF3.6 */
    background-image:     -ms-linear-gradient($angle, $gradient1, $gradient2); /* IE10 */
    background-image:      -o-linear-gradient($angle, $gradient1, $gradient2); /* Opera 11.10+ */
    background-image:         linear-gradient($angle, $gradient1, $gradient2);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#{$gradient1}', EndColorStr='#{$gradient2}');
}

@mixin background-size($value){
    -o-background-size: $value;
    -webkit-background-size: $value;
    -moz-background-size: $value;
    background-size: $value;
}

@mixin box-shadow($value){
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin box-sizing($value){
    -webkit-box-sizing: $value;
    -moz-box-sizing: $value;
    box-sizing: $value;
}

@mixin filter($value){
    -o-filter: $value;
    -ms-filter: $value;
    -webkit-filter: $value;
    -moz-filter: $value;
    filter: $value;
}
  
@mixin appearance($value){
    -moz-appearance: $value;
    -webkit-appearance: $value;
    appearance: $value;
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin background-clip($value) {
    -webkit-background-clip: $value;
    background-clip: $value;
}